import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { endpoints } from "../../constants/Endpoints";
import { LOCAL_CONSTANT } from "../../constants/LocalConstants";
import {
  allExamDepartments as recoilAllExamDepartments,
  authUser,
  allExams as allExamsRecoil,
  department as recoilDepartment,
  exam as recoilExam,
} from "../../providers/index";
import { instance } from "../../services/https/inceptors";
import { Admin } from "../../utilities/constants";
import AddViewPanel from "./add-view-panel";
import CardSection from "./card-section";
import SelectPanel from "./select-panel";
import "./style.scss";

const HomePage = () => {
  const [exam, setExam] = useRecoilState(recoilExam);
  const [department, setDepartment] = useRecoilState(recoilDepartment);
  const recoilAuth = useRecoilValue(authUser);
  const [allExamDepartment, setAllDepartments] = useRecoilState(
    recoilAllExamDepartments
  );

  const [allExams, setAllExams] = useRecoilState(allExamsRecoil);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem(LOCAL_CONSTANT.SET2_SCORE_USER);

  const getExams = async () => {
    const exams = await instance.get(
      endpoints.getAllowedExams +
        `?examPermissions=${recoilAuth.examPermissions.toString()}`
    );
    if (exams) setAllExams(exams?.data);
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      if (recoilAuth.role === "master" || recoilAuth.examPermissions.length) {
        getExams();
      }
      setLoading(false);
    }, 1000);
  }, [recoilAuth, token]);

  const selectExam = async (exam) => {
    setDepartment("");
    setExam(exam);
    if (exam) {
      const departs = await instance.get(
        endpoints.getAllowedDepartments +
          `?exam=${
            exam.split("/")[1]
          }&departmentPermissions=${recoilAuth.departmentPermissions.toString()}`
      );
      setAllDepartments(departs.data);
    }
  };

  return (
    <div className="d-flex flex-column overflow-auto">
      <div className="mb-5">
        <div className="container">
          <SelectPanel
            {...{
              selectExam,
              setAllDepartments,
              loading,
              allExams,
              setDepartment,
              allExamDepartment,
            }}
          />

          <AddViewPanel {...{ exam, department }} />

          {recoilAuth.role !== Admin.SENIOR &&
          recoilAuth.role !== Admin.SUPPORT ? (
            <CardSection {...{ exam, department }} />
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default HomePage;
