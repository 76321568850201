import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "../../components/buttons";
import EmptyIcon from "../../components/EmptyIcon/index";
import { DisplaySection } from "../../components/layouts";
import { endpoints } from "../../constants/Endpoints";
import { instance } from "../../services/https/inceptors";
import { useRecoilState, useRecoilValue } from 'recoil';
import { selectedDepartmentObject, selectedLanguage } from '../../providers';
import ReactSelect from 'react-select';
import { LANGUAGES } from '../../constants/Common';
import classNames from 'classnames';

const TestSeries = () => {
  const { push } = useHistory();
  const { department, exam } = useParams();
  const [testSeries, setTestSeries] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useRecoilState(selectedLanguage);
  const selectedDepart = useRecoilValue(selectedDepartmentObject);


  useEffect(() => {
    const getTestSeries = async () => {
      const param = `&exam=${exam}&department=${department}`;

      setLoading(true);
      let query = `?mode=subject`;
      if (isActive) {
        query = `?mode=full`;
      }
      const res = await instance.get(
        endpoints.getAllTestSeries + query + param
      );
      setTestSeries(res.data);
      setLoading(false);
    };

    getTestSeries();
  }, [isActive]);

  return (
    <div className="container">
      <div className="d-flex flex-row justify-content-center fw-bold p-2 mb-3">
        <h2 className="mt-3 mb-3 space-heading">Test Series</h2>
      </div>

      <div className="d-flex justify-content-center mb-5">
        <Button
          width="30"
          style={{
            height: "2.8rem",
            background: `rgb(198, 215, 201)`,
            color: "#024f10",
            border: "none",
            fontSize: "1.2rem",
          }}
          onClick={() => push(`/add-testSeries/${department}/${exam}`)}
        >
          Create New
        </Button>
      </div>

      <div className="w-70 m-auto">
        <div className="flex items-end justify-center gap-x-4 mb-5">
          <Button className={classNames('h-10 shadow-none', {
              'btn-hover btn-active text-white': isActive,
              'btn-hover text-dark': !isActive,
            })}
            onClick={() => setIsActive(true)}
          >
            Full Length
          </Button>

          <Button className={classNames('h-10 shadow-none', {
              'btn-hover btn-active text-white': !isActive,
              'btn-hover text-dark': isActive,
            })}
            onClick={() => setIsActive(false)}
          >
            Subjective
          </Button>

          <div>
            <h6 className='mb-1 font-semibold'>Select Language</h6>
            <ReactSelect
              onChange={setCurrentLanguage}
              options={selectedDepart?.languages?.map((lang) => {
                const label = Object.keys(LANGUAGES).find(
                  (key) => LANGUAGES[key] === lang
                );
                return {
                  value: lang,
                  label: label.replace(/^./, label[0].toUpperCase()),
                };
              })}
              value={currentLanguage}
              placeholder='Select Language'
              className='w-52'
            />
         </div>
        </div>

        {!testSeries.length ? <EmptyIcon /> : null}

        {loading ? (
          <h6 style={{ textAlign: "center", fontStyle: "italic" }}>
            Loading ...
          </h6>
        ) : (
          <DisplaySection
            language={currentLanguage}
            type="test"
            dept={department}
            onChange={setTestSeries}
            collectiveData={testSeries}
          />
        )}
      </div>
    </div>
  );
};

export default TestSeries;
