import axios from "axios";
import { LOCAL_CONSTANT } from "../../constants/LocalConstants";

export const axiosObj = {
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  timeout: 120000,
  headers: {
    "Content-Type": "application/json",
  },
};

export const instance = axios.create(axiosObj);

instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem(LOCAL_CONSTANT.SET2_SCORE_USER);
    const userToken = token ? JSON.parse(token) : null;
    if (config && config.headers) {
      config.headers["x-access-token"] = userToken?.token
        ? `Bearer ${userToken?.token}`
        : "";
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // eslint-disable-next-line default-case
    switch (error.response?.status) {
      case 401: {
        localStorage.clear();
        localStorage.setItem(
          "sessionMessage",
          "Your session has expired. Please sign-in again."
        );

        window.location.href = "/login";
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);
