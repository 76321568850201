import React, { useState } from "react";
import logo from "../../assets/images/logo_name.png";
import { Submit } from "../../components/buttons";
import { Input } from "../../components/inputs";
import useUserActions from "../../hooks/useUserActions";
import { showToast } from "../../utilities/toast";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login, error } = useUserActions();

  const onLogin = async (event) => {
    event.preventDefault();
    await login(username, password);
  };

  const sessionMessage = localStorage.getItem("sessionMessage");
  if (sessionMessage) {
    showToast({ type: "info", message: sessionMessage });
    localStorage.clear();
  }

  return (
    <div className="d-flex" style={{ height: "100vh", width: "100%" }}>
      <div
        style={{ width: "60%" }}
        className="d-flex justify-content-center align-items-center"
      >
        <img src={logo} alt="company_logo" className="img-responsive logo1" />
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ background: `rgb(198, 215, 201)`, width: "40%" }}
      >
        <div
          className="card"
          style={{
            width: "350px",
            background: "transparent",
            border: `3px solid rgb(2, 79, 16)`,
          }}
        >
          <div className="card-body p-5">
            <form onSubmit={onLogin}>
              <Input
                label="Username"
                id="username"
                value={username}
                onChange={setUsername}
              />

              <Input
                label="Password"
                id="password"
                type="password"
                value={password}
                onChange={setPassword}
              />

              <Submit disabled={!username.trim() || !password.trim()}>
                Login
              </Submit>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
