import ReactSelect from 'react-select';

const ExamSelect = ({
  setExamSelectValue,
  selectExam,
  setAllDepartments,
  setDepartSelectValue,
  loading,
  examSelectValue,
  allExams,
}) => {
  const getExamsOptions = () => {
    const options = [];
    allExams.forEach((exam) =>
      options.push({
        label: exam.title.toString(),
        value: exam?.code + '/' + exam?._id + '/' + exam?.title,
      })
    );
    return options;
  };

  return (
    <>
      <h6 className='mb-1'>Select Exam</h6>
      <ReactSelect
        onChange={(e) => {
          setExamSelectValue(e);
          selectExam(e.value);
          setAllDepartments([]);
          setDepartSelectValue(null);
        }}
        placeholder='Select Exam'
        className='basic-single'
        classNamePrefix='select'
        isLoading={loading}
        value={examSelectValue}
        isSearchable
        options={getExamsOptions()}
      />
    </>
  );
};

export default ExamSelect;
