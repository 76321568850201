import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Button, Submit } from "../../components/buttons";
import { Input, Select, Time } from "../../components/inputs";
import { Section } from "../../components/Section";
import { endpoints } from "../../constants/Endpoints";
import { authUser, selectedDepartmentObject } from "../../providers/index";
import { instance } from "../../services/https/inceptors";
import { Type } from "../../utilities/constants";
import { showToast } from "../../utilities/toast";
import { checkError } from "../../utilities/error";
import CommonInput from "../../components/CommonInput";
import { AWS_DIRECTORIES, LANGUAGES, MODES } from "../../constants/Common";
import useData from "../../hooks/useData";
import ReactSelect from "react-select";

const CreatePreviousYearPaper = () => {
  const { department, exam } = useParams();
  const user = useRecoilValue(authUser);
  const [toRenderSection, setToRenderSection] = useState([]);
  const [marks, setMarks] = useState("");

  const [language, setSelectedLanguage] = useState({
    value: "en",
    label: "English",
  });

  const [time, setTime] = useState(0);
  const [name, setName] = useState({ [language.value]: "" });
  const [instructions, setInstructions] = useState({ [language.value]: "" });
  const [description, setDescription] = useState({ [language.value]: "" });
  const [choice, setChoice] = useState("");
  const [mode, setMode] = useState("");
  const [selectedChapter, setSelectedChapter] = useState("");
  const selectedDepart = useRecoilValue(selectedDepartmentObject);
  const [to, setTo] = useState();
  const [from, setFrom] = useState();
  const [year, setYear] = useState();

  useEffect(() => {
    setTo(Number(selectedDepart.yearRange.to));
    setFrom(Number(selectedDepart.yearRange.from));
  }, [selectedDepart]);

  const {
    getSubjectByDepartment,
    onSubjectSelect,
    selectedSubject,
    subjects,
    chapters,
  } = useData();

  const handleSection = () => {
    let index = toRenderSection.length;
    let flag = true;

    while (flag === true) {
      let temp = false;
      // eslint-disable-next-line no-loop-func
      toRenderSection.forEach((sec) => {
        if (sec.sectionId === `section${index}`) temp = true;
      });

      if (temp) {
        flag = true;
        index++;
      } else flag = false;
    }

    const toAdd = {
      name: "",
      questions: [],
      sectionId: "section" + index,
      tackle: 0,
      compulsory: null,
    };

    setToRenderSection([...toRenderSection, toAdd]);
  };

  const getSubjects = async () => {
    await getSubjectByDepartment(department);
  };

  useEffect(() => {
    getSubjects();
  }, []);

  const onSubmit = async (event) => {
    event.preventDefault();
    let questionIds = [];
    let returnFunc = false;
    const sectionsClone = JSON.parse(JSON.stringify(toRenderSection));

    sectionsClone.forEach((section) => {
      returnFunc = checkError(section);
      section.questions.forEach((question) => {
        questionIds.push(question._id);
      });

      section.questions = questionIds;
      questionIds = [];
    });

    if (returnFunc) return;

    const data = {
      name,
      user,
      time,
      year,
      instructions,
      description,
      marks,
      mode,
      subject: selectedSubject?.length ? selectedSubject : undefined,
      chapter: selectedChapter?.length ? selectedChapter : undefined,
      department,
      selectSection: choice,
      exam,
      sections: sectionsClone,
      postedBy: user?.userId,
    };

    try {
      await instance.post(endpoints.addPreviousYearsPaper, data);
      showToast({
        type: "success",
        message: "Successfully added previous year paper",
      });
      setMarks("");
      setTime(0);
      setToRenderSection([]);
      setName({ [language.value]: "" });
      setInstructions({ [language.value]: "" });
      setDescription({ [language.value]: "" });
    } catch (err) {
      showToast({
        type: "error",
        message: err.response.data.message,
      });
    }
  };

  const deleteSection = (selected) =>
    setToRenderSection((prev) =>
      prev.filter((sec) => sec.sectionId !== selected.sectionId)
    );

  const onLanguageChange = (e) => {
    setSelectedLanguage(e);

    setName((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: "" };
      else return prev;
    });

    setInstructions((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: "" };
      else return prev;
    });

    setDescription((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: "" };
      else return prev;
    });
  };

  const allQuestions = useMemo(() => {
    const data = [];
    toRenderSection.forEach((section) =>
      section.questions.forEach((ques) => data.push(ques))
    );
    return data;
  }, [toRenderSection]);

  return (
    <>
      <div className="d-flex justify-content-center ">
        <h4 className="mt-3 mb-5 space-heading">Create Previous Year Paper</h4>
      </div>
      <form className="horizontal" onSubmit={onSubmit}>
        <div className="flex flex-wrap justify-normal gap-x-6 px-3">
          <Input
            className="!w-56"
            id="name"
            label="Name"
            value={name[language?.value]}
            onChange={(e) =>
              setName((prev) => ({ ...prev, [language.value]: e }))
            }
          />

          <Input value={marks} label="Total marks" onChange={setMarks} />

          <Input
            name="choice"
            label="Do"
            type="number"
            value={choice}
            onChange={(e) => {
              if (e > -1) {
                setChoice(e);
              }
            }}
          />

          <Time value={time} onChange={setTime} label="Time" />

          <Select value={mode} onChange={setMode} label="Type">
            <option value={MODES.FULL_LENGTH}>Full Length</option>
            <option value={MODES.SUBJECT_WISE}>Subject Wise</option>
          </Select>

          <Select onChange={setYear} value={year} label="Year">
            {Array.from(
              { length: to - from + 1 },
              (_, index) => from + index
            ).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Select>

          <Select
            onChange={onSubjectSelect}
            value={selectedSubject}
            label="Subject"
          >
            {subjects?.map((item, index) => (
              <option key={index} value={item._id}>
                {item.title}
              </option>
            ))}
          </Select>

          <Select
            onChange={(e) => setSelectedChapter(e)}
            value={selectedChapter}
            label="Chapters"
          >
            {chapters?.map((item, index) => (
              <option key={index} value={item._id}>
                {item.title}
              </option>
            ))}
          </Select>

          <div className="mt-1">
            <h6 className="mb-1 font-semibold">Select Language</h6>
            <ReactSelect
              onChange={onLanguageChange}
              options={selectedDepart?.languages?.map((lang) => {
                const label = Object.keys(LANGUAGES).find(
                  (key) => LANGUAGES[key] === lang
                );
                return {
                  value: lang,
                  label: label.replace(/^./, label[0].toUpperCase()),
                };
              })}
              value={language}
              placeholder="Select Language"
              className="w-52"
            />
          </div>
        </div>

        <hr className="my-4" />

        <div className="mb-4 mx-4">
          <CommonInput
            language={language.value}
            directory={AWS_DIRECTORIES.PREVIOUS_YEAR_PAPER}
            label="General Instructions"
            value={instructions?.[language.value]}
            onChange={setInstructions}
          />
        </div>

        <div className="mb-4 mx-4">
          <CommonInput
            language={language.value}
            directory={AWS_DIRECTORIES.PREVIOUS_YEAR_PAPER}
            label="Description"
            value={description?.[language.value]}
            onChange={setDescription}
          />
        </div>

        {toRenderSection.map((section, index) => {
          return (
            <Section
              allQuestions={allQuestions}
              language={language}
              key={index + section.sectionId}
              index={index}
              section={section}
              setToRenderSection={setToRenderSection}
              deleteSection={deleteSection}
            />
          );
        })}

        <div className="d-flex justify-content-between mt-5 ">
          <Button width="25" onClick={handleSection}>
            Add Section
          </Button>

          <Submit
            width="25"
            disabled={
              !name[language.value] ||
              time === 0 ||
              !marks ||
              !toRenderSection.length ||
              !instructions[language.value]
            }
          />
        </div>
      </form>
    </>
  );
};

const AddPreviousYearPaperPage = () => {
  return (
    <div className="container d-flex flex-column justify-content-center mb-5">
      <CreatePreviousYearPaper />
    </div>
  );
};
export default AddPreviousYearPaperPage;
