import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button } from '../../components/buttons';
import { LANGUAGES } from '../../constants/Common';
import { endpoints } from '../../constants/Endpoints';
import { allExamDepartments, department } from '../../providers';
import { instance } from '../../services/https/inceptors';
import { showToast } from '../../utilities/toast';

const DepartmentSettings = () => {
  const [languages, setLanguages] = useState(['hn', 'en']);
  const selectedDepartment = useRecoilValue(department).split('/')[1];
  const [allDepartments, setAllDepartments] =
    useRecoilState(allExamDepartments);

  useEffect(() => {
    const currentDepartment = allDepartments.find(
      (depart) => depart?._id === selectedDepartment
    );

    setLanguages(currentDepartment?.languages);
  }, [allDepartments, selectedDepartment]);

  const changeDepartmentLanguage = async (e) => {
    const isExist = languages?.includes(e.target.value);
    const clone = JSON.parse(JSON.stringify(languages));
    if (isExist) {
      const arr = clone.filter((lang) => lang !== e.target.value);
      setLanguages(arr);
    } else if (!isExist) {
      clone.push(e.target.value);
      setLanguages(clone);
    }
  };

  const saveDepartmentLanguages = async () => {
    try {
      await instance.put(endpoints.updateDepartment, {
        _id: selectedDepartment,
        languages,
      });

      const allDepartmentsClone = JSON.parse(JSON.stringify(allDepartments));
      allDepartmentsClone.forEach((depart) => {
        if (depart._id === selectedDepartment) {
          depart.languages = languages;
        }
      });

      setAllDepartments(allDepartmentsClone);

      showToast({
        type: 'success',
        message: 'Successfully Updated!',
      });
    } catch (err) {
      console.log(err);
      showToast({
        type: 'error',
        message: err?.response?.data?.message,
      });
    }
  };


  useEffect(() => {
    if (!selectedDepartment) {
      window.location.href = '/'
    }
  }, [selectedDepartment])


  return (
    <div>
      <h3 className='space-heading text-center'>Department Settings</h3>

      <div className='container mx-auto mt-5'>
        <div className='!flex flex-col justify-center items-center gap-3'>
          <h4 className='text-xl font-medium'>Department Languages</h4>
          {Object.keys(LANGUAGES).map((key) => (
            <div className='flex  gap-4 items-center'>
              <h3 className='!w-20 capitalize text-xl'>{key}</h3>
              <input
                onChange={changeDepartmentLanguage}
                checked={languages?.includes(LANGUAGES[key])}
                name={LANGUAGES[key]}
                value={LANGUAGES[key]}
                className='form-check-input h-6 w-6 cursor-pointer'
                type='checkbox'
              />
            </div>
          ))}

          <Button onClick={saveDepartmentLanguages} className='w-10 mt-3'>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
export default DepartmentSettings;
