import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Actions from "../../components/Action/index";
import { Button } from "../../components/buttons";
import EmptyIcon from "../../components/EmptyIcon";
import { Select } from "../../components/inputs";
import { LANGUAGES, MODULES } from "../../constants/Common";
import { endpoints } from "../../constants/Endpoints";
import useData from "../../hooks/useData";
import { instance } from "../../services/https/inceptors";
import { useRecoilState, useRecoilValue } from 'recoil';
import { selectedDepartmentObject, selectedLanguage } from '../../providers';
import ReactSelect from 'react-select';

const Assignmnet = () => {
  const { push } = useHistory();
  const [subject, setSubject] = useState();
  const { department, exam } = useParams();
  const [chapter, setChapter] = useState();
  const [subjects, setSubjects] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const selectedDepart = useRecoilValue(selectedDepartmentObject);
  const [currentLanguage, setCurrentLanguage] = useRecoilState(selectedLanguage);

  const { getSubjectByDepartment, getChaptersBySubject } = useData();

  const getAllAssignment = async () => {
    const res = await instance.get(
      `${endpoints.getAllAssignemnt}?exam=${exam}&department=${department}`
    );
    setAssignments(res.data);
  };

  const getSubjects = async () => {
    const res = await getSubjectByDepartment(department);
    setSubjects(res);
  };

  const searchAssignments = async () => {
    const res = await instance.get(
      `${endpoints.getAssignemntBySearch}?chapter=${chapter}&exam=${exam}&department=${department}`
    );
    setAssignments(res.data);
  };

  const getChapters = async (e) => {
    setSubject(e);
    const res = await getChaptersBySubject(e);
    setChapters(res);
  };

  useEffect(() => {
    getAllAssignment();
    getSubjects();
  }, []);

  return (
    <>
      <div className="container">
        <h2 className="text-center mb-3 space-heading">Assignments</h2>
        <div className="flex justify-between items-center p-3 w-full shadow rounded">
          <div className="flex items-center gap-3">
            <Select className='!w-60' onChange={getChapters} value={subject} label="Subject">
              {subjects.map((item, index) => (
                <option key={index} value={item._id}>
                  {item.title}
                </option>
              ))}
            </Select>

            <Select className='!w-60' value={chapter} onChange={setChapter} label="Chapter">
              {chapters.length > 0 &&
                chapters.map((item, index) => (
                  <option value={item._id} key={index}>
                    {item.title}
                  </option>
                ))}
            </Select>

            <div className='-mt-3'>
              <h6 className='mb-1 font-semibold'>Select Language</h6>
              <ReactSelect
                onChange={setCurrentLanguage}
                options={selectedDepart?.languages?.map((lang) => {
                  const label = Object.keys(LANGUAGES).find(
                    (key) => LANGUAGES[key] === lang
                  );
                  return {
                    value: lang,
                    label: label.replace(/^./, label[0].toUpperCase()),
                  };
                })}
                value={currentLanguage}
                placeholder='Select Language'
                className='w-52'
              />
            </div>

            <Button
              color="success"
              width="20"
              className="search-assignment"
              onClick={searchAssignments}
              disabled={!subject || !chapter}
            >
              Search
            </Button>
          </div>

          <div className="w-96 flex justify-end">
            <Button
              color="success"
              width="60"
              onClick={() => push(`/add-assignment/${department}/${exam}`)}
            >
              Create new assignment
            </Button>
          </div>
        </div>
      </div>


      <div className="mt-5 m-auto w-50">
        {assignments?.length ? (
          <Actions
            language={currentLanguage}
            data={assignments}
            setData={setAssignments}
            type={MODULES.ASSIGNMENT}
          />
        ) : (
          <EmptyIcon />
        )}
      </div>
    </>
  );
};
const AssignmnetPage = () => {
  return <Assignmnet />;
};
export default AssignmnetPage;
