import { useEffect, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { useRecoilValue } from 'recoil';
import { Button } from '../../../components/buttons';
import { endpoints } from '../../../constants/Endpoints';
import { instance } from '../../../services/https/inceptors';
import { showToast } from '../../../utilities/toast';
import useData from '../../../hooks/useData';

import { allExams, selectedLanguage } from '../../../providers/index';
import { Select } from '../../../components/inputs';
import classNames from 'classnames';

const AddContentModal = ({ data, id, closeModal, setData }) => {
  const [content, setContent] = useState([]);
  const [selectedContent, setSelectedContent] = useState([]);
  const language = useRecoilValue(selectedLanguage);



  const {
    departments,
    subjects,
    chapters,
    topics,
    selectedChapter,
    selectedDepartment,
    selectedExam,
    selectedSubject,
    selectedTopic,
    onChapterSelect,
    onDepartmentSelect,
    onExamSelect,
    onSubjectSelect,
    setSelectedTopic,
  } = useData();

  const exams = useRecoilValue(allExams);

  const getSearchedContent = async () => {
    let url = '';
    if (selectedExam) url += `?exam=${selectedExam}`;
    if (selectedDepartment) url += `&department=${selectedDepartment}`;
    if (selectedSubject) url += `&subject=${selectedSubject}`;
    if (selectedChapter) url += `&chapter=${selectedChapter}`;
    if (selectedTopic) url += `&topic=${selectedTopic}`;

    const res = await instance.get(endpoints.getContentByFilter + url);

    const result = res.data.filter(
      (element2) =>
        !data?.content?.some((element1) => element1._id === element2._id)
    );

    setContent(result);
  };

  const selectContent = (content) => {
    let clone = JSON.parse(JSON.stringify(selectedContent));

    if (clone.some((ass) => ass._id === content._id)) {
      clone = clone.filter((chap) => chap._id !== content._id);
    } else clone.push(content);

    setSelectedContent(clone);
  };

  const saveContent = async () => {
    try {
      const res = await instance.post(endpoints.addCourseContent, {
        content: selectedContent,
        topicId: id,
      });

      if (res.data.data) {
        setData(res.data.data);
      }

      showToast({ type: 'success', message: 'Successfully Added Content!' });
      closeModal();
    } catch (err) {
      showToast({ type: 'error', message: err.response.data.message });
    }
  };

  return (
    <div className='backdrop fade show' style={{ background: 'white' }}>
      <div className='modal-dialog modal-dialog-scrollable modal-fullscreen'>
        <div className='modal-content'>
          <div className='modal-body'>
            <div className='d-flex justify-content-end cross-button'>
              <RxCross2 size={24} onClick={closeModal} />
            </div>
            <div className='container mt-3'>
              <h3 className='space-heading text-center'>Content</h3>
              <h6 className='mt-4 text-uppercase'>Filters:</h6>
              <div className='d-flex justify-content-center mt-2 gap-3 mb-4 border-1 border-top pt-4'>
                <div className='w-20'>
                  <Select
                    label='Exams'
                    value={selectedExam}
                    onChange={onExamSelect}>
                    {exams?.map((exam) => (
                      <option key={exam.code} value={exam._id}>
                        {exam.title}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className='w-20'>
                  <Select
                    label='Departments'
                    value={selectedDepartment}
                    onChange={onDepartmentSelect}>
                    {departments?.map((depart) => (
                      <option key={depart.code} value={depart._id}>
                        {depart.title}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className='w-20'>
                  <Select
                    label='Subject'
                    value={selectedSubject}
                    onChange={onSubjectSelect}>
                    {subjects?.map((subj) => (
                      <option key={subj.code} value={subj._id}>
                        {subj.title}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className='w-20'>
                  <Select
                    label='Chapters'
                    value={selectedChapter}
                    onChange={onChapterSelect}>
                    {chapters?.map((chap) => (
                      <option key={chap.code} value={chap._id}>
                        {chap.title}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className='w-20'>
                  <Select
                    label='Topics'
                    value={selectedTopic}
                    onChange={setSelectedTopic}>
                    {topics?.map((topic) => (
                      <option key={topic.code} value={topic._id}>
                        {topic.title}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className='d-flex justify-content-end w-100'>
                <Button onClick={getSearchedContent} width={20}>
                  Search
                </Button>
              </div>

              <hr />
              {content.length ? (
                <div className='d-flex flex-column gap-1 align-items-center mt-5'>
                  <div className='d-flex justify-content-start w-40'>
                    <div className='selected-length'>
                      <span> Selected</span>: {selectedContent?.length}
                    </div>
                  </div>
                  {content?.map((contentItem) => (
                    <div
                      onClick={() => selectContent(contentItem)}
                      className={`each-chapter d-flex c-pointer ${selectedContent?.some(
                        (ch) => ch._id === contentItem?._id
                      ) && 'selected-chapter'
                        }`}>
                      <div className={classNames('text-center', {
                        '!bg-red-200': !contentItem?.contents[0]?.title?.[language.value]
                      })}>
                        {contentItem?.contents[0]?.title?.[language.value] ?? `Content not available in ${language.label} language`}</div>
                    </div>
                  ))}
                  <Button onClick={saveContent} className='mt-5'>
                    Proceed
                  </Button>
                </div>
              ) : (
                <div
                  style={{ marginTop: '10rem' }}
                  className='text-center fs-5'>
                  No Content Found!
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddContentModal;
