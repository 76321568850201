import { useState } from 'react';
import { RxCross2 } from 'react-icons/rx';

import { useRecoilValue } from 'recoil';
import { Button } from '../../../components/buttons';
import { endpoints } from '../../../constants/Endpoints';
import useData from '../../../hooks/useData';
import { allExams, selectedLanguage } from '../../../providers';
import { instance } from '../../../services/https/inceptors';
import { showToast } from '../../../utilities/toast';
import { Select } from '../../../components/inputs';
import classNames from 'classnames';

const AddTestModal = ({ subject, closeModal, course, setCourse }) => {
  const [tests, setTests] = useState([]);
  const [selectedTests, setSelectedTests] = useState([]);
  const exams = useRecoilValue(allExams);
  const language = useRecoilValue(selectedLanguage);


  const {
    departments,
    subjects,
    selectedDepartment,
    selectedExam,
    selectedSubject,
    onDepartmentSelect,
    onExamSelect,
    onSubjectSelect,
  } = useData();

  const saveTest = async () => {
    try {
      if (selectedTests.length > 1) {
        showToast({
          type: 'error',
          message: 'Only 1 Test can be added!',
        });
        return;
      }

      await instance.post(endpoints.addCoursesTest, {
        tests: selectedTests,
        subjectId: subject,
      });

      const courseClone = JSON.parse(JSON.stringify(course));
      courseClone.tests.push(...selectedTests);
      setCourse(courseClone);

      showToast({ type: 'success', message: 'Successfully Added Test!' });
      closeModal();
    } catch (err) {
      console.log(err);
      showToast({ type: 'error', message: err?.response?.data?.message });
    }
  };

  const selectTest = (chapter) => {
    let clone = JSON.parse(JSON.stringify(selectedTests));

    if (clone.some((ch) => ch._id === chapter._id)) {
      clone = clone.filter((chap) => chap._id !== chapter._id);
    } else clone.push(chapter);

    setTests(clone);
  };

  const getSearchedTest = async () => {
    let url = '';
    if (selectedExam) url += `?exam=${selectedExam}`;
    if (selectedDepartment) url += `&department=${selectedDepartment}`;
    if (selectedSubject) url += `&subject=${selectedSubject}`;

    const res = await instance.get(endpoints.getWorkbookBySearch + url);

    const result = res.data.filter(
      (element2) =>
        !course?.tests?.some((element1) => element1._id === element2._id)
    );

    setTests(result);
  };

  return (
    <div className='backdrop fade show' style={{ background: 'white' }}>
      <div className='modal-dialog modal-dialog-scrollable modal-fullscreen'>
        <div className='modal-content'>
          <div className='modal-body'>
            <div className='d-flex justify-content-end cross-button'>
              <RxCross2 size={24} onClick={closeModal} />
            </div>
            <div className='container mt-3'>
              <h3 className='space-heading text-center mb-5'>Tests</h3>
              <div className='d-flex justify-content-center mt-2 gap-3 mb-4 border-1 border-top pt-4'>
                <div className='w-20'>
                  <Select
                    label='Exams'
                    value={selectedExam}
                    onChange={onExamSelect}>
                    {exams?.map((exam) => (
                      <option key={exam.code} value={exam._id}>
                        {exam.title}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className='w-20'>
                  <Select
                    label='Departments'
                    value={selectedDepartment}
                    onChange={onDepartmentSelect}>
                    {departments?.map((depart) => (
                      <option key={depart.code} value={depart._id}>
                        {depart.title}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className='w-20'>
                  <Select
                    label='Subject'
                    value={selectedSubject}
                    onChange={onSubjectSelect}>
                    {subjects?.map((subj) => (
                      <option key={subj.code} value={subj._id}>
                        {subj.title}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className='d-flex justify-content-end w-100'>
                <Button onClick={getSearchedTest} width={20}>
                  Search
                </Button>
              </div>

              {tests.length ? (
                <div className='d-flex flex-column gap-1 align-items-center mt-5'>
                  <div className='d-flex justify-content-start w-40'>
                    <div className='selected-length'>
                      <span> Selected</span>: {selectedTests.length}
                    </div>
                  </div>
                  {tests?.map((wb) => (
                    <div
                      onClick={() => selectTest(wb)}
                      className={`each-chapter d-flex c-pointer ${selectedTests.some(
                        (selected) => selected._id === wb._id
                      ) && 'selected-chapter'
                        }`}>
                      <div className={classNames('w-50 text-center border-r', {
                        '!bg-red-200': !wb.name?.[language.value]
                      })}>
                        {wb.name?.[language.value] ?? `Not available in ${language.label}`}
                      </div>
                      <div className='w-50 text-center'>{wb.marks} Marks</div>
                    </div>
                  ))}
                  <Button onClick={saveTest} className='mt-5'>
                    Proceed
                  </Button>
                </div>
              ) : (
                <div
                  style={{ marginTop: '10rem' }}
                  className='text-center fs-5'>
                  No Test Found!
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTestModal;
