import { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { Button } from "../../../components/buttons";
import { Input } from "../../../components/inputs";
import { endpoints } from "../../../constants/Endpoints";
import { instance } from "../../../services/https/inceptors";
import { showToast } from "../../../utilities/toast";

const AddTopicModal = ({ closeModal, setData, id, language }) => {
  const [topicTitle, setTopicTitle] = useState([]);

  useEffect(() => {
    setTopicTitle({ [language]: '' })
  }, [language])


  const saveTopic = async () => {
    try {
      const res = await instance.post(endpoints.addCourseChapterData, {
        chapterId: id,
        topicTitle,
      });

      if (res?.data?.data) {
        setData(res.data.data);
      }

      showToast({ type: "success", message: "Successfully Added Topic!" });
      closeModal();
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  return (
    <div className="add-modal-sm">
      <div className="d-flex justify-content-end cross-button">
        <RxCross2 size={24} onClick={closeModal} />
      </div>
      <div className="container mt-3">
        <h3 className="space-heading text-center">Add Topic</h3>

        <div className="d-flex flex-column gap-1 align-items-center mt-5">
          <Input
            className='!w-56'
            id='name'
            label='DPP Name'
            value={topicTitle?.[language]}
            onChange={e => setTopicTitle(prev => ({ ...prev, [language]: e }))}
          />

          <Button onClick={saveTopic} className="mt-4 mb-5">
            Add Topic
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddTopicModal;
