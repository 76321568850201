import { FaLongArrowAltRight } from 'react-icons/fa';
import { useRecoilValue } from 'recoil';
import { department, exam, selectedLanguage } from '../../providers/index';
import { getLanguageName } from '../../utilities/helpers';

const SelectedOptions = () => {
  const selectedExam = useRecoilValue(exam).split('/')[2];
  const selectedDepart = useRecoilValue(department).split('/')[2];
  const language = useRecoilValue(selectedLanguage).value;

  return (
    <div className='flex gap-x-1 items-center'>
      <div className='bg-gray-400 rounded-md p-2 font-medium text-white text-xs'>
        {selectedExam}
      </div>
      <FaLongArrowAltRight className='text-black text-base' />
      <div className='bg-gray-400 rounded-md p-2 font-medium text-white text-xs'>
        {selectedDepart}
      </div>
      <FaLongArrowAltRight className='text-black text-base' />
      <div className='bg-gray-400 rounded-md p-2 font-medium text-white text-xs capitalize'>
        {getLanguageName(language)}
      </div>
    </div>
  );
};

export default SelectedOptions;
