import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { instance } from "../../services/https/inceptors";
import { endpoints } from "../../constants/Endpoints";
import { Input } from "../../components/inputs";
import { Button } from "../../components/buttons";
import { useHistory } from "react-router-dom";
import { showToast } from "../../utilities/toast";
import CommonInput from "../../components/CommonInput";
import { AWS_DIRECTORIES, LANGUAGES } from "../../constants/Common";
import CommonResult from "../../components/CommonResult";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  authUser,
  selectedDepartmentObject,
  selectedLanguage,
} from "../../providers";
import ReactSelect from "react-select";

const EditSyllabus = () => {
  const { id } = useParams();
  const { goBack } = useHistory();

  const [language, setLanguage] = useRecoilState(selectedLanguage);
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const selectedDepart = useRecoilValue(selectedDepartmentObject);

  const [publish, setPublish] = useState();
  const [_id, set_id] = useState();
  const user = useRecoilValue(authUser);

  const getSyllabus = async () => {
    const res = await instance.get(`${endpoints.getSingleSyllabus}?_id=${id}`);
    setPrevious(res.data);
  };

  const setPrevious = (previous) => {
    set_id(previous._id);
    setName(previous.name);
    setPublish(previous.publish);
    setDescription(previous.description);
  };

  useEffect(() => {
    getSyllabus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = async () => {
    try {
      await instance.put(endpoints.updateSyllabus, {
        ...{ _id, name, description },
      });

      showToast({
        type: "success",
        message: "Successfully Edit Syllabus ..",
      });
      goBack();
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  const onLanguageChange = (e) => {
    setLanguage(e);
    setName((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: "" };
      else return prev;
    });

    setDescription((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: "" };
      else return prev;
    });
  };

  return (
    <div className="container">
      <h2 className="space-heading text-center mb-5">Edit Syllabus</h2>
      <div className="mb-4 px-3">
        <h6 className="mb-1">Select Language</h6>
        <ReactSelect
          onChange={onLanguageChange}
          options={selectedDepart?.languages?.map((lang) => {
            const label = Object.keys(LANGUAGES).find(
              (key) => LANGUAGES[key] === lang
            );
            return {
              value: lang,
              label: label.replace(/^./, label[0].toUpperCase()),
            };
          })}
          value={language}
          placeholder="Select Language"
          className="w-52"
        />
      </div>

      <div className="container create-screen">
        <div className="edit-section">
          <Input
            className="!w-56"
            id="name"
            label="Syllabus Name"
            value={name?.[language?.value]}
            onChange={(e) =>
              setName((prev) => ({ ...prev, [language.value]: e }))
            }
          />

          <CommonInput
            language={language.value}
            directory={AWS_DIRECTORIES.SYLLABUS}
            label="Description"
            value={description?.[language.value]}
            onChange={setDescription}
          />
        </div>
        <div className="result-section">
          <div className="pb-5">
            <h6>Name:</h6>
            <p>{name?.[language.value]}</p>
          </div>

          <CommonResult
            language={language.value}
            label="Description:"
            text={description}
          />
        </div>
      </div>

      <div className="d-flex justify-content-center mt-5 mb-5">
        {user?.role !== "master" && publish ? (
          <div className="flex items-center bg-[#ff000066] px-[6px] py-[10px] rounded-md cursor-pointer">
            Published syllabus cannot be edited.
          </div>
        ) : null}

        {user.role !== "master" && !publish && (
          <Button className="w-25" onClick={submit}>
            Submit
          </Button>
        )}

        {user?.role === "master" && (
          <Button className="w-25" onClick={submit}>
            Submit
          </Button>
        )}
      </div>
    </div>
  );
};

export default EditSyllabus;
