import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "../../components/buttons";
import { Markup } from "../../components/displays";
import { Input, Select } from "../../components/inputs";
import { Tabs } from "../../components/layouts";
import { endpoints } from "../../constants/Endpoints";
import { ANSWER_MODES } from "../../constants/LocalConstants";
import useData from "../../hooks/useData";
import { instance } from "../../services/https/inceptors";
import "../../styles/layouts.scss";
import { Type } from "../../utilities/constants";
import { years } from "../../utilities/helpers";
import { showToast } from "../../utilities/toast";

const Bulk = () => {
  const { department } = useParams();

  const { goBack } = useHistory();
  const [subjects, setSubjects] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [topics, setTopics] = useState([]);
  const [selected, setSelected] = useState([]);

  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedChapter, setSelectedChapter] = useState("");
  const [selectedTopic, setSelectedTopic] = useState("");

  const [outPut, setOutPut] = useState(false);
  const [questions, setQuestions] = useState([]);

  const [selectedYear, setSelectedYear] = useState();
  const [selectedAnswerMode, setSelectedAnswerMode] = useState();
  const [selectedMarks, setSelectedMarks] = useState();
  const [selectedSet, setSelectedSet] = useState();
  const [publish, setPublish] = useState();

  const { getSubjectByDepartment, getChaptersBySubject, getTopicsByChapter } =
    useData();

  useEffect(() => {
    const getSubject = async () => {
      const subjects = await getSubjectByDepartment(department);
      setSubjects(subjects);
    };
    getSubject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [department]);

  const fetchQuestions = async () => {
    let url = `?department=${department}`;
    if (selectedSubject) url += `&subject=${selectedSubject}`;
    if (selectedChapter) url += `&chapter=${selectedChapter}`;
    if (selectedTopic) url += `&topic=${selectedTopic}`;
    if (selectedYear) url += `&year=${selectedYear}`;
    if (selectedAnswerMode) url += `&mode=${selectedAnswerMode}`;
    if (selectedMarks) url += `&marks=${selectedMarks}`;
    if (selectedSet) url += `&set=${selectedSet}`;
    if (publish) url += `&publish=${publish}`;

    const res = await instance.get(`${endpoints.getQuestions}/${url}`);
    setQuestions(res.data);
    setOutPut(true);
  };

  const selectSubject = async (subject) => {
    setSelectedSubject(subject);
    const chapter = await getChaptersBySubject(subject);
    setChapters(chapter);
  };

  const selectChapter = async (chapter) => {
    setSelectedChapter(chapter);
    const topic = await getTopicsByChapter(chapter);
    setTopics(topic);
  };

  function selectTopic(e) {
    setSelectedTopic(e);
  }

  const exists = (question) =>
    selected.some((value) => value._id === question._id);

  const onSelect = (question) => () => {
    if (exists(question))
      return setSelected((p) => p.filter((q) => q._id !== question._id));
    setSelected((previous) => [...previous, question]);
  };

  const bulk_edit = async (state) => {
    const objectIds = [];
    selected.forEach((question) => {
      objectIds.push(question._id);
    });

    try {
      if (state === "delete") {
        if (
          window.confirm(
            "Are you sure you want to delete the selected questions?"
          )
        ) {
          await instance.delete(endpoints.deleteBulkQuestions, {
            data: { objectIds },
          });
          showToast({
            type: "success",
            message: "Successfully Deleted the question",
          });

          goBack();
        }
      }
      if (state === "publish") {
        await instance.put(endpoints.bulkPublishUnpublish, {
          objectIds,
          publish: true,
        });

        showToast({
          type: "success",
          message: "Successfully Published the selected questions",
        });

        goBack();
      }

      if (state === "unpublish") {
        const objectIds = [];
        selected.forEach((question) => {
          objectIds.push(question._id);
        });

        await instance.put(endpoints.bulkPublishUnpublish, {
          objectIds,
          publish: false,
        });

        showToast({
          type: "success",
          message: "Successfully Unpublished the selected questions",
        });

        goBack();
      }

      setSelected([]);
    } catch (err) {
      showToast({ type: "error", message: err });
    }
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <Select
            label="Subject"
            value={selectedSubject}
            onChange={selectSubject}
            vertical
          >
            {subjects?.map((sub) => (
              <option key={sub?.code} value={sub?._id}>
                {sub.title}
              </option>
            ))}
          </Select>

          <Select
            label="Set"
            value={selectedSet}
            onChange={(e) => setSelectedSet(e)}
            vertical
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,30, 31, 32, 33, 34, 35,36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50].map((set) => (
              <option key={set} value={set}>
                {set}
              </option>
            ))}
          </Select>

          <Select
            label="Mode"
            value={selectedAnswerMode}
            onChange={(e) => setSelectedAnswerMode(e)}
            vertical
          >
            {ANSWER_MODES.map((mode) => (
              <option value={mode.value}>{mode.label}</option>
            ))}
          </Select>
        </div>
        <div className="col">
          <Select
            label="Chapter"
            value={selectedChapter}
            onChange={selectChapter}
            vertical
          >
            {chapters?.map((chapter) => (
              <option key={chapter.code} value={chapter._id}>
                {chapter.title}
              </option>
            ))}
          </Select>

          <Select
            label="Publish"
            value={publish}
            onChange={setPublish}
            vertical
          >
            <option label="True" value={true}></option>
            <option label="False" value={false}></option>
          </Select>

          <Input
            value={selectedMarks}
            label="Total marks"
            onChange={setSelectedMarks}
          />
        </div>

        <div className="col">
          <Select
            label="Topic"
            value={selectedTopic}
            onChange={selectTopic}
            vertical
          >
            {topics.map((topic) => (
              <option key={topic.code} value={topic._id}>
                {topic.title}
              </option>
            ))}
          </Select>

          <Select
            label="Year"
            value={selectedYear}
            onChange={(e) => setSelectedYear(e)}
            vertical
          >
            <option value={Type.SERIES}>Test Series</option>
            <option value={Type.QUIZ}>Quiz</option>
            <option value={Type.Assignment}>Assignment</option>
            <option value={Type.DPP}>DPP</option>
            <option value={Type.Workbook}>Workbook</option>
            <option value={Type.Other}>Other</option>

            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Select>
        </div>
      </div>
      <div className="d-flex justify-content-around mt-3">
        <Button
          color="primary"
          className="btn-w"
          style={{ background: "blue" }}
          onClick={() => bulk_edit("unpublish")}
        >
          Unpublish
        </Button>
        <Button
          color="primary"
          width="25"
          className="btn-w"
          style={{ background: "blue" }}
          onClick={() => bulk_edit("publish")}
        >
          publish
        </Button>
        <Button
          color="danger"
          className="btn-w"
          width="25"
          style={{ background: "red" }}
          onClick={() => bulk_edit("delete")}
        >
          Delete
        </Button>
        <Button
          color="success"
          className="btn-w"
          width="25"
          onClick={fetchQuestions}
        >
          Go
        </Button>
      </div>
      <hr className="mb-4" />
      {outPut &&
        questions.map((question, index) => (
          <div
            key={index}
            className={classnames(
              "border rounded p-3",
              "question",
              exists(question) && "selected",
              index !== questions.length - 1 && "mb-3"
            )}
            onClick={onSelect(question)}
          >
            <Markup latex={question.text.en} className="mb-0" />

            <div className="row view-questions-data">
              <div className="col"> Subject: {question.subject.title}</div>

              <div className="col">Year: {question.year}</div>

              <div className="col">Marks: {question.marks}</div>

              <div className="col">Set: {question.set}</div>

              <div className="col">
                {" "}
                Publish: {question.publish ? "true" : "false"}
              </div>

              <div className="col">
                Mode: &nbsp;
                {question.choices && question.choices.length > 0
                  ? "Options"
                  : "Answer"}
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

const Bulk_page = () => (
  <Tabs>
    <Tabs.Button id="bquestion" target="pquestion" selected>
      Bulk Edit
    </Tabs.Button>

    <Tabs.Pane id="pquestion" label="bquestion" selected>
      <Bulk />
    </Tabs.Pane>
  </Tabs>
);

export default Bulk_page;
