import { instance } from '../services/https/inceptors';
import { endpoints } from '../constants/Endpoints';
import { showToast } from '../utilities/toast';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { authUser } from '../providers';

const useData = () => {
  const [exams, setExams] = useState([]);
  const [selectedExam, setSelectedExam] = useState('');

  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');

  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState('');

  const [chapters, setChapters] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState('');

  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState('');

  const recoilAuth = useRecoilValue(authUser);

  const getExams = async () => {
    const exams = await instance.get(
      endpoints.getAllowedExams +
      `?examPermissions=${recoilAuth.examPermissions.toString()}`
    );
    if (exams) setExams(exams?.data);
  };

  const getDepartmentByExam = async (examId) => {
    const res = await instance.get(
      endpoints.getDepartmentsByExam + `?exam=${examId}`
    );
    setDepartments(res.data);
    return res.data;
  };

  const getSubjectByDepartment = async (departmentId) => {
    const subjectResponse = await instance.get(
      `${endpoints.getSubjectsByDepartment}?_id=${departmentId}`
    );

    setSubjects(subjectResponse.data);
    return subjectResponse.data;
  };

  const getChaptersBySubject = async (subjectId) => {
    try {
      if (subjectId) {
        const res = await instance.get(
          `${endpoints.getChaptersBySubject}/?_id=${subjectId}`
        );
        setChapters(res.data);
        return res.data;
      }
    } catch (err) {
      showToast({ type: 'error', message: err.response.data.message });
    }
  };

  const getTopicsByChapter = async (chapterId) => {
    if (chapterId) {
      const res = await instance.get(
        `${endpoints.getTopicsByChapter}/?_id=${chapterId}`
      );

      setTopics(res.data);
      return res.data;
    }
  };

  const getTopicsBySubject = async (subjectId) => {
    if (subjectId) {
      const res = await instance.get(
        `${endpoints.getTopicsBySubject}/?subjectId=${subjectId}`
      );
      return res.data;
    }
  };

  const getCourseBySubject = async (subjectId) => {
    if (subjectId) {
      const res = await instance.get(
        `${endpoints.getCourseBySubject}/?subjectId=${subjectId}`
      );
      return res.data;
    }
  };

  const onExamSelect = async (exam) => {
    setSelectedDepartment('');
    setSelectedSubject('');
    setSelectedChapter('');
    setSelectedTopic('');
    setSelectedExam(exam);

    await getDepartmentByExam(exam);
  };

  const onDepartmentSelect = async (depart) => {
    setSelectedSubject('');
    setSelectedChapter('');
    setSelectedTopic('');
    setSelectedDepartment(depart);

    await getSubjectByDepartment(depart);
  };

  const onSubjectSelect = async (subject) => {
    setSelectedChapter('');
    setSelectedTopic('');
    setSelectedSubject(subject);

    await getChaptersBySubject(subject);
  };

  const onChapterSelect = async (chap) => {
    setSelectedTopic('');
    setSelectedChapter(chap);

    await getTopicsByChapter(chap);
  };

  return {
    getSubjectByDepartment,
    getChaptersBySubject,
    getTopicsByChapter,
    getDepartmentByExam,
    getCourseBySubject,
    getTopicsBySubject,
    getExams,
    exams,
    selectedExam,
    setSelectedExam,
    departments,
    selectedDepartment,
    setSelectedDepartment,
    subjects,
    selectedSubject,
    setSelectedSubject,
    chapters,
    selectedChapter,
    setSelectedChapter,
    topics,
    selectedTopic,
    setSelectedTopic,
    onExamSelect,
    onDepartmentSelect,
    onSubjectSelect,
    onChapterSelect,
  };
};

export default useData;
