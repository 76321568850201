import { showToast } from "./toast";

export const checkError = (section) => {
  if (
    section.compulsory === undefined ||
    section.compulsory === null ||
    section.compulsory === ""
  ) {
    showToast({
      type: "error",
      message: `Please select the compulsory option of section named: "${section.name}"`,
    });
    return true;
  } else if (!section.questions.length) {
    showToast({
      type: "error",
      message: `Please select the questions of section named: "${section.name}"`,
    });
    return true;
  } else if (!section.name) {
    showToast({
      type: "error",
      message: `Please select the name of section named: "${section.name}"`,
    });
    return true;
  } else return false;
};
