import { useEffect, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { useRecoilValue } from 'recoil';
import { Button } from '../../../components/buttons';
import { endpoints } from '../../../constants/Endpoints';
import { instance } from '../../../services/https/inceptors';
import { showToast } from '../../../utilities/toast';

import useData from '../../../hooks/useData';
import { allExams, selectedLanguage } from '../../../providers';
import { Select } from '../../../components/inputs';
import classNames from 'classnames';

const AddAssignmentModal = ({ data, id, closeModal, setData, subject }) => {
  const [assignments, setAssignments] = useState([]);
  const [selectedAssignment, setSelectedAssignment] = useState([]);
  const exams = useRecoilValue(allExams);
  const language = useRecoilValue(selectedLanguage);


  const {
    departments,
    subjects,
    chapters,
    selectedChapter,
    selectedDepartment,
    selectedExam,
    selectedSubject,
    onChapterSelect,
    onDepartmentSelect,
    onExamSelect,
    onSubjectSelect,
  } = useData();

  const selectAssignment = (assignment) => {
    let clone = JSON.parse(JSON.stringify(selectedAssignment));

    if (clone.some((ass) => ass._id === assignment._id)) {
      clone = clone.filter((chap) => chap._id !== assignment._id);
    } else clone.push(assignment);

    setSelectedAssignment(clone);
  };

  const saveAssignment = async () => {
    try {
      await instance.post(endpoints.addCourseAssignment, {
        assignment: selectedAssignment,
        chapterId: id,
      });

      if (data) {
        const courseClone = JSON.parse(JSON.stringify(data));
        courseClone.assignments.push(...selectedAssignment);
        setData(courseClone);
      }

      showToast({ type: 'success', message: 'Successfully Added Assignment' });
      closeModal();
    } catch (err) {
      showToast({ type: 'error', message: err.response.data.message });
    }
  };

  const getSearchedAssignments = async () => {
    let url = '';
    if (selectedExam) url += `?exam=${selectedExam}`;
    if (selectedDepartment) url += `&department=${selectedDepartment}`;
    if (selectedSubject) url += `&subject=${selectedSubject}`;
    if (selectedChapter) url += `&chapter=${selectedChapter}`;

    const res = await instance.get(endpoints.getAssignemntBySearch + url);

    const result = res.data.filter(
      (element2) =>
        !data?.assignments?.some((element1) => element1._id === element2._id)
    );

    setAssignments(result);
  };

  return (
    <div className='backdrop fade show' style={{ background: 'white' }}>
      <div className='modal-dialog modal-dialog-scrollable modal-fullscreen'>
        <div className='modal-content'>
          <div className='modal-body'>
            <div className='d-flex justify-content-end cross-button'>
              <RxCross2 size={24} onClick={closeModal} />
            </div>
            <div className='container mt-3'>
              <h3 className='space-heading text-center mb-5'>Assignments</h3>

              <div className='d-flex justify-content-center mt-2 gap-3 mb-4 border-1 border-top pt-4'>
                <div className='w-20'>
                  <Select
                    label='Exams'
                    value={selectedExam}
                    onChange={onExamSelect}>
                    {exams?.map((exam) => (
                      <option key={exam.code} value={exam._id}>
                        {exam.title}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className='w-20'>
                  <Select
                    label='Departments'
                    value={selectedDepartment}
                    onChange={onDepartmentSelect}>
                    {departments?.map((depart) => (
                      <option key={depart.code} value={depart._id}>
                        {depart.title}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className='w-20'>
                  <Select
                    label='Subject'
                    value={selectedSubject}
                    onChange={onSubjectSelect}>
                    {subjects?.map((subj) => (
                      <option key={subj.code} value={subj._id}>
                        {subj.title}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className='w-20'>
                  <Select
                    label='Chapters'
                    value={selectedChapter}
                    onChange={onChapterSelect}>
                    {chapters?.map((chap) => (
                      <option key={chap.code} value={chap._id}>
                        {chap.title}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className='d-flex justify-content-end w-100'>
                <Button onClick={getSearchedAssignments} width={20}>
                  Search
                </Button>
              </div>

              {assignments.length ? (
                <div className='d-flex flex-column gap-1 align-items-center mt-5'>
                  <div className='d-flex justify-content-start w-40'>
                    <div className='selected-length'>
                      <span> Selected</span>: {selectedAssignment.length}
                    </div>
                  </div>
                  {assignments?.map((assignment) => (
                    <div
                      onClick={() => selectAssignment(assignment)}
                      className={`each-chapter d-flex c-pointer ${selectedAssignment.some(
                        (ch) => ch._id === assignment._id
                      ) && 'selected-chapter'
                        }`}>
                      <div className={classNames('w-50 text-center border-r', {
                        '!bg-red-200': !assignment?.name?.[language.value]
                      })}>
                        {assignment?.name?.[language.value] ?? `Not available in ${language.label}`}
                      </div>
                      <div className='w-50 text-center'>
                        {assignment.marks} marks
                      </div>
                    </div>
                  ))}
                  <Button onClick={saveAssignment} className='mt-5'>
                    Proceed
                  </Button>
                </div>
              ) : (
                <div
                  style={{ marginTop: '10rem' }}
                  className='text-center fs-5'>
                  No Assignments Found!
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAssignmentModal;
