import { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import ImgAvatar from '../../assets/images/default-avatar.png';
import logo from '../../assets/images/logo_name.png';
import { authUser } from '../../providers/index';
import DropDown from './drop-down';
import './style.scss';

const Header = () => {
  const recoilAuth = useRecoilValue(authUser);

  const { pathname } = useLocation();
  const history = useHistory();
  const [toggle, setToggle] = useState(false);

  if (pathname === '/login') return;

  return (
    <header className='position-relative mb-5 header'>
      <div className='brand'>
        {pathname !== '/' && (
          <button className='btn' onClick={() => history.goBack()}>
            <i className='bi bi-arrow-left'></i>
          </button>
        )}
        <Link to='/'>
          <img src={logo} alt='set2score logo ' style={{ height: '40px' }} />
        </Link>
      </div>

      {recoilAuth && (
        <div className='d-flex gap-4 items-center'>
          {(recoilAuth?.role === 'master' || recoilAuth?.role === 'exam') && (
            <div className='d-flex'>
              <button
                style={{ background: '#024f10', padding: '8px 20px' }}
                className='btn btn-primary !w-40'
                onClick={() => history.push('/add-sections')}>
                Add Section
              </button>
            </div>
          )}

          <div className='drop-down-avatar' onClick={() => setToggle(!toggle)}>
            <div className='!w-10'>
              <img
                src={ImgAvatar}
                alt='avatar'
                className='rounded-circle !w-full'
              />
            </div>
            <div>
              <h6 className='username-avatar'>{recoilAuth.username}</h6>
            </div>
          </div>

          {toggle && <DropDown setToggle={setToggle} toggle={toggle} />}
        </div>
      )}
    </header>
  );
};

export default Header;
