import { MODULES } from "./Common";
import { endpoints } from "./Endpoints";

export const ENDPOINT_MAP = {
  [MODULES.ASSIGNMENT]: {
    delete: endpoints.deleteAssignment,
    update: endpoints.updateAssignment,
    add: endpoints.addAssignemnt,
    togglePublish: endpoints.toggleAssignmentPublish,
    toggleDisplay: endpoints.toggleAssignmentDisplay,
    toggleLock: endpoints.toggleAssignmentLock,
    updateRedirectLink: "/edit-assignment",
  },

  [MODULES.DPP]: {
    delete: endpoints.deleteDpp,
    update: endpoints.updateDpp,
    add: endpoints.addDpp,
    togglePublish: endpoints.toggleDppPublish,
    toggleDisplay: endpoints.toggleDppDisplay,
    toggleLock: endpoints.toggleDppLock,
    updateRedirectLink: "/edit-dpp",
  },

  [MODULES.WORK_BOOK]: {
    delete: endpoints.deleteWorkbook,
    update: endpoints.updateWorkbook,
    add: endpoints.addWorkbook,
    togglePublish: endpoints.toggleWorkbookPublish,
    toggleDisplay: endpoints.toggleWorkbookDisplay,
    toggleLock: endpoints.toggleWorkbookLock,
    updateRedirectLink: "/edit-workbook",
  },

  [MODULES.NOTIFICATION]: {
    delete: endpoints.deleteNotification,
    update: endpoints.updateNotification,
    add: endpoints.addNotification,
    togglePublish: endpoints.toggleNotificationPublish,
    toggleDisplay: endpoints.toggleNotificationDisplay,
    updateRedirectLink: "/edit-notification",
  },

  [MODULES.SYLLABUS]: {
    delete: endpoints.deleteSyllabus,
    update: endpoints.updateSyllabus,
    add: endpoints.addSyllabus,
    togglePublish: endpoints.toggleSyllabusPublish,
    toggleDisplay: endpoints.toggleSyllabusDisplay,
    updateRedirectLink: "/edit-syllabus",
  },

  [MODULES.YEAR_CUT_OFF]: {
    delete: endpoints.deleteYearCutOff,
    update: endpoints.updateYearCutOff,
    add: endpoints.addYearCutOff,
    togglePublish: endpoints.toggleYearCutOffPublish,
    toggleDisplay: endpoints.toggleYearCutOffDisplay,
    updateRedirectLink: "/edit-yearCutOff",
  },

  [MODULES.EXAM_ANALYSIS]: {
    delete: endpoints.deleteExamAnalyis,
    update: endpoints.updateExamAnalyis,
    add: endpoints.addExamAnalysis,
    togglePublish: endpoints.toggleExamAnalyisPublish,
    toggleDisplay: endpoints.toggleExamAnalyisDisplay,
    updateRedirectLink: "/edit-examAnalysis",
  },

  [MODULES.GENERAL_VIDEO]: {
    delete: endpoints.deleteVideo,
    update: endpoints.updateVideo,
    add: endpoints.addVideo,
    togglePublish: endpoints.toggleVideoPublish,
    toggleDisplay: endpoints.toggleVideoDisplay,
    updateRedirectLink: "/edit-generalVideo",
  },
};
