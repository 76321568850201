import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { RadioGroup, Select } from "../../components/inputs";
import { Tabs } from "../../components/layouts";
import { Type } from "../../utilities/constants";
import { years } from "../../utilities/helpers";
import { Button, Submit } from "../../components/buttons";
import { useRecoilValue } from "recoil";
import { department, exam } from "../../providers";

const SelectQuestionType = () => {
  const { push } = useHistory();
  const [type, setType] = useState("");
  const [year, setYear] = useState("");
  const [set, setSet] = useState("");
  const departmentState = useRecoilValue(department);
  const examState = useRecoilValue(exam);

  const allTypes = [
    { key: "previous", title: "Previous Years" },
    { key: "series", title: "Test Series" },
    { key: "quiz", title: "Quiz" },
    { key: "dpp", title: "DPP" },
    { key: "assignment", title: "Assignment" },
    { key: "workbook", title: "Work Book" },
    { key: "other", title: "Other" },
  ];

  function onSubmit(event) {
    event.preventDefault();

    let selectedYear = year;
    if (type === "series") selectedYear = Type.SERIES;
    if (type === "quiz") selectedYear = Type.QUIZ;
    if (type === "dpp") selectedYear = Type.DPP;
    if (type === "assignment") selectedYear = Type.Assignment;
    if (type === "workbook") selectedYear = Type.Workbook;
    if (type === "other") selectedYear = Type.Other;

    push("/new/question", {
      year: selectedYear,
      set,
    });
  }

  return (
    <form className="horizontal" onSubmit={onSubmit}>
      <h5>Exam: {examState.split("/")[2]}</h5>
      <h5>Department: {departmentState.split("/")[2]}</h5>

      <hr />

      <div className="row mt-4">
        <div className="col">
          <RadioGroup name="type" onChange={setType}>
            {allTypes.map((singleType, index) => {
              return (
                <RadioGroup.Button key={index} value={singleType.key}>
                  <Button width="100" disabled={type !== singleType.key}>
                    {singleType.title}
                  </Button>
                </RadioGroup.Button>
              );
            })}
          </RadioGroup>
        </div>

        <div className="col">
          {type === "previous" && (
            <>
              <Select label="Year" value={year} onChange={setYear} vertical>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Select>

              <Select label="Set" value={set} onChange={setSet} vertical>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option>
                <option value={11}>11</option>
                <option value={12}>12</option>
                <option value={13}>13</option>
                <option value={14}>14</option>
                <option value={15}>15</option>
                <option value={16}>16</option>
                <option value={17}>17</option>
                <option value={18}>18</option>
                <option value={19}>19</option>
                <option value={20}>20</option>
                <option value={21}>21</option>
                <option value={22}>22</option>
                <option value={23}>23</option>
                <option value={24}>24</option>
                <option value={25}>25</option>
                <option value={26}>26</option>
                <option value={27}>27</option>
                <option value={28}>28</option>
                <option value={29}>29</option>
                <option value={30}>30</option>
                <option value={31}>31</option>
                <option value={32}>32</option>
                <option value={33}>33</option>
                <option value={34}>34</option>
                <option value={35}>35</option>
                <option value={36}>36</option>
                <option value={37}>37</option>
                <option value={38}>38</option>
                <option value={39}>39</option>
                <option value={40}>40</option>
                <option value={41}>41</option>
                <option value={42}>42</option>
                <option value={43}>43</option>
                <option value={44}>44</option>
                <option value={45}>45</option>
                <option value={46}>46</option>
                <option value={47}>47</option>
                <option value={48}>48</option>
                <option value={49}>49</option>
                <option value={50}>50</option>
              </Select>
            </>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <Submit
          width="25"
          disabled={!type || (type === "previous" && (!year || !set))}
        />
      </div>
    </form>
  );
};

// const Series = () => {
//   const { post } = useFetch();
//   const { department } = useParams();

//   const [name, setName] = useState("");
//   const [time, setTime] = useState(0);
//   const [questions, setQuestions] = useState([]);
//   const [mode, setMode] = useState("");

//   function onSubmit(event) {
//     event.preventDefault();

//     const data = { name, time, department, questions, mode };

//     post(SERIES + "new", data, (error, _) => {
//       if (error) {
//         return;
//       }

//       setName("");
//       setTime(0);
//       setQuestions([]);
//       setMode("");
//     });
//   }

//   return (
//     <form className="horizontal" onSubmit={onSubmit}>
//       <div className="d-flex flex-column align-items-center mt-2">
//         <Input id="name" label="Name" value={name} onChange={setName}>
//           Name
//         </Input>

//         <Time value={time} onChange={setTime} />

//         <Select label="Type" value={mode} onChange={setMode}>
//           <option value="full">Full Length</option>
//           <option value="subject">Subject Wise</option>
//         </Select>
//       </div>

//       <hr />

//       <Selector
//         type={Type.SERIES}
//         questions={questions}
//         onChange={setQuestions}
//       />

//       <div className="d-flex justify-content-end mt-3">
//         <Submit
//           width="25"
//           disabled={!name || time === 0 || questions.length === 0}
//         />
//       </div>
//     </form>
//   );
// };

// const Quiz = () => {
//   const { post } = useFetch();
//   const { department } = useParams();

//   const [name, setName] = useState("");
//   const [time, setTime] = useState(0);
//   const [questions, setQuestions] = useState([]);

//   function onSubmit(event) {
//     event.preventDefault();

//     const data = { name, time, department, questions };

//     post(QUIZ + "new", data, (error, _) => {
//       if (error) {
//         return;
//       }

//       setName("");
//       setTime(0);
//       setQuestions([]);
//     });
//   }

//   return (
//     <form className="horizontal" onSubmit={onSubmit}>
//       <div className="d-flex flex-column align-items-center mt-2">
//         <Input id="name" label="Name" value={name} onChange={setName}>
//           Name
//         </Input>

//         <Time value={time} onChange={setTime} />
//       </div>

//       <hr />

//       <Selector
//         type={Type.QUIZ}
//         questions={questions}
//         onChange={setQuestions}
//       />

//       <div className="d-flex justify-content-end mt-3">
//         <Submit
//           width="25"
//           disabled={!name || time === 0 || questions.length === 0}
//         />
//       </div>
//     </form>
//   );
// };

const NewPage = () => (
  <Tabs>
    <Tabs.Button id="bquestion" target="pquestion" selected>
      New <br /> Question
    </Tabs.Button>

    <Tabs.Pane id="pquestion" label="bquestion" selected>
      <SelectQuestionType />
    </Tabs.Pane>
  </Tabs>
);

export default NewPage;
