import { useEffect, useState } from "react";
import { BsCheck2All } from "react-icons/bs";
import { ImBlocked } from "react-icons/im";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ENDPOINT_MAP } from "../../constants/Action";
import { authUser } from "../../providers/index";
import { instance } from "../../services/https/inceptors";
import { showToast } from "../../utilities/toast";
import classNames from 'classnames';
import { getLanguageName } from '../../utilities/helpers';

const Actions = ({ data, setData, type, primary, language }) => {
  const { push } = useHistory();
  const user = useRecoilValue(authUser);
  const { department } = useParams();
  const [actionEndpoints, setActionEndpoints] = useState();

  useEffect(() => {
    setActionEndpoints(ENDPOINT_MAP[type]);
  }, [type]);

  const onDelete = async (e) => {
    if (window.confirm("Are you sure you want to delete?")) {
      try {
        const res = await instance.delete(
          `${actionEndpoints?.delete}?_id=${e}`
        );
        const filteredData = data?.filter((dpp) => dpp._id !== res.data._id);
        setData(filteredData);
        showToast({ type: "success", message: "Successfully Deleted" });
      } catch (err) {
        showToast({ type: "error", message: err.response.data.message });
      }
    }
  };

  const togglePublish = async (toggleData) => {
    try {
      const res = await instance.put(
        `${actionEndpoints.togglePublish}?_id=${toggleData._id}&publishedBy=${user.userId}`
      );

      const clone = [...data];
      clone.forEach((dpp) => {
        if (dpp?._id === res?.data?._id) dpp.publish = !toggleData.publish;
      });

      setData(clone);

      showToast({
        type: "success",
        message: toggleData.publish
          ? "Successfully Published"
          : "Successfully Unpublished",
      });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  const toggleLock = async (lockData) => {
    try {
      const res = await instance.put(
        `${actionEndpoints.toggleLock}?_id=${lockData._id}`
      );

      const clone = [...data];
      clone.forEach((dpp) => {
        if (dpp?._id === res?.data?._id) dpp.lock = !lockData.lock;
      });
      setData(clone);
      showToast({
        type: "success",
        message: lockData?.lock ? "Successfully Unlock" : "Successfully Lock",
      });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  const toggleDisplay = async (diplayData) => {
    try {
      const res = await instance.put(
        `${actionEndpoints.toggleDisplay}?_id=${diplayData._id}`
      );

      const clone = [...data];
      clone.forEach((dpp) => {
        if (dpp?._id === res?.data?._id) dpp.display = !diplayData.display;
      });
      setData(clone);
      showToast({
        type: "success",
        message: diplayData.display
          ? "Successfully Unhide"
          : "Successfully Hide",
      });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  return (
    <div>
      {data?.map((data) => (
        <div key={data._id} className="previous-years-actions mt-5">
          <div className={classNames('relative flex items-center p-2 rounded-sm', {
            'bg-[#b2b2b2]': data.publish,
            'bg-[#c6d7c9]': !data.publish,
            'bg-red-200': !data?.name?.[language.value] && !data?.title,
            '!bg-red-100': !data?.name?.[language.value] && !data?.title && data.publish
          })}>
            <h6>{data?.name?.[language.value] ?? data?.title ?? `Not available in ${language.label}`}</h6>
            <div className='flex absolute justify-start gap-x-1 -bottom-7 left-0'>
              {Object.keys(data?.name ?? {}).map((lang) =>
                data.name?.[lang].length ? (
                  <div className='capitalize bg-slate-400 text-white px-[6px] py-1 rounded-sm text-[10px]'>
                    {getLanguageName(lang)}
                  </div>
                ) : null
              )}
            </div>
          </div>

          <div className="actions-on-right">
            <div>
              <i
                className="bi bi-eye-fill"
                style={{ fontSize: "2rem", cursor: "pointer" }}
                onClick={() => {
                  push(
                    `${actionEndpoints.updateRedirectLink}/${data._id}/${department}`
                  );
                }}
              ></i>
            </div>

            {!data.publish && (
              <div>
                <i
                  className="bi bi-trash cursor-pointer "
                  style={{ fontSize: "2rem", cursor: "pointer" }}
                  onClick={() => onDelete(data._id)}
                ></i>
              </div>
            )}

            {user.role === "master" && (
              <div className="publish-unpublish">
                <div onClick={() => togglePublish(data)}>
                  {!data?.publish === false ? <ImBlocked /> : <BsCheck2All />}
                </div>
              </div>
            )}

            {!primary ? (
              <div>
                <div onClick={() => toggleLock(data)}>
                  {data?.lock === false ? (
                    <i
                      className="bi bi-unlock-fill c-pointer"
                      style={{ color: `rgb(2, 79, 16)`, fontSize: "2rem" }}
                    ></i>
                  ) : (
                    <i
                      className="bi bi-lock-fill c-pointer"
                      style={{ color: `rgb(2, 79, 16)`, fontSize: "2rem" }}
                    ></i>
                  )}
                </div>
              </div>
            ) : null}

            <div onClick={() => toggleDisplay(data)}>
              {data?.display ? (
                <div className="hide">Hide</div>
              ) : (
                <div className="show">Show</div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Actions;
