import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Submit } from '../../components/buttons';
import { Markup, Options, Result, Score } from '../../components/displays';
import { Input, Select } from '../../components/inputs';
import {
  Answer,
  Choices,
  Marks,
  Mode,
  Question,
  Solution,
  Length,
} from '../../components/question';
import { choiceLabel, years as ALL_YEARS } from '../../utilities/helpers';
import { useRecoilValue } from 'recoil';
import {
  exam as recoilExam,
  department as recoilDepart,
  selectedDepartmentObject,
} from '../../providers';

import useData from '../../hooks/useData';
import { authUser } from '../../providers/index';
import { QuestionTypes, Type } from '../../utilities/constants';
import { instance } from '../../services/https/inceptors';
import { endpoints } from '../../constants/Endpoints';
import { showToast } from '../../utilities/toast';
import ReactSelect from 'react-select';
import { LANGUAGES } from '../../constants/Common';

const AddQuestion = () => {
  const selectedDepart = useRecoilValue(selectedDepartmentObject);
  const [language, setSelectedLanguage] = useState({
    value: 'en',
    label: 'English',
  });

  const { state } = useLocation();
  const [set, setSet] = useState();
  const [year, setYear] = useState('');
  const [level, setLevel] = useState('');
  const [topic, setTopic] = useState('');
  const [topics, setTopics] = useState([]);
  const [images, setImages] = useState([]);
  const [choices, setChoices] = useState([]);
  const [subject, setSubject] = useState('');
  const [chapter, setChapter] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [negativeMarks, setNegativeMarks] = useState('0');
  const [positiveMarks, setPositiveMarks] = useState('1');
  const [mode, setMode] = useState(QuestionTypes.ANSWER);
  const [solutionImages, setSolutionImages] = useState([]);

  const [text, setText] = useState({ [language.value]: '' });
  const [answer, setAnswer] = useState({ [language.value]: '' });
  const [video, setVideo] = useState({ [language.value]: '' });
  const [rawSolution, setSolution] = useState({
    text: { [language.value]: '' },
  });

  const examState = useRecoilValue(recoilExam);
  const departmentState = useRecoilValue(recoilDepart);
  const user = useRecoilValue(authUser);
  const data = useData();



  useEffect(() => {
    const getSubjects = async () => {
      const res = await data.getSubjectByDepartment(
        departmentState.split('/')[1]
      );
      setSubjects(res);
    };

    if (departmentState && examState) getSubjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentState, examState]);

  function invalid() {
    let check =
      mode === QuestionTypes.MCQ || mode === QuestionTypes.MSQ ? false : true;
    let i = 0;

    if (mode === QuestionTypes.MCQ || mode === QuestionTypes.MSQ) {
      choices.forEach((c) => {
        if (c.answer === true && mode === QuestionTypes.MCQ) {
          check = true;
        }

        if (c.answer === true && mode === QuestionTypes.MSQ) {
          i++;
          if (i > 0) {
            check = true;
          }
        }
      });
    }

    if (!subject || !check || !year) {
      return true;
    }
    return false;
  }

  const onSubmit = async (event) => {
    event.preventDefault();

    const marks_ = {
      negative: negativeMarks,
      positive: positiveMarks,
    };

    const choices_ = [];

    choices.forEach((choice) => {
      choices_.push({
        answer: choice.answer,
        text: choice.text,
      });
    });
    const subject_data = subject.split('/');
    const chapter_data = chapter.split('/');
    const topic_data = topic.split('/');

    const data = {
      answer,
      solution: { text: rawSolution.text },
      video,
      text,
      year,
      markingRule: marks_,
      exam: examState.split('/')[1],
      department: departmentState.split('/')[1],
      choices: choices_,
      subject: subject_data[1],
      set,
      mode,
      postedBy: user.userId,
      chapter: chapter_data[1],
      level,
      topic: topic_data[1],
      ...state,
    };

    try {
      const res = await instance.post(endpoints.addQuestion, data);

      if (res) {
        showToast({
          type: 'success',
          message: 'The question is successfully uploaded',
        });

        setText({ [language.value]: '' });
        setAnswer({ [language.value]: '' });
        setSolution({ text: { [language.value]: '' } });
        setImages([]);
        setMode(Mode.answer);
        setChoices([])
      }
    } catch (err) {
      showToast({ type: 'error', message: err.response.data.message });
    }
  };

  const getChapter = async (e) => {
    setSubject(e);
    const res = await data.getChaptersBySubject(e.split('/')[1]);
    setChapters(res);
  };

  const getTopics = async (e) => {
    setChapter(e);
    const res = await data.getTopicsByChapter(e.split('/')[1]);
    setTopics(res);
  };

  const onSolutionImageChange = (e) => {
    const imageClone = [...solutionImages];
    imageClone.push(e);
    setSolutionImages(imageClone);
  };

  const onLanguageChange = useCallback((e) => {
    setSelectedLanguage(e);
    setAnswer((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: '' };
      else return prev;
    });

    setText((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: '' };
      else return prev;
    });

    setVideo((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: '' };
      else return prev;
    });

    setChoices((prev) => {
      return prev.map((choice) => {
        if (!choice.text[e.value]) {
          return { ...choice, text: { ...choice.text, [e.value]: '' } };
        } else return choice;
      });
    });
  }, []);

  return (
    <div className='container h-100 py-5'>
      <h4 className='space-heading text-center -mt-12 mb-4'>Add Question</h4>
      <div className='row h-100'>
        <div className='mb-4 flex items-center justify-between gap-x-4 flex-wrap'>
          <div className='-mt-3'>
            <h6 className='mb-1'>Select Question Language</h6>
            <ReactSelect
              onChange={onLanguageChange}
              options={selectedDepart?.languages?.map((lang) => {
                const label = Object.keys(LANGUAGES).find(
                  (key) => LANGUAGES[key] === lang
                );
                return {
                  value: lang,
                  label: label.replace(/^./, label[0].toUpperCase()),
                };
              })}
              value={language}
              placeholder='Select Language'
              className='w-52'
            />
          </div>
          <div className='!w-48'>
            <Select
              label='Subject'
              value={subject}
              onChange={getChapter}
              vertical>
              {subjects?.length != 0 &&
                subjects.map((subject) => (
                  <option
                    key={subject.code}
                    value={subject.code + '/' + subject._id}>
                    {subject.title}
                  </option>
                ))}
            </Select>
          </div>

          <div className='!w-48'>
            <Select
              label='Chapter'
              value={chapter}
              onChange={getTopics}
              vertical>
              {chapters?.length != 0 &&
                chapters?.map((chapter) => (
                  <option
                    key={chapter.code}
                    value={chapter.code + '/' + chapter._id}>
                    {chapter.title}
                  </option>
                ))}
            </Select>
          </div>

          <div className='!w-48'>
            <Select label='Topic' value={topic} onChange={setTopic} vertical>
              {topics?.length !== 0 &&
                topics?.map((topic) => (
                  <option key={topic.code} value={topic.code + '/' + topic._id}>
                    {topic.title}
                  </option>
                ))}
            </Select>
          </div>

          <div className='!w-48'>
            <Select label='Difficulty Level' value={level} onChange={setLevel}>
              <option value='Easy'>Easy</option>
              <option value='Medium'>Medium</option>
              <option value='Hard'>Hard</option>
            </Select>
          </div>

          <div className='!w-48'>
            <Select
              label='Year'
              value={year}
              onChange={(e) => setYear(e)}
              vertical>
              <option value={Type.SERIES}>Test Series</option>
              <option value={Type.QUIZ}>Quiz</option>
              <option value={Type.Assignment}>Assignment</option>
              <option value={Type.DPP}>DPP</option>
              <option value={Type.Workbook}>Workbook</option>
              <option value={Type.Other}>Other</option>

              {ALL_YEARS.map((yearItem) => (
                <option key={yearItem} value={yearItem}>
                  {yearItem}
                </option>
              ))}
            </Select>
          </div>

          <div className='!w-48'>
            <Select label='Set' value={set} onChange={setSet} vertical>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
              <option value={11}>11</option>
              <option value={12}>12</option>
              <option value={13}>13</option>
              <option value={14}>14</option>
              <option value={15}>15</option>
              <option value={16}>16</option>
              <option value={17}>17</option>
              <option value={18}>18</option>
              <option value={19}>19</option>
              <option value={20}>20</option>
              <option value={21}>21</option>
              <option value={22}>22</option>
              <option value={23}>23</option>
              <option value={24}>24</option>
              <option value={25}>25</option>
              <option value={26}>26</option>
              <option value={27}>27</option>
              <option value={28}>28</option>
              <option value={29}>29</option>
              <option value={30}>30</option>
              <option value={31}>31</option>
              <option value={32}>32</option>
              <option value={33}>33</option>
              <option value={34}>34</option>
              <option value={35}>35</option>
              <option value={36}>36</option>
              <option value={37}>37</option>
              <option value={38}>38</option>
              <option value={39}>39</option>
              <option value={40}>40</option>
              <option value={41}>41</option>
              <option value={42}>42</option>
              <option value={43}>43</option>
              <option value={44}>44</option>
              <option value={45}>45</option>
              <option value={46}>46</option>
              <option value={47}>47</option>
              <option value={48}>48</option>
              <option value={49}>49</option>
              <option value={50}>50</option>
            </Select>
          </div>
        </div>

        <div className='col'>
          <div className='question-scroll'>
            <form id='form' onSubmit={onSubmit}>
              <Mode value={mode} onChange={setMode} />
              <Question
                language={language.value}
                value={text[language.value]}
                onChange={setText}
              />

              <div className='d-flex flex-row justify-content-between'>
                <Marks value={positiveMarks} onChange={setPositiveMarks} />
                <Marks
                  value={negativeMarks}
                  name='Negative Marks'
                  onChange={setNegativeMarks}
                />
              </div>

              {(mode === QuestionTypes.MCQ || mode === QuestionTypes.MSQ) && (
                <Length
                  choices={choices}
                  onChange={setChoices}
                  language={language.value}
                />
              )}
              {mode && mode === QuestionTypes.MCQ && (
                <p>Select one choice as correct answer</p>
              )}

              {mode === QuestionTypes.ANSWER && (
                <Answer
                  language={language.value}
                  value={answer[language.value]}
                  onChange={setAnswer}
                />
              )}

              {(mode === QuestionTypes.MCQ || mode === QuestionTypes.MSQ) && (
                <Choices
                  values={choices}
                  mode={mode}
                  onChange={setChoices}
                  language={language.value}
                />
              )}

              <Solution
                language={language.value}
                solutionImage={onSolutionImageChange}
                value={rawSolution}
                onChange={setSolution}
              />

              <Input
                className='!w-56'
                id='name'
                label='Video'
                value={video?.[language?.value]}
                onChange={e => setVideo(prev => ({ ...prev, [language.value]: e }))}
              />
            </form>
          </div>
        </div>

        <div className='col'>
          <div className='question-scroll'>
            <h6 className='font-semibold'>Question: </h6>

            <Markup
              latex={text[language.value]}
              className={images?.length < 1 && choices.length < 1 && 'mb-0'}
            />
            <hr className='mt-2' />

            <Score
              positveMarks={positiveMarks}
              negativeMarks={negativeMarks}
              className='mt-3'
              label
            />
            <hr className='mt-4' />
            {mode === QuestionTypes.MCQ || mode === QuestionTypes.MSQ ? (
              <div className='mt-3'>
                <h6 className='font-semibold mb-2'>Options: </h6>
                <Options language={language.value} choices={choices} onChange={setChoices} />
              </div>
            ) : null}
            <hr className='mt-3' />
            <div className='mt-3 !mb-3'>
              <Result language={language.value} solution={rawSolution} />
            </div>
            {text && (
              <div>
                {mode === QuestionTypes.ANSWER && (
                  <div>
                    <hr className='mb-2' />
                    <Markup
                      label='Correct Answer:'
                      latex={answer[language.value]}
                    />
                  </div>
                )}

                <div className='mb-5' style={{ lineHeight: 0.5 }}>
                  {(mode === 'mcq' || mode === 'msq') &&
                    choices.map(
                      (choice, index) =>
                        choice.answer && (
                          <p className='mt-3' key={index}>
                            Correct Answer: Choice {choiceLabel(index)}
                          </p>
                        )
                    )}
                </div>

                <div className='d-flex justify-content-center mt-3'>
                  <Submit width='75' form='form' disabled={invalid()} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddQuestion;
