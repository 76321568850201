import { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Button, Submit } from "../../components/buttons";
import { Input, Select, Time } from "../../components/inputs";
import { Section } from "../../components/Section";
import { endpoints } from "../../constants/Endpoints";
import useData from "../../hooks/useData";
import { authUser, selectedDepartmentObject } from "../../providers/index";
import { instance } from "../../services/https/inceptors";
import { showToast } from "../../utilities/toast";
import { checkError } from "../../utilities/error";
import CommonInput from "../../components/CommonInput";
import { AWS_DIRECTORIES, LANGUAGES } from "../../constants/Common";
import ReactSelect from "react-select";

const EditAssignment = () => {
  const { goBack } = useHistory();
  const { id, department } = useParams();
  const { getSubjectByDepartment, getChaptersBySubject } = useData();

  const user = useRecoilValue(authUser);
  const selectedDepart = useRecoilValue(selectedDepartmentObject);

  const [time, setTime] = useState(0);
  const [name, setName] = useState({});
  const [marks, setMarks] = useState(0);
  const [publish, setPublish] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [instructions, setInstructions] = useState({});
  const [sectionChoice, setSectionChoice] = useState();
  const [selectedSubject, setSelectedSubject] = useState();
  const [selectedChapter, setSelectedChapter] = useState();
  const [toRenderSection, setToRenderSection] = useState([]);
  const [language, setSelectedLanguage] = useState({
    value: "en",
    label: "English",
  });

  const getData = async () => {
    const res = await instance.get(
      endpoints.getSingleAssignment + `?_id=${id}`
    );
    setPrevious(res.data);
  };

  const fetchSubjects = async () => {
    const subjects = await getSubjectByDepartment(department);
    setSubjects(subjects);
  };

  const fetchChapters = async () => {
    const res = await getChaptersBySubject(selectedSubject);
    setChapters(res);
  };

  useEffect(() => {
    if (id) getData();
    if (department) fetchSubjects();
  }, [id, department]);

  useEffect(() => {
    if (selectedSubject) {
      fetchChapters();
    }
  }, [selectedSubject]);

  function setPrevious(value) {
    value.sections.forEach((sec, i) => (sec.sectionId = "section" + i));
    setPublish(value.publish);
    setSelectedSubject(value.subject);
    setSelectedChapter(value.chapter);
    setName(value.name);
    setInstructions(value.instructions);
    setTime(value.time);
    setMarks(value.marks);
    setToRenderSection(value.sections);
    setSectionChoice(value.choice);
  }

  const handleSection = () => {
    let index = toRenderSection.length;
    let flag = true;
    while (flag === true) {
      let temp = false;
      // eslint-disable-next-line no-loop-func
      toRenderSection.forEach((sec) => {
        if (sec.sectionId === `section${index}`) temp = true;
      });
      if (temp) {
        flag = true;
        index++;
      } else flag = false;
    }
    const toAdd = {
      name: "",
      questions: [],
      sectionId: "section" + index,
      tackle: 0,
      compulsory: null,
    };
    setToRenderSection([...toRenderSection, toAdd]);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    let allSubjects = [];
    for (let i = 0; i <= subjects.length; i++) {
      if (formData.get("checkbox" + i)) {
        allSubjects.push(formData.get("checkbox" + i));
      }
    }

    let questionIds = [];
    let returnFunc = false;
    const sectionsClone = JSON.parse(JSON.stringify(toRenderSection));

    sectionsClone.forEach((section) => {
      returnFunc = checkError(section);
      section.questions.forEach((question) => {
        questionIds.push(question._id);
      });
      section.questions = questionIds;
      questionIds = [];
    });

    if (returnFunc) return;

    const data = {
      _id: id,
      name,
      time,
      marks,
      updatedBy: user?.userId,
      instructions,
      sections: sectionsClone,
      choice: sectionChoice,
      subject: selectedSubject,
      chapter: selectedChapter,
    };

    try {
      await instance.put(endpoints.updateAssignment, data);
      showToast({
        type: "success",
        message: "Successfully Updated the Assignment",
      });
      goBack();
    } catch (err) {
      showToast({
        type: "error",
        message: err?.response?.data.message,
      });
    }
  };

  const deleteSection = (selected) =>
    setToRenderSection((prev) =>
      prev.filter((sec) => sec.sectionId !== selected.sectionId)
    );

  const onLanguageChange = (e) => {
    setSelectedLanguage(e);

    setName((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: "" };
      else return prev;
    });

    setInstructions((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: "" };
      else return prev;
    });
  };

  const allQuestions = useMemo(() => {
    const data = [];
    toRenderSection.forEach((section) =>
      section.questions.forEach((ques) => data.push(ques))
    );
    return data;
  }, [toRenderSection]);

  return (
    <div className="container mb-5">
      <div className="d-flex justify-content-center">
        <h2 className="mb-5 bolder space-heading">Edit Assignment</h2>
      </div>
      <form className="horizontal" onSubmit={onSubmit}>
        <div className="flex flex-wrap gap-x-7">
          <Input
            id="name"
            label="Name"
            value={name?.[language.value]}
            onChange={(e) =>
              setName((prev) => ({ ...prev, [language.value]: e }))
            }
          />

          <Input value={marks} onChange={setMarks} label="Marks" />
          <Time value={time} onChange={setTime} label="Select Time" />
          <Select
            value={selectedSubject}
            onChange={setSelectedSubject}
            label="Subject"
          >
            {subjects?.map((item, index) => (
              <option value={item._id} key={index}>
                {item.title}
              </option>
            ))}
          </Select>
          <Select
            value={selectedChapter}
            onChange={setSelectedChapter}
            label="Chapter"
          >
            {chapters?.map((item, index) => (
              <option value={item._id} key={index}>
                {item.title}
              </option>
            ))}
          </Select>

          <div className="mt-1">
            <h6 className="mb-1">Select Language</h6>
            <ReactSelect
              onChange={onLanguageChange}
              options={selectedDepart?.languages?.map((lang) => {
                const label = Object.keys(LANGUAGES).find(
                  (key) => LANGUAGES[key] === lang
                );
                return {
                  value: lang,
                  label: label.replace(/^./, label[0].toUpperCase()),
                };
              })}
              value={language}
              placeholder="Select Language"
              className="w-52"
            />
          </div>
        </div>
        <div className="mt-4 mb-3 mx-4">
          <CommonInput
            language={language.value}
            directory={AWS_DIRECTORIES.ASSIGNMENT}
            label="General Instructions"
            value={instructions[language.value]}
            onChange={setInstructions}
          />
        </div>

        {toRenderSection.map((section, index) => {
          return (
            <Section
              allQuestions={allQuestions}
              language={language}
              key={index + section.sectionId}
              index={index}
              section={section}
              setToRenderSection={setToRenderSection}
              deleteSection={deleteSection}
            />
          );
        })}
        <div className="d-flex justify-content-start mt-3 ">
          <Button width="25" onClick={handleSection}>
            Add Section
          </Button>
        </div>
        <div className="d-flex justify-content-end mt-3">
          {user?.role !== "master" && publish ? (
            <div className="flex items-center bg-[#ff000066] px-[6px] py-[10px] rounded-md cursor-pointer">
              Published Assignment cannot be edited
            </div>
          ) : null}

          {user.role !== "master" && !publish && (
            <Submit
              width="25"
              disabled={
                time === 0 || !name || !marks || !toRenderSection.length
              }
            />
          )}

          {user?.role === "master" && (
            <Submit
              width="25"
              disabled={
                time === 0 ||
                !name[language.value] ||
                !marks ||
                !toRenderSection.length
              }
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default EditAssignment;
