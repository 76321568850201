import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Button } from '../../components/buttons';
import { Markup } from '../../components/displays';
import { Select } from '../../components/inputs';
import { endpoints } from '../../constants/Endpoints';
import useData from '../../hooks/useData';
import '../../styles/layouts.scss';
import { Admin } from '../../utilities/constants';
import {
  authUser,
  department as recoilDepart,
  exam as recoilExam,
} from '../../providers/index';
import { instance } from '../../services/https/inceptors';
import { showToast } from '../../utilities/toast';
import { useHistory } from 'react-router-dom';

const Content_view = () => {
  const [publish, setPublish] = useState();
  const [subjects, setSubjects] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [topics, setTopics] = useState([]);

  const [subject, setSubject] = useState('');
  const [chapter, setChapter] = useState('');
  const [topic, setTopic] = useState('');
  const [contents, setContents] = useState([]);
  const user = useRecoilValue(authUser);

  const { goBack } = useHistory();

  const [selected, setSelected] = useState([]);
  const data = useData();

  const department = useRecoilValue(recoilDepart).split('/')[1];
  const exam = useRecoilValue(recoilExam).split('/')[1];

  useEffect(() => {
    const fetchSubjects = async () => {
      const res = await data.getSubjectByDepartment(department);
      setSubjects(res);
    };
    fetchSubjects();
  }, [department]);

  const onSubjectChange = async (e) => {
    setSubject(e);
    const res = await data.getChaptersBySubject(e);
    setChapters(res);
  };

  const onChapterChange = async (e) => {
    setChapter(e);
    const res = await data.getTopicsByChapter(e);
    setTopics(res);
  };

  const display = async () => {
    let url = `${endpoints.getContentByFilter}?exam=${exam}&department=${department}`;

    if (subject) url += `&subject=${subject}`;
    if (chapter) url += `&chapter=${chapter}`;
    if (topic) url += `&topic=${topic}`;

    const res = await instance.get(url);
    setContents(res.data);
  };

  const exists = (content) => selected.some((value) => value === content);

  const onSelect = (content) => () => {
    if (exists(content))
      return setSelected((p) => p.filter((q) => q !== content));
    setSelected((previous) => [...previous, content]);
  };

  const bulkEdit = async (state) => {
    let flag = false;
    if (window.confirm('Are you sure you want do this operation?')) {
      flag = true;
    } else {
      return;
    }

    try {
      if (state === 'delete') {
        await instance.delete(endpoints.deleteBulkContent, {
          data: { objectIds: selected },
        });
        showToast({
          type: 'success',
          message: 'Successfully Deleted the Selected Content',
        });
      }
      if (state === 'publish') {
        await instance.put(endpoints.toggleBulkContentPublish, {
          objectIds: selected,
          publish: true,
        });
        showToast({
          type: 'success',
          message: 'Successfully Published the Selected Content',
        });
      }
      if (state === 'unpublish') {
        await instance.put(endpoints.toggleBulkContentPublish, {
          objectIds: selected,
          publish: false,
        });
        showToast({
          type: 'success',
          message: 'Successfully Unpublished the Selected Content',
        });
      }
      if (state === 'review') {
        await instance.put(endpoints.toggleBulkContentReview, {
          objectIds: selected,
          review: true,
        });
        showToast({
          type: 'success',
          message: 'Successfully Mark Reviewed the Selected Content',
        });
      }
      if (state === 'unreview') {
        await instance.put(endpoints.toggleBulkContentReview, {
          objectIds: selected,
          review: false,
        });
        showToast({
          type: 'success',
          message: 'Successfully Mark Unreviewed the Selected Content',
        });
      }

      setSelected([]);
      goBack();
    } catch (err) {
      showToast({
        type: 'error',
        message: err.response.data.message,
      });
    }
  };

  return (
    <div className='container'>
      <div className='d-flex align-items-center'>
        <div className='d-flex card-body w-100' style={{ gap: 30 }}>
          <div className='w-25'>
            <Select
              label='Subject'
              value={subject}
              onChange={onSubjectChange}
              vertical>
              {subjects.map((subject) => (
                <option key={subject.code} value={subject._id}>
                  {subject.title}
                </option>
              ))}
            </Select>
          </div>

          <div className='w-25'>
            <Select
              label='Chapter'
              value={chapter}
              onChange={onChapterChange}
              vertical>
              {chapters.map((chapter) => (
                <option key={chapter.code} value={chapter._id}>
                  {chapter.title}
                </option>
              ))}
            </Select>
          </div>

          <div className='w-25'>
            <Select label='Topic' value={topic} onChange={setTopic} vertical>
              {topics.map((topic) => (
                <option key={topic.code} value={topic._id}>
                  {topic.title}
                </option>
              ))}
            </Select>
          </div>

          {user?.role === Admin.MASTER && (
            <div className='w-25'>
              <Select
                label='Publish'
                value={publish}
                onChange={setPublish}
                vertical>
                <option value={true}>True</option>
                <option value={false}>False</option>
              </Select>
            </div>
          )}
        </div>
      </div>

      <div className='d-flex justify-content-end mb-5'>
        <Button color='success' width='25' onClick={display}>
          Go
        </Button>
      </div>
      <div className='d-flex justify-content-around mt-3'>
        <Button
          color='primary'
          className='btn-w'
          style={{ background: 'blue' }}
          onClick={() => bulkEdit('unpublish')}>
          Unpublish
        </Button>
        <Button
          color='primary'
          width='25'
          className='btn-w'
          style={{ background: 'blue' }}
          onClick={() => bulkEdit('publish')}>
          Publish
        </Button>

        <Button
          className='btn-w'
          width='25'
          style={{ background: 'gray' }}
          onClick={() => bulkEdit('review')}>
          Mark Reviewed
        </Button>

        <Button
          className='btn-w'
          width='25'
          style={{ background: 'gray' }}
          onClick={() => bulkEdit('unreview')}>
          Mark Unreviewed
        </Button>

        <Button
          color='danger'
          className='btn-w'
          width='25'
          style={{ background: 'red' }}
          onClick={() => bulkEdit('delete')}>
          Delete
        </Button>
      </div>
      <hr className='mb-4 mt-5' />

      {contents.map((content, index) => (
        <div
          key={index}
          className={classnames(
            'border rounded p-3',
            'question',
            exists(content._id) && 'selected',
            index !== contents.length - 1 && 'mb-3'
          )}
          onClick={onSelect(content._id)}>
          <Markup latex={content.title} className='mb-0' />
        </div>
      ))}
    </div>
  );
};

const Content_Bulk_viewPage = () => {
  return <Content_view />;
};
export default Content_Bulk_viewPage;
