import React, { useEffect, useState } from "react";
import { Submit } from "../../components/buttons";
import { Input, Select } from "../../components/inputs";
import { BASE, useFetch } from "../../utilities/apis";
import { showToast } from "../../utilities/toast";

const AddSubject = ({ exams }) => {
  const [exam, setExam] = useState("");
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState("");
  const { post, get } = useFetch();
  const [subject_code, setSubject_Code] = useState("");
  const [subject_title, setSubject_Title] = useState("");
  useEffect(() => {
    const url = BASE + `exam/?id=${exam}`;
    if (exam != "") {
      get(url, (error, result) => {
        if (error) return error;
        setDepartments(result.departments);
      });
    }
  }, [exam]);

  function collectData(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    let selectedDepartments = [];
    for (let i = 0; i <= departments.length; i++) {
      if (formData.get("checkbox" + i)) {
        selectedDepartments.push(formData.get("checkbox" + i));
      }
    }
    selectedDepartments = selectedDepartments.filter(function (
      item,
      pos,
      self
    ) {
      return self.indexOf(item) == pos;
    });
    const data = {
      departments: selectedDepartments,
      code: subject_code,
      title: subject_title,
      type: "subject",
    };

    try {
      post(BASE + "new", data, (error, _) => {
        if (error) return console.log(error);
      });
      setSubject_Code("");
      setSubject_Title("");
      showToast({ type: "success", message: "Successfully added Subject!" });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  }

  return (
    <>
      <div className="d-flex flex-row justify-content-around border mb-4 ">
        <div className="border p-3 w-100">
          <h6 className="mb-3">Add Subject</h6>

          <Select value={exam} onChange={setExam}>
            {exams.map((exam, index) => (
              <option key={index} value={exam._id}>
                {exam.title}
              </option>
            ))}
          </Select>

          <form onSubmit={collectData}>
            <label htmlFor="examCode">Departments </label>
            <div className="form-group border d-flex flex-wrap justify-content-center mb-3 flex-wrap gap-4">
              {departments?.map((department, index) => {
                return (
                  <div className="m-2" key={index}>
                    <div className="form-check">
                      <label
                        htmlFor={"checkbox" + (index + 1)}
                        className="form-check-label"
                      >
                        {department.title}
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={setDepartment}
                        value={department._id}
                        id={"checkbox" + (index + 1)}
                        name={"checkbox" + (index + 1)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="form-group">
              <label htmlFor="examCode">Subject Code</label>
              <Input
                id="examCode"
                value={subject_code}
                onChange={setSubject_Code}
                type={"text"}
              />
            </div>
            <div className="form-group">
              <label htmlFor="examTitle">Subject Title</label>
              <Input
                id="examTitle"
                value={subject_title}
                onChange={setSubject_Title}
                type={"text"}
              />
            </div>
            <div>
              <div className="col">
                <Submit
                  disabled={!subject_title || !subject_code || department == ""}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default AddSubject;
