import classnames from "classnames";
import PropTypes from "prop-types";
import React, { Children, useState } from "react";
import { BsCheck2All } from "react-icons/bs";
import { ImBlocked } from "react-icons/im";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { endpoints } from "../constants/Endpoints";
import { authUser } from "../providers/index";
import { instance } from "../services/https/inceptors";
import "../styles/layouts.scss";
import { showToast } from "../utilities/toast";
import { getLanguageName } from '../utilities/helpers';


const DisplaySection = ({ type, collectiveData, onChange, language }) => {
  const { push } = useHistory();
  const { department } = useParams();
  const user = useRecoilValue(authUser);

  const onDelete = async (Id) => {
    let url;
    if (type === "test") {
      url = endpoints.deleteTestSeries + `?id=${Id}`;
    } else {
      url = endpoints.deletePreviousPaper + `?id=${Id}`;
    }

    if (window.confirm("Are you sure you want to delete?")) {
      await instance.delete(url);
      onChange(collectiveData.filter((elem) => elem._id !== Id));
      showToast({
        type: "success",
        message: "Successfully Deleted!",
      });
    }
  };

  const publish_ = async (data) => {
    if (user.role !== "master" && data.publish) {
      showToast({
        type: "error",
        message: "Only master admin can unpublish the test series",
      });
      return;
    }

    let message = "";
    let url;
    if (type === "test") {
      url = endpoints.updateTestSeries;
    } else {
      url = endpoints.updatePreviousPaper;
    }

    if (data.publish) {
      message = "Do you want to Unpublish?";
    } else {
      message = "Do you want to Publish?";
    }

    if (window.confirm(message)) {
      const data_ = {
        _id: data._id,
        publish: !data.publish,
        publishedBy: user.userId,
      };

      try {
        const res = await instance.put(url, data_);
        const index = collectiveData.findIndex(
          (set) => res.data._id === set._id
        );
        const newSetClone = [...collectiveData];
        if (index > -1) {
          newSetClone[index] = res.data;
        }
        onChange(newSetClone);
        showToast({
          type: "success",
          message: "Successfully Updated!",
        });
      } catch (err) {
        showToast({
          type: "error",
          message: err?.response.data.message,
        });
      }
    }
  };

  const lock_ = async (data) => {
    let message = "";
    let url;

    if (type === "test") {
      url = endpoints.updateTestSeries;
    } else {
      url = endpoints.updatePreviousPaper;
    }

    if (data.lock) {
      message = "Do you want to Unlock?";
    } else {
      message = "Do you want to Lock it?";
    }

    if (window.confirm(message)) {
      const data_ = {
        _id: data._id,
        lock: !data.lock,
      };

      try {
        const res = await instance.put(url, data_);
        const index = collectiveData.findIndex(
          (set) => res.data._id === set._id
        );
        const newSetClone = [...collectiveData];
        if (index > -1) {
          newSetClone[index] = res.data;
        }
        onChange(newSetClone);
        showToast({
          type: "success",
          message: "Successfully Updated!",
        });
      } catch (err) {
        showToast({
          type: "error",
          message: err?.response.data.message,
        });
      }
    }
  };

  const displayPreviousPaper = async (data) => {
    let message = "";
    let url;
    if (type === "test") {
      url = endpoints.updateTestSeries;
    } else {
      url = endpoints.updatePreviousPaper;
    }

    if (data.display) {
      message = "Do you want to Hide?";
    } else {
      message = "Do you want to Display?";
    }
    if (window.confirm(message)) {
      const data_ = {
        _id: data._id,
        display: !data.display,
      };
      try {
        const res = await instance.put(url, data_);
        const index = collectiveData.findIndex(
          (set) => res.data._id === set._id
        );
        const newSetClone = [...collectiveData];
        if (index > -1) {
          newSetClone[index] = res.data;
        }
        onChange(newSetClone);
        showToast({
          type: "success",
          message: "Successfully Updated!",
        });
      } catch (err) {
        showToast({
          type: "error",
          message: err?.response.data.message,
        });
      }
    }
  };

  return (
    <div>
      {collectiveData.map((data) => (
      <div key={data._id} className="previous-years-actions mt-5">
        <div className={classnames('relative py-2 px-3 !rounded-sm', {
            'tag-publish': data.publish, 
            'tag': !data.publish,
            '!bg-red-100': !data?.name?.[language.value],
          })}>
            
            <h6>{data?.name?.[language?.value] ?? `Paper not available in ${language?.label}`}</h6>
            <div className='flex justify-start gap-x-1 absolute -bottom-7 left-0'>
              {Object.keys(data.name).map((lang) =>
                data.name?.[lang].length ? (
                  <div className='capitalize bg-slate-400 text-white px-[6px] py-1 rounded-sm text-[10px]'>
                    {getLanguageName(lang)}
                  </div>
                ) : null
              )}
          </div>
          </div>

          <div className="actions-on-right">
            <div>
              <i
                className="bi bi-eye-fill"
                style={{ fontSize: "2rem", cursor: "pointer" }}
                onClick={() => {
                  if (type === "previousYear") {
                    push(`/edit-previous-year-paper/${data._id}/${department}`);
                  } else if (type === "test") {
                    push(`/edit-testSeries/${data._id}/${department}`);
                  }
                }}
              ></i>
            </div>
            {!data.publish && (
              <div>
                <i
                  className="bi bi-trash cursor-pointer "
                  style={{ fontSize: "2rem", cursor: "pointer" }}
                  onClick={() => onDelete(data._id)}
                ></i>
              </div>
            )}

            {user.role === "master" && (
              <div className="publish-unpublish">
                <div onClick={() => publish_(data)}>
                  {data.publish === false ? <BsCheck2All /> : <ImBlocked />}
                </div>
              </div>
            )}

            <div>
              <div onClick={() => lock_(data)}>
                {data.lock === false ? (
                  <i
                    className="bi bi-unlock-fill"
                    style={{ color: `rgb(2, 79, 16)`, fontSize: "2rem" }}
                  ></i>
                ) : (
                  <i
                    className="bi bi-lock-fill"
                    style={{ color: `rgb(2, 79, 16)`, fontSize: "2rem" }}
                  ></i>
                )}
              </div>
            </div>

            <div onClick={() => displayPreviousPaper(data)}>
              {data.display ? (
                <div className="hide">Hide</div>
              ) : (
                <div className="show">Show</div>
              )}
            </div>

            {type === "test" ? (
              <div
                style={{
                  width: "8rem",
                  background: "#c6d7c9",
                  textAlign: "center",
                  padding: "6px 2px",
                  borderRadius: "4px",
                  color: "#024f10",
                  fontSize: "14px",
                }}
              >
                {data.releaseDate
                  ? new Date(data.releaseDate).toString().slice(0, 15)
                  : "N/A"}
              </div>
            ) : null}       
          </div>
        </div>
        
      ))}
    </div>
  );
};

const TabButton = ({ id, target, selected, children }) => (
  <li className="nav-item">
    <button
      type="button"
      className={classnames("nav-link", selected && "active")}
      role="tab"
      id={id}
      data-bs-toggle="tab"
      data-bs-target={`#${target}`}
      aria-controls={target}
      aria-selected={selected}
    >
      {children}
    </button>
  </li>
);

TabButton.propTypes = {
  id: PropTypes.string,
  target: PropTypes.string,
  selected: PropTypes.bool,
  children: PropTypes.node,
};

/**
 * Tab Pane
 */

const TabPane = ({ id, label, selected, children }) => (
  <div
    className={classnames("tab-pane", selected && "active")}
    role="tabpanel"
    id={id}
    aria-labelledby={label}
  >
    {children}
  </div>
);

TabPane.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  selected: PropTypes.bool,
  children: PropTypes.node,
};

/**
 * Tabs Layout
 */

const Tabs = ({ children }) => (
  <div className="container py-5 h-100 d-flex flex-column">
    <ul className="nav nav-tabs" id="tabs" role="tablist">
      {Children.map(children, (child) => {
        if (child.type.name === TabButton.name) return child;
      })}
    </ul>

    <div className="tab-content h-100 overflow-auto">
      {Children.map(children, (child) => {
        if (child.type.name === TabPane.name) return child;
      })}
    </div>
  </div>
);

Tabs.propTypes = {
  children: PropTypes.node,
};

Tabs.Button = TabButton;
Tabs.Pane = TabPane;

const Thumb = ({ filename, removable, onDelete }) => {
  if (!filename) return null;

  // const { remove } = useFetch();

  return (
    <div className="thumb">
      <img src={filename} alt="" />

      {removable ? (
        <button type="button" onClick={onDelete}>
          X
        </button>
      ) : null}
    </div>
  );
};

const Profile = (children, ...props) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center position-absolute p-2 fw-bold "
      style={{
        top: "0.4em",
        right: "6em",
        zIndex: "3",
        height: "40px",
        width: "40px",
        background: `rgb(2, 79, 16)`,
        color: "white",
        borderRadius: "50%",
        cursor: "pointer",
      }}
    >
      {children}
    </div>
  );
};
Profile.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export { Tabs, Thumb, Profile, DisplaySection };
