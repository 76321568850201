import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Actions from "../../components/Action";
import { Button } from "../../components/buttons";
import EmptyIcon from "../../components/EmptyIcon/index";
import { Select } from "../../components/inputs";
import { LANGUAGES, MODULES } from "../../constants/Common";
import { endpoints } from "../../constants/Endpoints";
import useData from "../../hooks/useData";
import { instance } from "../../services/https/inceptors";
import ReactSelect from 'react-select';
import { useRecoilState, useRecoilValue } from 'recoil';
import { selectedDepartmentObject, selectedLanguage } from '../../providers';

const Dpp = () => {
  const { push } = useHistory();
  const [subjects, setSubjects] = useState([]);
  const [subject, setSubject] = useState();
  const { department, exam } = useParams();
  const [dpps, setDpp] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [chapter, setChapter] = useState();
  const [topics, setTopics] = useState([]);
  const [topic, setTopic] = useState();
  const selectedDepart = useRecoilValue(selectedDepartmentObject);
  const [currentLanguage, setCurrentLanguage] = useRecoilState(selectedLanguage);


  const { getSubjectByDepartment, getChaptersBySubject, getTopicsByChapter } =
    useData();

  const getAllDpp = async () => {
    const res = await instance.get(
      `${endpoints.getAllDpp}?exam=${exam}&department=${department}`
    );
    setDpp(res.data);
  };

  const getSubjects = async () => {
    const res = await getSubjectByDepartment(department);
    setSubjects(res);
  };

  const onSearch = async () => {
    const res = await instance.get(
      `${endpoints.getAllDpp}?topic=${topic}&exam=${exam}&department=${department}`
    );
    setDpp(res.data);
  };

  const getChapters = async (e) => {
    setSubject(e);
    const res = await getChaptersBySubject(e);
    setChapters(res);
  };

  const getTopics = async (e) => {
    setChapter(e);
    const res = await getTopicsByChapter(e);
    setTopics(res);
  };

  useEffect(() => {
    getAllDpp();
    getSubjects();
  }, []);

  return (
    <>
      <div className="container mb-5">
        <h2 className="text-center mb-3 space-heading">
          Daily Practice Problems (DPP)
        </h2>
        <div className="mx-5 border shadow rounded pt-5 px-5">
          <div className="d-flex justify-content-center">
            <Button
              color="success"
              width="25"
              onClick={() => push(`/create-dpp/${department}/${exam}`)}
            >
              Create new DPP
            </Button>
          </div>

          <div className="d-flex flex-row align-items-center py-5">
            <div className="d-flex gap-3 w-80">
              <Select onChange={getChapters} value={subject} label="Subject">
                {subjects?.map((item, index) => (
                  <option key={index} value={item._id}>
                    {item.title}
                  </option>
                ))}
              </Select>

              <Select value={chapter} onChange={getTopics} label="Chapter">
                {chapters?.map((item, index) => (
                  <option value={item._id} key={index}>
                    {item.title}
                  </option>
                ))}
              </Select>

              <Select value={topic} onChange={setTopic} label="Topic">
                {topics?.map((item, index) => (
                  <option value={item._id} key={index}>
                    {item.title}
                  </option>
                ))}
              </Select>

              <div className='mt-1'>
                <h6 className='mb-1 font-semibold'>Select Language</h6>
                <ReactSelect
                  onChange={setCurrentLanguage}
                  options={selectedDepart?.languages?.map((lang) => {
                    const label = Object.keys(LANGUAGES).find(
                      (key) => LANGUAGES[key] === lang
                    );
                    return {
                      value: lang,
                      label: label.replace(/^./, label[0].toUpperCase()),
                    };
                  })}
                  value={currentLanguage}
                  placeholder='Select Language'
                  className='w-52'
                />
              </div>
            </div>
            <div className="w-20">
              <Button
                disabled={!topic}
                onClick={onSearch}
                color="success"
                width="100"
                className="mt-3"
              >
                Search
              </Button>
            </div>
          </div>
        </div>

        <div className="mt-5 m-auto w-50">
          {dpps?.length ? (
            <Actions
              language={currentLanguage}
              type={MODULES.DPP}
              data={dpps}
              setData={setDpp} />
          ) : (
            <EmptyIcon />
          )}
        </div>
      </div>
    </>
  );
};
const dppPage = () => {
  return <Dpp />;
};
export default dppPage;
