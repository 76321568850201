import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "../../components/buttons";
import { Select } from "../../components/inputs";
import useData from "../../hooks/useData";
import AddChapterModal from "./Modals/AddChapterModal";
import "./style.scss";
import { LANGUAGES } from '../../constants/Common';

import { BsFillEyeFill } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import { instance } from "../../services/https/inceptors";
import { endpoints } from "../../constants/Endpoints";
import { showToast } from "../../utilities/toast";
import AddWorkBookModal from "./Modals/AddWorkbookModal";
import AddTestModal from "./Modals/AddTestModal";
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { courseSelectedSubject, selectedDepartmentObject, selectedLanguage } from "../../providers/index";
import ReactSelect from 'react-select';
import classNames from 'classnames';

const Courses = () => {
  const [allSubjects, setAllSubjects] = useState([]);
  const [showAddChapterModal, setShowAddChapterModal] = useState(false);
  const [showAddWorkBookModal, setShowAddWorkBookModal] = useState(false);
  const [showAddTestModal, setShowAddTestModal] = useState(false);
  const selectedDepart = useRecoilValue(selectedDepartmentObject);
  const [language, setSelectedLanguage] = useRecoilState(selectedLanguage);

  const [subject, setSubject] = useRecoilState(courseSelectedSubject);
  const [course, setCourse] = useState([]);

  const { department, exam } = useParams();
  const { getSubjectByDepartment, getCourseBySubject } = useData();

  const getSubjects = async () => {
    const res = await getSubjectByDepartment(department);
    setAllSubjects(res);
  };

  const getCourse = async () => {
    const res = await getCourseBySubject(subject);
    setCourse(res ? res : {});
  };

  const deleteChapter = async (chap) => {
    try {
      await instance.delete(
        `${endpoints.deleteCourseChapter}?chapterId=${chap._id}&subjectId=${subject}`
      );

      const courseClone = JSON.parse(JSON.stringify(course));
      courseClone.chapters = courseClone.chapters.filter(
        (chapter) => chapter._id !== chap._id
      );

      setCourse(courseClone);
      showToast({ type: "success", message: "Successfully deleted chapter" });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  const deleteWorkBook = async (wb) => {
    try {
      await instance.delete(
        `${endpoints.deleteCourseWorkBook}?workBookId=${wb._id}&subjectId=${subject}`
      );

      const courseClone = JSON.parse(JSON.stringify(course));
      courseClone.workBooks = courseClone.workBooks.filter(
        (workBook) => workBook._id !== wb._id
      );

      setCourse(courseClone);
      showToast({ type: "success", message: "Successfully deleted workbook!" });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  const deleteTest = async (clickedTest) => {
    try {
      await instance.delete(
        `${endpoints.deleteCourseTest}?testId=${clickedTest._id}&subjectId=${subject}`
      );

      const courseClone = JSON.parse(JSON.stringify(course));
      courseClone.tests = courseClone.tests.filter(
        (test) => test._id !== clickedTest._id
      );

      setCourse(courseClone);
      showToast({ type: "success", message: "Successfully deleted Test!" });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  useEffect(() => {
    if (department) getSubjects();
  }, []);

  useEffect(() => {
    if (subject) getCourse();
  }, [subject]);

  return (
    <div className="container mb-5">
      <h2 className="text-center mb-3 space-heading">Courses</h2>
      <div className="mx-3 accent-body mt-3 d-flex flex-row justify-content-between align-items-center py-3 shadow rounded mt-3 px-4">
        <div className="d-flex gap-3 w-60">
          <div style={{ width: "35rem" }}>
            <Select
              onChange={(e) => setSubject(e)}
              value={subject}
              label="Subject"
            >
              {allSubjects.map((item, index) => (
                <option key={index} value={item._id}>
                  {item.title}
                </option>
              ))}
            </Select>
          </div>

          <div className='mt-1'>
            <h6 className='mb-1'>Select Language</h6>
            <ReactSelect
              onChange={setSelectedLanguage}
              options={selectedDepart?.languages?.map((lang) => {
                const label = Object.keys(LANGUAGES).find(
                  (key) => LANGUAGES[key] === lang
                );
                return {
                  value: lang,
                  label: label.replace(/^./, label[0].toUpperCase()),
                };
              })}
              value={language}
              placeholder='Select Language'
              className='w-52'
            />
          </div>
        </div>
      </div>

      {subject ? (
        <div>
          <div>
            <h5 className="text-center text-uppercase mt-5 section-heading">
              Chapters
            </h5>
            <div className="d-flex mx-3 accent-body mt-4">
              <div className="all-chapters w-50">
                {course?.chapters?.length ? (
                  <div className="mt-4">
                    {course?.chapters?.map((chap) => (
                      <div className="chapters-list">
                        <div className={classNames('chapter-title', {
                          '!bg-red-200': !chap?.title?.[language.value]
                        })}>
                          {chap?.title?.[language.value] ?? `Not available in ${language.label}`}
                        </div>
                        <div className="actions">
                          <Link
                            to={chap?.title?.[language.value] ? `/course/chapter/${chap?._id}/${subject}/${chap.title?.[language.value]}` : '#'}
                          >
                            <BsFillEyeFill size={26} />
                          </Link>
                          <div>
                            <AiFillDelete
                              onClick={() => deleteChapter(chap)}
                              size={26}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center mt-4"> No Chapters Added!</div>
                )}
              </div>

              <div className="w-50 d-flex flex-column align-items-center justify-content-center gap-4">
                <Button
                  onClick={() => setShowAddChapterModal(true)}
                  color="success"
                  width="30"
                  className="search-assignment"
                >
                  Add Chapters
                </Button>
              </div>
            </div>
          </div>

          <div>
            <h5 className="text-center text-uppercase mt-5 section-heading">
              Workbooks
            </h5>
            <div className="d-flex mx-3 accent-body mt-4">
              <div className="all-chapters w-50">
                {course?.workBooks?.length ? (
                  <div className="mt-4">
                    {course?.workBooks?.map((wb) => (
                      <div className="chapters-list">
                        <div className="chapter-title">{wb?.name?.[language.value] ?? `Not available in ${language.label}`}</div>
                        <div className="actions">
                          <Link to={`/edit-workbook/${wb._id}/${exam}`}>
                            <BsFillEyeFill size={26} />
                          </Link>
                          <div>
                            <AiFillDelete
                              onClick={() => deleteWorkBook(wb)}
                              size={26}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center mt-4"> No Workbook Added!</div>
                )}
              </div>

              <div className="w-50 d-flex flex-column align-items-center justify-content-center gap-4">
                <Button
                  onClick={() => setShowAddWorkBookModal(true)}
                  color="success"
                  width="30"
                  className="search-assignment"
                >
                  Add Workbooks
                </Button>
              </div>
            </div>
          </div>

          <div>
            <h5 className="text-center text-uppercase mt-5 section-heading">
              Tests
            </h5>
            <div className="d-flex mx-3 accent-body mt-4">
              <div className="all-chapters w-50">
                {course?.tests?.length ? (
                  <div className="mt-4">
                    {course?.tests?.map((test) => (
                      <div className="chapters-list">
                        <div className="chapter-title">{test?.name?.[language.value] ?? `Not available in ${language.label}`}</div>
                        <div className="actions">
                          <Link to={`/edit-workbook/${test._id}/${exam}`}>
                            <BsFillEyeFill size={26} />
                          </Link>
                          <div>
                            <AiFillDelete
                              onClick={() => deleteTest(test)}
                              size={26}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center mt-4"> No Tests Added!</div>
                )}
              </div>

              <div className="w-50 d-flex flex-column align-items-center justify-content-center gap-4">
                <Button
                  onClick={() => setShowAddTestModal(true)}
                  color="success"
                  width="30"
                  className="search-assignment"
                >
                  Add Test
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center mt-4"> No Chapters Added!</div>
      )}

      {showAddChapterModal && subject && (
        <AddChapterModal
          language={language.value}
          setCourse={setCourse}
          course={course}
          subject={subject}
          closeModal={() => setShowAddChapterModal(false)}
        />
      )}

      {showAddWorkBookModal && subject && (
        <AddWorkBookModal
          setCourse={setCourse}
          course={course}
          subject={subject}
          closeModal={() => setShowAddWorkBookModal(false)}
        />
      )}

      {showAddTestModal && subject && (
        <AddTestModal
          setCourse={setCourse}
          course={course}
          subject={subject}
          closeModal={() => setShowAddTestModal(false)}
        />
      )}
    </div>
  );
};

export default Courses;
