import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ReactSelect from "react-select";
import { useRecoilState, useRecoilValue } from "recoil";
import AvailableContentLanguage from "../../components/AvailableContentLanguage";
import { Button } from "../../components/buttons";
import { Markup } from "../../components/displays";
import { Select } from "../../components/inputs";
import { LANGUAGES } from "../../constants/Common";
import { endpoints } from "../../constants/Endpoints";
import useData from "../../hooks/useData";
import {
  authUser,
  contentState,
  exam as recoilExam,
  selectedDepartmentObject,
  selectedLanguage,
} from "../../providers/index";
import { instance } from "../../services/https/inceptors";
import "../../styles/layouts.scss";
import { Admin } from "../../utilities/constants";

const Content = () => {
  const { push } = useHistory();
  const { department } = useParams();

  const [subjects, setSubjects] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [topics, setTopics] = useState([]);
  const [subject, setSubject] = useState("");
  const [chapter, setChapter] = useState("");
  const [topic, setTopic] = useState("");
  const [publish, setPublish] = useState();

  const [contents, setContents] = useRecoilState(contentState);
  const { role } = useRecoilValue(authUser);
  const exam = useRecoilValue(recoilExam);
  const [language, setLanguage] = useRecoilState(selectedLanguage);
  const selectedDepart = useRecoilValue(selectedDepartmentObject);
  const [languageValueOnSubmit, setLanguageValueOnSubmit] = useState({
    value: "en",
    label: "English",
  });

  const data = useData();

  useEffect(() => {
    const fetchSubjects = async () => {
      const res = await data.getSubjectByDepartment(department);
      setSubjects(res);
    };
    fetchSubjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [department]);

  const display = async () => {
    let url = `?exam=${exam.split("/")[1]}&department=${department}&language=${
      language.value
    }`;
    if (subject) url += `&subject=${subject}`;
    if (chapter) url += `&chapter=${chapter}`;
    if (topic) url += `&topic=${topic}`;
    if (publish && publish !== undefined) url += `&publish=${publish}`;

    const res = await instance.get(endpoints.getContentByFilter + url);
    setLanguageValueOnSubmit(language);
    setContents(res.data);
  };

  const onSubjectChange = async (e) => {
    setTopic("");
    setChapter("");
    setSubject(e);
    if (e) {
      const res = await data.getChaptersBySubject(e);
      setChapters(res);
    }
  };

  const onChapterChange = async (e) => {
    setChapter(e);
    if (e) {
      const res = await data.getTopicsByChapter(e);
      setTopics(res);
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="space-heading text-center mb-4">Search Content</h2>
      <div className="d-flex w-100 flex-wrap gap-x-6 m-auto">
        <div className="!w-68">
          <Select
            label="Subject"
            value={subject}
            onChange={onSubjectChange}
            vertical
          >
            {subjects.map((subject) => (
              <option key={subject.code} value={subject._id}>
                {subject.title}
              </option>
            ))}
          </Select>
        </div>

        <div className="!w-72">
          <Select
            label="Chapter"
            value={chapter}
            onChange={onChapterChange}
            vertical
          >
            {chapters.map((chapter) => (
              <option key={chapter.code} value={chapter._id}>
                {chapter.title}
              </option>
            ))}
          </Select>
        </div>
        <div className="!w-72">
          <Select label="Topic" value={topic} onChange={setTopic} vertical>
            {topics.map((topic) => (
              <option key={topic.code} value={topic._id}>
                {topic.title}
              </option>
            ))}
          </Select>
        </div>
        {role !== Admin.SENIOR && role !== Admin.SUPPORT && (
          <div className="!w-48">
            <Select
              label="Publish"
              value={publish}
              onChange={setPublish}
              vertical
            >
              <option value={true}>True</option>
              <option value={false}>False</option>
            </Select>
          </div>
        )}

        <div className="!w-52 mt-1">
          <h6 className="mb-1">Select Content Language</h6>
          <ReactSelect
            onChange={(e) => setLanguage(e)}
            options={selectedDepart?.languages?.map((lang) => {
              const label = Object.keys(LANGUAGES).find(
                (key) => LANGUAGES[key] === lang
              );
              return {
                value: lang,
                label: label.replace(/^./, label[0].toUpperCase()),
              };
            })}
            value={language}
            placeholder="Select Language"
            className="!w-76"
          />
        </div>
      </div>
      <div className="d-flex justify-content-end mt-3 gap-3">
        {role === Admin.MASTER && (
          <Button
            color="warning"
            width="10"
            style={{ background: "yellow" }}
            onClick={() => push(`/content-bulk-edit`)}
          >
            Bulk Edit
          </Button>
        )}
        <Button color="success" width="10" onClick={display}>
          Go
        </Button>
      </div>
      <hr className="mb-4 mt-4" />

      {contents.map((content, index) => (
        <div
          key={index}
          className={classnames(
            "border rounded p-3",
            index !== contents.length - 1 && "mb-3"
          )}
        >
          <div>
            <AvailableContentLanguage
              setCurrentLanguage={setLanguage}
              allLanguageContent={content.contents[0]?.title}
              noSelect
            />
          </div>

          <Markup
            latex={content.contents[0]?.title[languageValueOnSubmit.value]}
            className="mb-0"
          />

          <div className="d-flex justify-content-end">
            <Button
              className="mt-3"
              color="success"
              width="10"
              onClick={() => push(`/view-content/${content._id}`)}
            >
              View
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Content;
