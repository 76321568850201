import classNames from "classnames";

export const SelectQuestionModal = ({
  show,
  size,
  title,
  onClose,
  onDone,
  children,
  footerVisible,
}) => (
  <div className={classNames("backdrop fade", show && "show")}>
    <div
      className={classNames(
        "modal-dialog modal-dialog-scrollable",
        size && `modal-${size}`
      )}
    >
      <div className="modal-content">
        <div className="modal-header">
          {title && <h5 className="text-2xl font-semibold">{title}</h5>}

          <button type="button" className="btn-close" onClick={onClose} />
        </div>

        <div className="modal-body">{children}</div>

        {footerVisible && (
          <div className="modal-footer">
            <button type="button" className="px-3 py-2 rounded-md bg-green-700 text-white" onClick={onDone}>
              Proceed
            </button>
          </div>
        )}
      </div>
    </div>
  </div>
);
