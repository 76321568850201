export const LOCAL_CONSTANT = {
  SET2_SCORE_USER: "set-2-score-user",
  SELECTED_DEPARTMENT: "selected_department",
  ALL_EXAM_DEPARTMENTS: "all_exam_departments",
  ALL_EXAMS: "all-exams",
  SELECTED_EXAM: "selected_exam",
  GLOBAL_SELECTED_EXAM_LABEL: "global_selected_exam_label",
  GLOBAL_SELECTED_DEPART_LABEL: "global_selected_depart_label",
  SELECTED_DEPARTMENT_OBJECT: "selected_department_object",
  SELECTED_LANGUAGE: "selected_language",
  QUESTION_FILTER_LANGUAGE: "question_filter_language",
};

export const ANSWER_MODES = [
  { value: "mcq", label: "MCQs" },
  { value: "msq", label: "MSQs" },
  { value: "answer", label: "Answers" },
  { value: "subjective", label: "Subjectives" },
];
