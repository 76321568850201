import { useEffect, useState } from "react";
import { BsFillTrashFill } from "react-icons/bs";
import { Input, Select } from "../inputs";
import { Selector } from "../Selector";

export const Section = ({
  index,
  setToRenderSection,
  section,
  deleteSection,
  language,
  allQuestions,
}) => {
  const [questions, setQuestions] = useState([]);
  const [compulsory, setCompulsory] = useState(null);
  const [name, setName] = useState("");
  const [tackle, setTackle] = useState(0);

  useEffect(() => {
    setQuestions(section?.questions ?? []);
    setCompulsory(section?.compulsory);
    setName(section?.name);
    setTackle(section?.tackle ?? 0);
  }, [section]);

  const changeSectionName = (e) => {
    console.log(e.target.value);
    setToRenderSection((prev) => {
      prev.map((sec) => {
        if (sec.sectionId === section.sectionId) {
          sec.name = e.target.value;
        }
      });
      return prev;
    });
    setName(e.target.value);
  };

  const changeCompulsoryQuestion = (e) => {
    setToRenderSection((prev) => {
      prev.map((sec) => {
        if (sec.sectionId === section.sectionId) {
          sec.compulsory = e.target.value;
        }
      });
      return prev;
    });
    setCompulsory(e.target.value);
  };

  const selectQuestions = (selected) => {
    setToRenderSection((prev) => {
      const clone = JSON.parse(JSON.stringify(prev));
      clone.map((sec) => {
        if (sec.sectionId === section.sectionId) {
          sec.questions.push(...selected);
        }
      });
      return clone;
    });

    setQuestions((previous) => [...previous, ...selected]);
  };

  const deleteQuestion = (ques) => {
    setToRenderSection((prev) => {
      prev.map((sec) => {
        if (sec.sectionId === section.sectionId) {
          sec.questions = ques;
        }
      });
      return prev;
    });
    setQuestions(ques);
  };

  const changeTackle = (e) => {
    setToRenderSection((prev) => {
      prev.map((sec) => {
        if (sec.sectionId === section.sectionId) {
          sec.tackle = e.target.value;
        }
      });
      return prev;
    });
    setTackle(e.target.value);
  };

  const onQuestionUpDown = (questions) => {
    setToRenderSection((prev) => {
      prev.map((sec) => {
        if (sec.sectionId === section.sectionId) {
          sec.questions = questions;
        }
      });
      return prev;
    });
    setQuestions(questions);
  };

  return (
    <div>
      <div className="mb-4 mt-5 px-5 gap-4 d-flex align-items-center justify-content-center">
        <div className=" mt-3" onClick={() => deleteSection(section)}>
          <BsFillTrashFill
            style={{
              cursor: "pointer",
              fill: "red",
              width: "35px",
              height: "35px",
              padding: 8,
            }}
          />
        </div>

        <div>
          <Input
            returnEvent
            id={"name" + index}
            name={"sectionName" + index}
            label="Name"
            type="text"
            value={typeof name === "string" ? name : section?.name}
            onChange={changeSectionName}
          />
        </div>
        <div>
          <div className="mb-2">Compulsory Question</div>
          <Select
            key={section?.sectionId}
            returnEvent
            value={compulsory}
            onChange={changeCompulsoryQuestion}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </Select>
        </div>

        <div>
          <Input
            returnEvent
            id={"tackle" + index}
            name={"tackle" + index}
            label="Questions to do"
            type="number"
            value={tackle}
            onChange={changeTackle}
          />
        </div>
      </div>

      <Selector
        allQuestions={allQuestions}
        language={language}
        onQuestionUpDown={onQuestionUpDown}
        returnEvent
        questions={questions}
        onChange={selectQuestions}
        deleteQuestion={deleteQuestion}
      />
    </div>
  );
};
