import { Markup } from "../../components/displays";
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import { Button } from "../buttons";
import "./style.scss";
import { choiceLabel, getLanguageName } from "../../utilities/helpers";

const QuestionBox = ({ question, index, length, language }) => {
  const { push } = useHistory();

  return (
    <div
      key={index}
      style={{ width: "100%" }}
      className={classnames(
        "border !border-green-600 rounded p-3 relative",
        index !== length - 1 && "mb-3"
      )}
    >
      <Markup latex={question.text[language]} className="mb-5" />

      <div className="row view-questions-data">
        <div className="col">
          <div className="font-semibold"> Subject:</div>
          <div> {question?.subject?.title}</div>
        </div>
        <div className="col question-box-answer">
          <div className="font-semibold">Answer:</div>
          {question.choices && question?.choices?.length > 0 ? (
            <div className="mt-1">
              {question.choices.map((choice, index) => {
                return (
                  <div className="flex items-center justify-center gap-x-2 my-3">
                    <div>{choiceLabel(index)}</div>
                    <Markup
                      latex={
                        choice.text[language]?.length
                          ? choice.text[language]
                          : "N/A"
                      }
                      className={classnames("mb-0", {
                        "font-extrabold bg-slate-200 p-3 rounded-md": choice.answer,
                      })}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <Markup
              latex={
                question.answer[language]?.length
                  ? question.answer[language]
                  : "N/A"
              }
              className="mb-0"
            />
          )}
        </div>

        <div className="col">
          <div className="font-semibold">Marks: </div>+
          {question.markingRule?.positive}/-
          {question.markingRule?.negative}
        </div>

        <div className="col">
          <div className="font-semibold">Set:</div>
          {question?.set ? question.set : "N/A"}
        </div>

        <div className="col">
          <div className="font-semibold"> Mode:</div>
          <div style={{ textTransform: "uppercase" }}>{question?.mode}</div>
        </div>

        <div className="col">
          <div className="font-semibold"> Available in Languages:</div>

          <div className="flex justify-center gap-x-1 mt-1">
            {Object.keys(question.text).map((lang) =>
              question.text[lang].length ? (
                <div className="capitalize bg-gray-400 text-white px-2 py-1 rounded-sm text-xs">
                  {getLanguageName(lang)}
                </div>
              ) : null
            )}
          </div>
        </div>

        <div className="col">
          {question.choices.map((ch) => (
            <div>{ch?.en}</div>
          ))}
        </div>
      </div>

      <Button
        onClick={() =>
          push(`/view/question/${question._id}`, { questionId: question._id })
        }
        className="view-question-button"
        color="success"
        width="20"
      >
        View
      </Button>
    </div>
  );
};

export default QuestionBox;
