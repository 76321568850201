import { LANGUAGES } from '../constants/Common';
import { Type } from './constants';

function getYears() {
  const years = [];
  const endYear = new Date().getFullYear();

  var year = endYear;

  while (year >= 1990) {
    years.push(Number(year));
    year--;
  }

  return years;
}

const years = getYears();

function choiceLabel(index) {
  switch (index) {
    case 0:
      return 'A.';
    case 1:
      return 'B.';
    case 2:
      return 'C.';
    case 3:
      return 'D.';
    case 4:
      return 'E.';
    case 5:
      return 'F.';
    case 6:
      return 'G.';
    case 7:
      return 'H.';
    default:
      throw new Error('Invalid Choice Index');
  }
}

function questionType(year) {
  switch (year) {
    case Type.SERIES:
      return 'Type: Test Series';
    case Type.QUIZ:
      return 'Type: Quiz';
    default:
      return `Year: ${year}`;
  }
}

const getLanguageName = (value) => {
  return Object.keys(LANGUAGES).find((key) => LANGUAGES[key] === value);
};

export { years, choiceLabel, questionType, getLanguageName };
