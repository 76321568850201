import ReactDatePicker from 'react-datepicker';
import { BsFillCalendar2Fill } from 'react-icons/bs';

const DatePicker = ({ label, date, onChange, className }) => {
  return (
    <div className={className}>
      <div className='mb-2 font-semibold'>{label}</div>
      <div className='flex items-center gap-x-3'>
        <div>
          <ReactDatePicker
            customInput={
              <BsFillCalendar2Fill
                className='cursor-pointer fill-green-700'
                size={26}
              />
            }
            selected={date}
            minDate={Date.now()}
            onChange={(date) => onChange(date)}
          />
        </div>

        <div className='cursor-not-allowed bg-slate-300 rounded-sm py-1 px-3 '>
          {date?.toString().length
            ? date?.toString()?.slice(0, 15)
            : 'Date not selected'}
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
