import React, { useState } from "react";
import { Submit } from "../../components/buttons";
import { Input } from "../../components/inputs";
import { BASE, useFetch } from "../../utilities/apis";
import { showToast } from "../../utilities/toast";

const AddDepartment = ({ exams }) => {
  const [exam, setExam] = useState("");
  const { post } = useFetch();
  const [department_code, setDepartment_Code] = useState("");
  const [department_title, setDepartment_Title] = useState("");

  function collectData(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    let selectedExam = [];
    for (let i = 0; i <= exams.length; i++) {
      if (formData.get("checkbox" + i)) {
        selectedExam.push(formData.get("checkbox" + i));
      }
    }
    selectedExam = selectedExam.filter(function (item, pos, self) {
      return self.indexOf(item) === pos;
    });
    const data = {
      exams: selectedExam,
      code: department_code,
      title: department_title,
      type: "department",
    };

    try {
      post(BASE + "new", data, (error, _) => {
        if (error) return console.log(error);
      });
      setDepartment_Code("");
      setDepartment_Title("");
      showToast({ type: "success", message: "Successfully added department!" });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  }

  return (
    <>
      <div className="d-flex flex-row justify-content-around mb-4 ">
        <div className="border p-3 w-100">
          <h6>Add Department</h6>

          <form onSubmit={collectData}>
            <label htmlFor="examCode">Exam</label>
            <div className="form-group  d-flex justify-content-center mb-3 flex-wrap gap-5">
              {exams.map((exam, index) => {
                return (
                  <div className="test-series-subject-input" key={index}>
                    <div className="form-check">
                      <label
                        htmlFor={"checkbox" + (index + 1)}
                        className="form-check-label"
                      >
                        {exam.title}
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={setExam}
                        value={exam._id}
                        id={"checkbox" + (index + 1)}
                        name={"checkbox" + (index + 1)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="form-group">
              <label htmlFor="examCode">Department Code</label>
              <Input
                id="examCode"
                value={department_code}
                onChange={setDepartment_Code}
                type={"text"}
              />
            </div>
            <div className="form-group">
              <label htmlFor="examTitle">Department Title</label>
              <Input
                id="examTitle"
                value={department_title}
                onChange={setDepartment_Title}
                type={"text"}
              />
            </div>
            <div>
              <div className="col">
                <Submit
                  disabled={
                    !department_title || !department_code || exam === ""
                  }
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddDepartment;
