import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '../../components/buttons';
import EmptyIcon from '../../components/EmptyIcon/index';
import { Input, Select } from '../../components/inputs';
import { DisplaySection } from '../../components/layouts';
import { LANGUAGES, MODES } from '../../constants/Common';
import { endpoints } from '../../constants/Endpoints';
import { instance } from '../../services/https/inceptors';
import ReactSelect from 'react-select';
import { useRecoilState } from 'recoil';
import { selectedDepartmentObject, selectedLanguage } from '../../providers';
import classNames from 'classnames';
import { showToast } from '../../utilities/toast';

const Previous = () => {
  const { push } = useHistory();
  const [to, setTo] = useState();
  const [from, setFrom] = useState();
  const { department, exam } = useParams();
  const [subjects, setSubjects] = useState([]);
  const [mode, setMode] = useState(MODES.FULL_LENGTH);
  const [previousYears, setPreviousYears] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [currentLanguage, setCurrentLanguage] = useRecoilState(selectedLanguage);
  const [selectedDepart, setSelectedDepart] = useRecoilState(selectedDepartmentObject);

  useEffect(() => {
    setTo(selectedDepart?.yearRange?.to);
    setFrom(selectedDepart?.yearRange?.from);
  }, [selectedDepart])

  const getPreviousYearsPaper = async () => {
    let params = `?exam=${exam}&department=${department}&mode=${mode}`;
    if (selectedSubject) {
      params = params + `&subject=${selectedSubject}`;
    }
    const res = await instance.get(endpoints.getAllPreviousPapers + params);
    setPreviousYears(res.data);
  };

  useEffect(() => {
    getPreviousYearsPaper();
  }, [department, exam, mode, selectedSubject]);

  useEffect(() => {
    if (mode === MODES.SUBJECT_WISE && !subjects.length) getSubjects();
  }, [mode, subjects.length]);

  const getSubjects = async () => {
    const res = await instance.get(
      `${endpoints.getSubjectsByDepartment}?_id=${department}`
    );
    setSubjects(res.data);
  };

  const filterWithSubjects = (e) => {
    setSelectedSubject(e);
  };

  const saveYearRange = async () => {
    const yearRange = { from: Number(from), to: Number(to) };

    await instance.put(endpoints.updateDepartment, {
      _id: department,
      yearRange,
    });

    const selectedDepartClone = { ...selectedDepart };
    selectedDepartClone.yearRange = yearRange;
    setSelectedDepart(selectedDepartClone);

    showToast({ type: 'success', message: 'Successfully Saved Date Range' });
  };

  return (
    <div className='container'>
      <h2 className='mt-3 mb-5 space-heading text-center'>
        Previous Year Papers
      </h2>

      <div className='flex flex-col items-center justify-center mb-4'>
        <Button
          width='30'
          className='h-11 bg-[#c6d7c9] text-[#024f10] border-none text-lg'
          onClick={() => push(`/add-previous-year-paper/${department}/${exam}`)}>
          Create New
        </Button>


        <div className='year-range mt-4'>
          <div className='d-flex flex-column'>
            <Input label='From' value={from} type='text' onChange={setFrom} />
          </div>
          <div>
            <Input label='To' value={to} type='text' onChange={setTo} />
          </div>

          <Button disabled={!from || !to} onClick={saveYearRange}>
            Save
          </Button>
        </div>
      </div>

      <div className='w-100 m-auto mb-5'>
        <div className='d-flex justify-content-center align-items-center gap-4 align-content-center mb-5'>
          <div className='flex items-end gap-3'>
            <Button className={classNames('h-10 shadow-none', {
              'btn-hover btn-active text-white': mode === MODES.FULL_LENGTH,
              'btn-hover text-dark': mode !== MODES.FULL_LENGTH,
            })}

              onClick={() => {
                setSelectedSubject('');
                setMode(MODES.FULL_LENGTH);
              }}>
              Full Length
            </Button>

            <Button className={classNames('h-10 shadow-none', {
              'btn-hover btn-active text-white': mode !== MODES.FULL_LENGTH,
              'btn-hover text-dark': mode === MODES.FULL_LENGTH,
            })}
              onClick={() => {
                setSelectedSubject('');
                setMode(MODES.SUBJECT_WISE);
              }}>
              Subjective
            </Button>


            <div className='mt-1'>
              <h6 className='mb-1 font-semibold'>Select Language</h6>
              <ReactSelect
                onChange={setCurrentLanguage}
                options={selectedDepart?.languages?.map((lang) => {
                  const label = Object.keys(LANGUAGES).find(
                    (key) => LANGUAGES[key] === lang
                  );
                  return {
                    value: lang,
                    label: label.replace(/^./, label[0].toUpperCase()),
                  };
                })}
                value={currentLanguage}
                placeholder='Select Language'
                className='w-52'
              />
            </div>
          </div>
        </div>

        {mode === MODES.FULL_LENGTH ? null : (
          <div>
            <div className='d-flex justify-content-center w-100'>
              <div className='w-70'>
                <Select
                  onChange={filterWithSubjects}
                  value={selectedSubject}
                  label='Filter'>
                  {subjects?.map((item, index) => (
                    <option key={index} value={item._id}>
                      {item.title}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className='w-60 m-auto'>
        {previousYears.length > 0 ? (
          <DisplaySection
            language={currentLanguage}
            type='previousYear'
            dept={department}
            onChange={setPreviousYears}
            collectiveData={previousYears}
          />
        ) : (
          <EmptyIcon />
        )}
      </div>
    </div>
  );
};

const PreviousYears = () => <Previous />;

export default PreviousYears;
