import { useHistory } from "react-router-dom";
import { Button } from "../buttons";

const AddViewBox = ({
  addPath,
  viewPAth,
  addDisable,
  viewDisable,
  label,
  icon,
}) => {
  const { push } = useHistory();

  return (
    <div className="add-view-box">
      {icon}
      <h4>{label}</h4>
      <div className="add-view-button">
        <Button width="25" disabled={addDisable} onClick={() => push(addPath)}>
          Add
        </Button>
        <Button
          width="25"
          disabled={viewDisable}
          onClick={() => push(viewPAth)}
        >
          View
        </Button>
      </div>
    </div>
  );
};

export default AddViewBox;
