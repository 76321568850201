import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import AvailableContentLanguage from '../../components/AvailableContentLanguage';
import { Button } from '../../components/buttons';
import { Images, Markup, Options, Result } from '../../components/displays';
import { endpoints } from '../../constants/Endpoints';
import { authUser, department, selectedLanguage } from '../../providers/index';
import { instance } from '../../services/https/inceptors';
import { Admin } from '../../utilities/constants';
import { choiceLabel, questionType } from '../../utilities/helpers';
import { showToast } from '../../utilities/toast';

const QuestionView = () => {
  const { role, userId } = useRecoilValue(authUser);
  const selectedDepartment = useRecoilValue(department);
  const { push, goBack } = useHistory();
  const { questionId: questionParamId } = useParams();

  const { state } = useLocation();
  const [question, setQuestion] = useState();
  const [publish_, setPublish] = useState();
  const [step, setStep] = useState('current');
  const [ansText, setAnsText] = useState('');
  const [questionId, setQuestionId] = useState('');
  const [currentLanguage, setCurrentLanguage] =
    useRecoilState(selectedLanguage);

  const getSingleQuestion = async (id) => {
    try {
      const res = await instance.get(
        endpoints.getSingleQuestion +
        `?_id=${id}&step=${step}&department=${selectedDepartment.split('/')[1]
        }`
      );
      setQuestion(res.data);
      setStep('');
      setQuestionId('');
    } catch (err) {
      showToast({ type: 'error', message: err.response.data.message });
      if (err.response.status === 404) {
        // push('/');
      }
    }
  };

  useEffect(() => {
    let currentId;
    if (!state?.questionId) {
      currentId = questionParamId;
    } else {
      currentId = state.questionId;
    }

    const id = step === 'current' ? currentId : questionId;

    if (id && step) {
      getSingleQuestion(id);
    }
  }, [questionId, questionParamId, selectedDepartment, state, step]);

  useEffect(() => {
    if (question) {
      setPublish(question?.publish);
      setAnsText(question?.answer);
    }
  }, [question]);

  const deleteQuestion = async () => {
    try {
      const confirmModal = window.confirm(
        'Are you sure want to delete the question'
      );
      if (confirmModal) {
        await instance.delete(
          `${endpoints.deleteQuestion}?_id=${question._id}`
        );
        showToast({
          type: 'success',
          message: 'Successfully Deleted the question',
        });
        goBack();
      }
    } catch (err) {
      showToast({ type: 'error', message: err.response.data.message });
    }
  };

  const publish_unpublish = async () => {
    try {
      const updatedQuestion = await instance.put(`${endpoints.togglePublish}`, {
        _id: question?._id,
        publish: !question.publish,
        publishedBy: userId,
      });

      if (updatedQuestion) {
        setQuestion(updatedQuestion?.data);
        goBack();
        showToast({
          type: 'success',
          message: `Successfully ${question.publish ? 'unpublish' : 'publish'
            } question!`,
        });
      }
    } catch (err) {
      showToast({ type: 'error', message: err.response.data.message });
    }
  };

  const toggleReview = async () => {
    try {
      const res = await instance.put(
        `${endpoints.toggleQuestionReview}?_id=${question._id}&reviewedBy=${userId}`
      );

      const clone = { ...question };
      clone.review = res.data.review;
      setQuestion(clone);

      showToast({
        type: 'success',
        message: clone.review
          ? 'Question marked reviewed'
          : 'Question mark unreviewed',
      });
    } catch (err) {
      showToast({
        type: 'error',
        message: err.response.data.message,
      });
    }
  };

  return (
    <div>
      <AvailableContentLanguage
        currentLanguage={currentLanguage.value}
        setCurrentLanguage={setCurrentLanguage}
        allLanguageContent={question?.text}
      />

      {question && (
        <>
          <div className='py-3 px-2'>
            <div >
              <span className='font-semibold'> Question:</span>
              <Markup latex={question?.text[currentLanguage.value]} />
            </div>
            <Images images={question?.images} />
            <Options language={currentLanguage.value} choices={question?.choices} />
          </div>

          <hr />
          <div className='py-3 px-2'>
            {ansText ? (
              <Markup
                label='Correct Answer:'
                latex={ansText[currentLanguage.value]}
              />
            ) : null}

            {question?.choices.map(
              (choice, index) =>
                choice.answer && (
                  <p key={index}>
                    <span className='font-semibold'>Correct Answer:</span>{' '}
                    Choice {choiceLabel(index)}
                  </p>
                )
            )}

            <div className='mt-3 !mb-7'>
              {question.solution.text[currentLanguage.value] ? (
                <Result
                  language={currentLanguage.value}
                  solution={question?.solution}
                />
              ) : null}
            </div>


            <div className='w-24'>
              {question.solution.images?.length ? (
                <center>
                  <Images images={question.solution.images} />
                </center>
              ) : null}
            </div>

            <div className='marks' style={{ lineHeight: 0.5 }}>
              {question?.markingRule?.positive ? (
                <p className='!mt-2'>
                  <span className='font-semibold'> Positive Marks:</span>{' '}
                  {question?.markingRule?.positive}
                </p>
              ) : null}

              {question?.markingRule?.negative ? (
                <p className='!mt-3'>
                  <span className='font-semibold'> Negative Marks: </span>{' '}
                  {question?.markingRule?.negative}
                </p>
              ) : null}
            </div>
          </div>

          <hr />

          <div className='py-3 px-2'>
            <p>
              <span className='font-semibold'>Department:</span> {selectedDepartment.split('/')[2]}
            </p>
            <p>
              <span className='font-semibold'>Subject:</span> {question?.subject?.title}
            </p>

            <p>
              {questionType(question?.year)}
              {question?.set && `, Set ${question?.set}`}
            </p>

            <div className='d-flex !mt-3'>
              <p className='small text-muted'>
                Posted By:{' '}
                <span style={{ textTransform: 'uppercase' }}>
                  {question?.postedBy && question?.postedBy.username
                    ? question?.postedBy.username
                    : 'NA'}{' '}
                  ({question?.postedBy?.role})
                </span>
              </p>

              <span className='spacer' />
              <p className='small text-muted'>
                Published By:{' '}
                <span className='text-uppercase'>
                  {question?.publishedBy?.username
                    ? question?.publishedBy?.username
                    : 'Not Reviewed'}{' '}
                  {question?.publishedBy?.role ? (
                    <span> ({question?.publishedBy?.role})</span>
                  ) : null}
                </span>
              </p>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col'>
              {(role === 'exam' ||
                role === 'master' ||
                role === 'department') && (
                  <Button
                    width='100'
                    color='danger'
                    style={{ background: 'red' }}
                    onClick={deleteQuestion}>
                    Delete
                  </Button>
                )}
            </div>

            <div className='col'>
              <Button
                width='100'
                color='warning'
                style={{ background: 'yellow' }}
                onClick={() => push('/edit/question', { question })}>
                Edit
              </Button>
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col'>
              <Button
                width='100'
                color='primary'
                onClick={() => {
                  setStep('previous');
                  setQuestionId(question._id);
                }}>
                Previous
              </Button>
            </div>

            <div className='col'>
              <Button
                width='100'
                color='primary'
                onClick={() => {
                  setStep('next');
                  setQuestionId(question._id);
                }}>
                Next
              </Button>
            </div>

            {role === Admin.MASTER && (
              <div className='col'>
                <Button width='100' color='primary' onClick={publish_unpublish}>
                  {publish_ ? 'Unpublish' : 'Publish'}
                </Button>
              </div>
            )}

            <div className='col'>
              <Button
                style={{
                  fontSize: 14,
                  background: question.review ? 'gray' : 'green',
                }}
                width='100'
                color='primary'
                onClick={toggleReview}>
                {question.review ? 'Mark Unreviewed' : 'Mark Review'}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const QuestionViewPage = () => (
  <div className='h-100 py-5 overflow-auto'>
    <div className='container'>
      <div className='row justify-content-center'>
        <div className='w-50'>
          <QuestionView />
        </div>
      </div>
    </div>
  </div>
);

export default QuestionViewPage;
