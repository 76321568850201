const EmptyIcon = () => {
  return (
    <div
      style={{ marginTop: "6rem" }}
      className="d-flex flex-column align-items-center justify-content-center"
    >
      <img
        style={{ left: "10px", position: "relative" }}
        alt="No data"
        src="https://cdn-icons-png.flaticon.com/128/7466/7466140.png"
      />
      <p className="text-center">Empty</p>
    </div>
  );
};

export default EmptyIcon;
