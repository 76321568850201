import ReactSelect from 'react-select';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import DepartmentSelect from '../../components/AutoComplete/department-select';
import ExamSelect from '../../components/AutoComplete/exam-select';
import { LANGUAGES } from '../../constants/Common';
import {
  globalSelectedDepartLabel,
  selectedDepartmentObject,
  selectedLanguage,
} from '../../providers';
import { globalSelectedExamLabel } from '../../providers/index';

const SelectPanel = ({
  selectExam,
  setAllDepartments,
  loading,
  allExams,
  setDepartment,

  allExamDepartment,
}) => {
  const [departSelectValue, setDepartSelectValue] = useRecoilState(
    globalSelectedDepartLabel
  );

  const [examSelectValue, setExamSelectValue] = useRecoilState(
    globalSelectedExamLabel
  );

  return (
    <div className='!flex !justify-center !items-center my-5 gap-5'>
      <div className='w-1/4'>
        <ExamSelect
          {...{
            setExamSelectValue,
            selectExam,
            setAllDepartments,
            setDepartSelectValue,
            loading,
            examSelectValue,
            allExams,
          }}
        />
      </div>
      <div className='w-1/4'>
        <DepartmentSelect
          {...{
            setDepartSelectValue,
            setDepartment,
            examSelectValue,
            loading,
            departSelectValue,
            allExamDepartment,
          }}
        />
      </div>
    </div>
  );
};

export default SelectPanel;
