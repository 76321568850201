import React, { useEffect, useState } from "react";
import { Button } from "../../components/buttons";
import { Input, Select } from "../../components/inputs";
import useData from "../../hooks/useData";
import { BASE, useFetch } from "../../utilities/apis";
import { showToast } from "../../utilities/toast";

const AddChapter = ({ exams }) => {
  const { post } = useFetch();

  const [exam, setExam] = useState("62f356c787f2e477c8e145ae");
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [subject, setSubject] = useState("");
  const [chapter_code, setChapterCode] = useState("");
  const [chapter_title, setChapterTitle] = useState("");
  const { getDepartmentByExam, getSubjectByDepartment } = useData();

  const getDepartments = async () => {
    const data = await getDepartmentByExam(exam);
    setDepartments(data);
  };

  const getSubjects = async () => {
    const data = await getSubjectByDepartment(department);
    setSubjects(data);
  };

  useEffect(() => {
    getDepartments();
  }, [exam]);

  useEffect(() => {
    getSubjects();
  }, [department]);

  function submit() {
    const data = {
      code: chapter_code,
      title: chapter_title,
      type: "chapter",
      subject: subject,
    };

    try {
      post(BASE + "new", data, (error, _) => {
        if (error) return console.log(error);
      });
      setChapterCode("");
      setChapterTitle("");
      showToast({ type: "success", message: "Successfully Added Chapter!" });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  }
  return (
    <>
      <>
        <div className="h-100 overflow-auto ">
          <div className="container mt-3">
            <div className="d-flex flex-row justify-content-around mb-4 ">
              <div className="border p-3 w-40 ">
                <h6>Add Chapter</h6>

                <Select value={exam} onChange={setExam} label="Exam">
                  {exams?.map((exam, index) => (
                    <option key={index} value={exam._id}>
                      {exam.title}
                    </option>
                  ))}
                </Select>

                <Select
                  value={department}
                  onChange={setDepartment}
                  label="Department"
                >
                  {departments?.map((department, index) => (
                    <option key={index} value={department._id}>
                      {department.title}
                    </option>
                  ))}
                </Select>

                <Select value={subject} onChange={setSubject} label="Subject">
                  {subjects?.map((subject, index) => (
                    <option key={index} value={subject._id}>
                      {subject.title}
                    </option>
                  ))}
                </Select>
                <div className="form-group">
                  <Input
                    label="Chapter Code"
                    id="examCode"
                    type={"text"}
                    value={chapter_code}
                    onChange={setChapterCode}
                  />
                </div>
                <div className="form-group">
                  <Input
                    label="Chapter Title"
                    id="examTitle"
                    type={"text"}
                    value={chapter_title}
                    onChange={setChapterTitle}
                  />
                </div>
                <div>
                  <Button
                    className="outline"
                    width="25"
                    onClick={submit}
                    disabled={!chapter_code || !chapter_title || subject == ""}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};
export default AddChapter;
