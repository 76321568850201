import classNames from "classnames";
import React from "react";
import { getLanguageName } from "../../utilities/helpers";

const AvailableContentLanguage = ({
  allLanguageContent,
  currentLanguage,
  setCurrentLanguage,
  noSelect,
}) => {
  return (
    <div className="flex justify-end gap-x-1">
      {allLanguageContent &&
        Object.keys(allLanguageContent)?.map((lang) =>
          allLanguageContent[lang]?.length ? (
            <div
              onClick={() => {
                if (!noSelect) {
                  setCurrentLanguage({
                    label: getLanguageName(lang).replace(
                      /^./,
                      getLanguageName(lang)[0].toUpperCase()
                    ),
                    value: lang,
                  });
                }
              }}
              className={classNames(
                "capitalize bg-gray-400 text-white px-2 py-1 rounded-sm text-xs ",
                {
                  "!bg-[#024f10]": currentLanguage === lang && !noSelect,
                  "cursor-pointer": !noSelect,
                }
              )}
            >
              {getLanguageName(lang)}
            </div>
          ) : null
        )}
    </div>
  );
};

export default AvailableContentLanguage;
