import React from "react";
import { Button } from "../../components/buttons";
import { Input } from "../../components/inputs";

const AddExam = ({
  examCode,
  examTitle,
  setExamCode,
  setExamTitle,
  submitExam,
}) => {
  return (
    <>
      <div className="h-100 overflow-auto ">
        <div className="container mt-3">
          <div className="d-flex flex-row justify-content-around mb-4 ">
            <div className="border p-3 w-40 ">
              <h6>Add Exam</h6>

              <div className="form-group">
                <label htmlFor="examCode">Exam Code</label>
                <Input
                  id="examCode"
                  type={"text"}
                  value={examCode}
                  onChange={setExamCode}
                />
              </div>
              <div className="form-group">
                <label htmlFor="examTitle">Exam Title</label>
                <Input
                  id="examTitle"
                  type={"text"}
                  value={examTitle}
                  onChange={setExamTitle}
                />
              </div>
              <div>
                <Button
                  className="outline"
                  width="25"
                  disabled={!examCode || !examTitle}
                  onClick={submitExam}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddExam;
