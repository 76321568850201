import { useState } from 'react';
import {
  BsFillArrowUpCircleFill,
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from 'react-icons/bs';
import { useLocation } from 'react-router-dom';
import SelectedOptions from '../SelectedOptions';

const ScrollTop = () => {
  const [showOptions, setShowOptions] = useState(true);
  const { pathname } = useLocation();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  if (pathname === '/login') return null;
  else
    return (
      <div className='flex items-center arrow-up gap-x-2 transition-all'>
        {showOptions ? (
          <div>
            <BsFillArrowLeftCircleFill
              className='fill-slate-500'
              onClick={() => setShowOptions(false)}
            />
          </div>
        ) : (
          <div className='flex gap-x-2'>
            <BsFillArrowRightCircleFill
              className='fill-slate-500'
              onClick={() => setShowOptions(true)}
            />
            <SelectedOptions />
          </div>
        )}

        <div>
          <BsFillArrowUpCircleFill onClick={scrollToTop} />
        </div>
      </div>
    );
};

export default ScrollTop;
