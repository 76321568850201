import { toast } from "react-toastify";

export const showToast = (props) => {
  toast(props.message || "Oh! something went wrong...", {
    type: props.type,
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    style: { top: "1rem" },
    closeOnClick: true,
    closeButton: true,
    onClick: props.onClick,
  });
};
