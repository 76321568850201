import '../../styles/layouts.scss';

import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Admin } from '../../utilities/constants';
import { Input, Select } from '../../components/inputs';
import { Type } from '../../utilities/constants';

import { years } from '../../utilities/helpers';
import { Button } from '../../components/buttons';
import { useRecoilValue } from 'recoil';
import { authUser, questionFilterLanguage, selectedDepartmentObject, selectedLanguage } from '../../providers/index';
import { instance } from '../../services/https/inceptors';
import { endpoints } from '../../constants/Endpoints';
import { ANSWER_MODES } from '../../constants/LocalConstants';
import QuestionBox from '../../components/questions/QuestionBox';
import '../../pages/Questions/style.scss';
import useData from '../../hooks/useData';
import { useRecoilState } from 'recoil';
import {
  year,
  subject,
  chapter,
  topic,
  mode,
  marks,
  set,
  filteredQuestions,
} from '../../providers/index';
import { Loader } from '../../components/Loaders';
import ReactSelect from 'react-select';
import { LANGUAGES } from '../../constants/Common';

const Questions = () => {
  const { role } = useRecoilValue(authUser);

  const { push } = useHistory();
  const { department, exam } = useParams();
  const [subjects, setSubjects] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [topics, setTopics] = useState([]);

  const [selectedSubject, setSelectedSubject] = useRecoilState(subject);
  const [selectedChapter, setSelectedChapter] = useRecoilState(chapter);
  const [selectedTopic, setSelectedTopic] = useRecoilState(topic);
  const [selectedYear, setSelectedYear] = useRecoilState(year);
  const [selectedAnswerMode, setSelectedAnswerMode] = useRecoilState(mode);
  const [selectedMarks, setSelectedMarks] = useRecoilState(marks);
  const [selectedSet, setSelectedSet] = useRecoilState(set);
  const [loading, setLoading] = useState(false);
  const [supportAdmins, setSupportAdmins] = useState();
  const [selectedSupportAdmin, setSelectedSupportAdmin] = useState();
  const [publish, setPublish] = useState();
  const [questions, setQuestions] = useRecoilState(filteredQuestions);
  const [language, setLanguage] = useRecoilState(selectedLanguage);
  const [languageValueOnSubmit, setLanguageValueOnSubmit] = useRecoilState(questionFilterLanguage);

  const [reviewed, setReviewed] = useState();
  const selectedDepart = useRecoilValue(selectedDepartmentObject);

  const { getSubjectByDepartment, getChaptersBySubject, getTopicsByChapter } =
    useData();

  const getSubject = async () => {
    const subjects = await getSubjectByDepartment(department);
    setSubjects(subjects);
  };

  const getChapters = async () => {
    const chapter = await getChaptersBySubject(selectedSubject);
    setChapters(chapter);
  };

  const getTopics = async () => {
    const topic = await getTopicsByChapter(selectedChapter);
    setTopics(topic);
  };

  const getAllSupportAdmins = async () => {
    const res = await instance.get(
      `${endpoints.getSupportAdmins}?exam=${exam}`
    );
    setSupportAdmins(res.data);
  };

  useEffect(() => {
    if (role !== Admin.SENIOR && role !== Admin.SUPPORT) {
      getAllSupportAdmins();
    }

    if (selectedTopic) getTopics();
    if (selectedSubject) getChapters();
    if (department) getSubject();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [department]);

  const fetchQuestions = async () => {
    setLoading(true);
    let url = `?department=${department}&language=${language.value}`;
    if (selectedSubject) url += `&subject=${selectedSubject}`;
    if (selectedChapter) url += `&chapter=${selectedChapter}`;
    if (selectedTopic) url += `&topic=${selectedTopic}`;
    if (selectedYear) url += `&year=${selectedYear}`;
    if (selectedAnswerMode) url += `&mode=${selectedAnswerMode}`;
    if (selectedMarks) url += `&marks=${selectedMarks}`;
    if (selectedSet) url += `&set=${selectedSet}`;
    if (selectedSupportAdmin) url += `&postedBy=${selectedSupportAdmin}`;
    if (publish) url += `&publish=${publish}`;
    if (reviewed) url += `&review=${reviewed}`;

    const res = await instance.get(`${endpoints.getQuestions}/${url}`);
    setLanguageValueOnSubmit(language)
    setQuestions(res.data);
    setLoading(false);
  };

  const selectSubject = async (subject) => {
    setSelectedTopic('');
    setSelectedChapter('');
    setSelectedSubject(subject);
    const chapter = await getChaptersBySubject(subject);
    setChapters(chapter);
  };

  const selectChapter = async (chapter) => {
    setSelectedTopic('');
    setSelectedChapter(chapter);
    const topic = await getTopicsByChapter(chapter);
    setTopics(topic);
  };

  function selectTopic(e) {
    setSelectedTopic(e);
  }

  return (
    <>
      <div className='row row-cols-4 mt-5'>
        <div className='col'>
          <Select
            label='Subject'
            value={selectedSubject}
            onChange={selectSubject}
            vertical>
            {subjects?.map((sub) => (
              <option key={sub?.code} value={sub?._id}>
                {sub.title}
              </option>
            ))}
          </Select>
        </div>

        <div className='col'>
          <Select
            label='Chapter'
            value={selectedChapter}
            onChange={selectChapter}
            vertical>
            {chapters?.map((chapter) => (
              <option key={chapter.code} value={chapter._id}>
                {chapter.title}
              </option>
            ))}
          </Select>
        </div>

        <div className='col'>
          <Select
            label='Topic'
            value={selectedTopic}
            onChange={selectTopic}
            vertical>
            {topics?.map((topic) => (
              <option key={topic.code} value={topic._id}>
                {topic.title}
              </option>
            ))}
          </Select>
        </div>

        <div className='col'>
          <Select
            label='Mode'
            value={selectedAnswerMode}
            onChange={(e) => setSelectedAnswerMode(e)}
            vertical>
            {ANSWER_MODES.map((mode) => (
              <option value={mode.value}>{mode.label}</option>
            ))}
          </Select>
        </div>

        <div className='col'>
          <Select
            label='Year'
            value={selectedYear}
            onChange={(e) => setSelectedYear(e)}
            vertical>
            <option value={Type.SERIES}>Test Series</option>
            <option value={Type.QUIZ}>Quiz</option>
            <option value={Type.Assignment}>Assignment</option>
            <option value={Type.DPP}>DPP</option>
            <option value={Type.Workbook}>Workbook</option>
            <option value={Type.Other}>Other</option>

            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Select>
        </div>

        <div className='col'>
          <Input
            label='Total marks'
            value={selectedMarks}
            onChange={setSelectedMarks}
          />
        </div>

        <div className='col'>
          <Select
            label='Set'
            value={selectedSet}
            onChange={(e) => setSelectedSet(e)}
            vertical>
            {[
              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
              20, 21, 22, 23, 24, 25, 26, 27, 28, 29,30, 31, 32, 33, 34, 35,36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50
            ].map((set) => (
              <option key={set} value={set}>
                {set}
              </option>
            ))}
          </Select>
        </div>

        {role !== Admin.SUPPORT ? (
          <div className='col'>
            <Select
              label='Support Admins'
              value={selectedSupportAdmin}
              onChange={setSelectedSupportAdmin}
              vertical>
              {supportAdmins?.map((sub) => (
                <option key={sub?._id} value={sub?._id}>
                  {sub.username}
                </option>
              ))}
            </Select>
          </div>
        ) : null}

        <div className='col'>
          <Select label='Reviewed' value={reviewed} onChange={setReviewed}>
            <option value={true}>True</option>
            <option value={false}>False</option>
          </Select>
        </div>

        {role === Admin.MASTER ? (
          <div className='col'>
            <Select label='Publish' value={publish} onChange={setPublish}>
              <option value={true}>True</option>
              <option value={false}>False</option>
            </Select>
          </div>
        ) : null}

        <div className='mt-1'>
          <h6 className='mb-1'>Select Content Language</h6>
          <ReactSelect
            onChange={(e) => setLanguage(e)}
            options={selectedDepart?.languages?.map((lang) => {
              const label = Object.keys(LANGUAGES).find(
                (key) => LANGUAGES[key] === lang
              );
              return {
                value: lang,
                label: label.replace(/^./, label[0].toUpperCase()),
              };
            })}
            value={language}
            placeholder='Select Language'
            className='!w-76'
          />
        </div>
      </div>
      <div className='d-flex justify-content-between mt-3'>
        {role === Admin.MASTER && (
          <Button
            color='warning'
            width='25'
            style={{ background: 'yellow' }}
            onClick={() => push(`/bulk-edit/${department}`)}>
            Bulk Edit
          </Button>
        )}
        <Button color='primary'>Total Questions: {questions.length}</Button>
        <Button
          disabled={loading}
          color='success'
          width='25'
          onClick={fetchQuestions}>
          Go
        </Button>
      </div>
      <hr className='m-4' />

      {loading ? (
        <Loader />
      ) : (
        <div className='mb-5'>
          {questions.map((question, index) => (
            <div className='question-flex !items-start'>
              <div className='count'>{index + 1}.</div>
              <QuestionBox
                language={languageValueOnSubmit.value}
                question={question}
                index={index}
                length={questions.length}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const ViewPage = () => (
  <div className='container'>
    <Questions />
  </div>
);

export default ViewPage;
