import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import { endpoints } from "../constants/Endpoints";
import { LOCAL_CONSTANT } from "../constants/LocalConstants";
import {
  allExamDepartments,
  authUser,
  department,
  exam,
  globalSelectedDepartLabel,
  globalSelectedExamLabel,
  selectedLanguage,
} from "../providers/index";
import { instance } from "../services/https/inceptors";
import { showToast } from "../utilities/toast";

const useUserActions = () => {
  const [error] = useState("");
  const setUser = useSetRecoilState(authUser);
  const resetAuth = useResetRecoilState(authUser);
  const resetExam = useResetRecoilState(exam);
  const resetDepartment = useResetRecoilState(department);
  const resetAllExamDepart = useResetRecoilState(allExamDepartments);
  const resetSelectedExamLabel = useResetRecoilState(globalSelectedExamLabel);
  const resetSelectedLanguage = useResetRecoilState(selectedLanguage);

  const resetSelectedDepartLabel = useResetRecoilState(
    globalSelectedDepartLabel
  );

  const history = useHistory();

  const login = (username, password) => {
    instance
      .post(endpoints.login, { username, password })
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        showToast({ message: err.response.data.message, type: "error" });
      });
  };

  const logout = () => {
    history.push("/login");
    localStorage.removeItem(LOCAL_CONSTANT.USER);
    resetAuth();
    resetExam();
    resetDepartment();
    resetAllExamDepart();
    resetSelectedExamLabel();
    resetSelectedDepartLabel();
    resetSelectedLanguage();
  };

  return { login, logout, error };
};

export default useUserActions;
