import { Markup } from '../displays';

const CommonResult = ({ text, label, language }) => {
  return (
    <>
      <h6>{label}</h6>
      {text && <Markup latex={typeof text?.[language] === 'string' ? text?.[language] : ''} />}
    </>
  );
};

export default CommonResult;
