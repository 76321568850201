import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactSelect from 'react-select';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Submit } from '../../components/buttons';
import { Markup } from '../../components/displays';
import { Select } from '../../components/inputs';
import { Question } from '../../components/question';
import { LANGUAGES } from '../../constants/Common';
import { endpoints } from '../../constants/Endpoints';
import useData from '../../hooks/useData';
import { Input } from "../../components/inputs";
import {
  authUser,
  department as recoilDepart,
  exam as recoilExam,
  selectedDepartmentObject,
  selectedLanguage,
} from '../../providers/index';
import { instance } from '../../services/https/inceptors';
import { showToast } from '../../utilities/toast';
import { GrChapterAdd } from 'react-icons/gr';
import { AiTwotoneDelete } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';

const EditContent = () => {
  const { contentId } = useParams();
  const [subject, setSubject] = useState('');
  const [chapter, setChapter] = useState('');
  const [topic, setTopic] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [topics, setTopics] = useState([]);
  const [contents, setContents] = useState([]);
  const user = useRecoilValue(authUser);
  const dataHook = useData();
  const department = useRecoilValue(recoilDepart).split('/')[1];
  const exam = useRecoilValue(recoilExam).split('/')[1];
  const [language, setLanguage] = useRecoilState(selectedLanguage);

  const selectedDepart = useRecoilValue(selectedDepartmentObject);
  const history = useHistory();


  const getContent = async () => {
    const res = await instance.get(
      `${endpoints.getSingleContent}?_id=${contentId}`
    );

    setPrevious(res.data);
  };

  const fetchChapter = async () => {
    const res = await dataHook.getChaptersBySubject(subject);
    setChapters(res);
  };

  const fetchTopics = async () => {
    const res = await dataHook.getTopicsByChapter(chapter);
    setTopics(res);
  };

  useEffect(() => {
    getContent();
  }, []);

  useEffect(() => {
    const fetchSubjects = async () => {
      const res = await dataHook.getSubjectByDepartment(department);
      setSubjects(res);
    };

    if (department) {
      fetchSubjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [department]);

  useEffect(() => {
    if (subject) {
      fetchChapter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject]);

  useEffect(() => {
    if (chapter) {
      fetchTopics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapter]);

  const setPrevious = (data) => {
    setContents(data.contents)
    setSubject(data.subject);
    setChapter(data.chapter);
    setTopic(data.topic);
  };

  useEffect(() => {
    const fetchSubjects = async () => {
      const res = await dataHook.getSubjectByDepartment(department);
      setSubjects(res);
    };
    fetchSubjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [department]);

  const onSubjectChange = async (e) => {
    setChapter('');
    setTopic('');

    setSubject(e);
    const res = await dataHook.getChaptersBySubject(e);
    setChapters(res);
  };

  const onChapterChange = async (e) => {
    setChapter(e);
    setTopic('');
    const res = await dataHook.getTopicsByChapter(e);
    setTopics(res);
  };

  const onLanguageChange = useCallback((e) => {
    setLanguage(e);
    const contentClone = [...contents]
    const newContents = contentClone.map(({ content, title }) => {

      let iContent = {};
      let iTitle = {};

      if (!content[e.value]) {
        iContent = { ...content, [e.value]: '' }
      } else {
        iContent = { ...content }
      }

      if (!title[e.value]) {
        iTitle = { ...title, [e.value]: '' }
      } else {
        iTitle = { ...title }
      }

      return { content: iContent, title: iTitle };
    })

    setContents(newContents)
  }, [contents, setLanguage]);

  const submit = async () => {
    let data = {
      _id: contentId,
      contents,
      exam,
      department,
      updatedBy: user?.userId,
    };
    if (subject) data.subject = subject;
    if (chapter) data.chapter = chapter;
    if (topic) data.topic = topic;

    try {
      await instance.put(endpoints.editContent, data);
      showToast({ type: 'success', message: 'Content Successfully Updated!' });
      history.goBack()
    } catch (err) {
      showToast({ type: 'error', message: err.response.data.message });
    }
  };

  const onSingleTitleChange = useCallback((value, index) => {
    const contentClone = [...contents]
    contentClone[index].title[language.value] = value
    setContents(contentClone)
  }, [contents, language.value])


  const addContents = useCallback(() => {
    const contentsClone = [...contents];
    contentsClone.push({ title: { [language.value]: '' }, content: { [language.value]: '' } })
    setContents(contentsClone)
  }, [contents, language.value])


  const deleteContents = useCallback((index) => {
    const contentsClone = [...contents];
    contentsClone.splice(index, 1)
    setContents(contentsClone)
  }, [contents])

  const onSingleContentChange = useCallback(async (value, index) => {
    const contentClone = [...contents]
    contentClone[index].content[language.value] = value
    setContents(contentClone)
  }, [contents, language.value])


  return (
    <div className='container col h-100 overflow-auto mt-5'>
      <h2 className='text-center space-heading mb-4'>Edit Content</h2>
      <div className='d-flex card align-items-center'>
        <div className='d-flex card-body' style={{ gap: 30 }}>
          <Select
            label='Subject'
            value={subject}
            onChange={onSubjectChange}
            vertical>
            {subjects?.map((subject) => (
              <option key={subject.code} value={subject._id}>
                {subject.title}
              </option>
            ))}
          </Select>

          <Select
            label='Chapter'
            value={chapter}
            onChange={onChapterChange}
            vertical>
            {chapters.map((chapter) => (
              <option key={chapter.code} value={chapter._id}>
                {chapter.title}
              </option>
            ))}
          </Select>

          <Select label='Topic' value={topic} onChange={setTopic} vertical>
            {topics.map((topic) => (
              <option key={topic.code} value={topic._id}>
                {topic.title}
              </option>
            ))}
          </Select>

          <div className='mt-1'>
            <h6 className='mb-1'>Select Question Language</h6>
            <ReactSelect
              onChange={onLanguageChange}
              options={selectedDepart?.languages?.map((lang) => {
                const label = Object.keys(LANGUAGES).find(
                  (key) => LANGUAGES[key] === lang
                );
                return {
                  value: lang,
                  label: label.replace(/^./, label[0].toUpperCase()),
                };
              })}
              value={language}
              placeholder='Select Language'
              className='w-52'
            />
          </div>
        </div>
      </div>

      {/* <div className="d-flex flex-column p-4 mt-5"> */}
      <div className='d-flex w-100 gap-3 mt-4'>
        <div className='w-50 p-3 question-scroll'>
          <div onClick={addContents} className='flex gap-x-2 justify-end items-center cursor-pointer mb-4'>
            <GrChapterAdd size={25} />
            <div className='font-semibold'>Add Tabs</div>
          </div>

          {contents.map(({ title, content }, index) => (
            <div className='border-2 px-3 mb-4 relative'>
              <div onClick={() => deleteContents(index)} className='absolute right-2 top-2 cursor-pointer'>
                <AiTwotoneDelete size={26} />
              </div>
              <Input
                id='name'
                className='mt-4'
                label='Title'
                value={title?.[language?.value]}
                onChange={(value) => onSingleTitleChange(value, index)}
              />
              <Question
                returnEvent
                language={language.value}
                label='Content Description'
                value={content[language.value]}
                onChange={(value) => onSingleContentChange(value, index)}
              />
            </div>
          ))}
        </div>

        <div className='w-50 p-3 question-scroll'>
          <h4 className='mb-3 font-semibold'>Result</h4>
          {contents.map(({ content, title }) =>
            <div className='rounded p-3 overflow-auto d-flex flex-column mb-3 border-2'>
              <div>
                <h6 className='mb-2 font-semibold text-[#024f108f]'>Content Title</h6>
                <Markup latex={`<h5>${title[language.value]}</h5>`} />
              </div>

              <div style={{ marginTop: '2rem' }}>
                <h6 className='font-semibold text-[#024f108f]'>Content Description</h6>
                {content && <Markup latex={content[language.value]} />}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='d-flex justify-content-center mt-5 mb-5'>
        <Submit
          onClick={submit}
          width='40'
          form='form'
          disabled={!contents.length || !topic}
        />
      </div>
    </div>
  );
};

export default EditContent;
