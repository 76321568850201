import "./card.scss";

const Card = ({ title, disabled, icon }) => {
  return (
    <div
      class="items-1 w-100"
      style={{
        cursor: disabled ? "not-allowed" : "pointer",
        opacity: disabled ? 0.6 : 1,
        filter: disabled ? "blur(2px)" : "none",
      }}
    >
      <div class="icon-wrapper-1">{icon}</div>
      <div class="project-name-1">
        <p>{title}</p>
      </div>
    </div>
  );
};

export default Card;
