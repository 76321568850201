import React from "react";
import { Switch } from "react-router-dom";
import { useRecoilValue } from "recoil";
import "../src/components/style.scss";
import Header from "./components/Header";
import LoginPage from "./pages/Login";
import { authUser } from "./providers/index";
import PrivateRoute from "./routes/privateRoutes";
import PublicRoute from "./routes/publicRoutes";
import "./styles/application.scss";
import routes from "./utilities/routes";
import ScrollTop from "./components/ScrollTop";

const Application = () => {
  const recoilAuth = useRecoilValue(authUser);

  return (
    <>
      <ScrollTop />
      <Header />
      <Switch>
        <PublicRoute path="/login" isAuthenticated={recoilAuth}>
          <LoginPage />
        </PublicRoute>
        {routes.map(({ path, exact, page: Page }, index) => {
          return (
            <PrivateRoute
              key={index}
              isAuthenticated={recoilAuth}
              path={path}
              exact={exact}
            >
              <Page />
            </PrivateRoute>
          );
        })}
      </Switch>
    </>
  );
};

export default Application;
