import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "../../components/buttons";
import { Input, Select } from "../../components/inputs";
import { endpoints } from "../../constants/Endpoints";
import { instance } from "../../services/https/inceptors";
import { showToast } from "../../utilities/toast";

const CreateReview = () => {
  const [username, setUsername] = useState("");
  const [designation, setDesignation] = useState("");
  const [comment, setComment] = useState("");
  const [photo, setPhoto] = useState("");
  const [gender, setGender] = useState("");
  const { id: _id } = useParams();
  const { goBack } = useHistory();

  const submitReview = async () => {
    try {
      const review = {
        username,
        designation,
        comment,
        photo,
        gender,
      };

      await instance.post(endpoints.addReview, { _id, review });
      showToast({ type: "success", message: "Successfully Added Review" });
      goBack();
    } catch (err) {
      showToast({ type: "success", message: err?.response?.data?.message });
    }
  };

  return (
    <div className="container">
      <h1 className="text-center space-heading">Create Review</h1>
      <div className="w-40 m-auto mt-5">
        <Input label="User Name" value={username} onChange={setUsername} />
        <Input
          className="mt-4"
          label="Designation"
          value={designation}
          onChange={setDesignation}
        />
        <div className="mt-4">
          <h6>Comment</h6>
          <textarea
            className="p-2 w-100 comment-textarea"
            rows="6"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>

        <Input
          className="mt-4"
          label="Display Photo"
          value={photo}
          onChange={setPhoto}
        />

        <Select label="Gender" onChange={setGender} value={gender}>
          <option value="MALE">MALE</option>
          <option value="FEMALE">FEMALE</option>
        </Select>

        <div className="text-center">
          <Button
            disabled={
              !username || !designation || !comment || !gender || !photo
            }
            onClick={submitReview}
            className="w-50 mt-4 mb-5"
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateReview;
