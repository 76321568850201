import { useRecoilValue } from "recoil";
import { authUser } from "../providers/index";

// Base API URL
const API = process.env.REACT_APP_BASE_URL;

/**
 * Login function
 * @param {JSON} data
 * @param {(error: JSON, result: JSON)} callback
 */

async function login(data, callback) {
  const response = await fetch(API + "admins/login", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });

  const result = await response.json();

  if (!response.ok) {
    return callback(result.error);
  }

  callback(null, result);
}

/**
 * Hook for using fetch api
 * Defines use of methonds GET, POST
 * Automatically adds headers including access token
 */

function useFetch() {
  const auth = useRecoilValue(authUser);

  const base = async (url, method, data, callback) => {
    const options = { method, headers: { "x-access-token": auth.token } };
    if (data) {
      options.headers["Content-Type"] = "application/json";
      options.body = JSON.stringify(data);
    }

    const response = await fetch(url, options);

    const result = await response.json();

    if (!response.ok) {
      return callback(result);
    }

    callback(null, result);
  };

  const get = async (url, callback) => {
    base(url, "GET", null, callback);
  };
  const post = async (url, data, callback) => {
    base(url, "POST", data, callback);
  };

  const update = async (url, data, callback) => {
    base(url, "PUT", data, callback);
  };

  const remove = async (url, callback) => {
    base(url, "DELETE", null, callback);
  };

  return { get, post, update, remove };
}

export { login, useFetch };

/**
 * API Constants
 */

export const ADMINS = API + "admins/";
export const QUESTIONS = API + "getQuestions/";
export const IMAGES = API + "images/";
export const SERIES = API + "series/";
export const QUIZ = API + "quiz/";
export const PREVIOUSYEARS = API + "previousYears/";
export const TESTSERIES = API + "testSeries/";
export const BASE = API + "base/";
export const NOTIFICATIONS = API + "notifications/";
export const SYLLABUS = API + "syllabus/";
export const ANALYSIS = API + "exam-analysis/";
export const CUTOFF = API + "yearcutoff/";
export const VIDEO = API + "general-video/";
export const CONTENT = API + "content/";
export const WORKBOOK = API + "workbook/";
export const ASSIGNMENT = API + "assignment/";
export const DPP = API + "dpp/";
