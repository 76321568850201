import React, { useEffect, useState } from "react";
import { Button } from "../../components/buttons";
import { Input, Select } from "../../components/inputs";
import useData from "../../hooks/useData";
import { BASE, useFetch } from "../../utilities/apis";
import { showToast } from "../../utilities/toast";

const AddTopic = ({ exams }) => {
  const { post } = useFetch();

  const [exam, setExam] = useState("62f356c787f2e477c8e145ae");
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [subject, setSubject] = useState("");
  const [chapters, setChapters] = useState([]);
  const [chapter, setChapter] = useState("");
  const [topic_code, setTopicCode] = useState("");
  const [topic_title, setTopicTitle] = useState("");
  const { getDepartmentByExam, getSubjectByDepartment, getChaptersBySubject } =
    useData();

  const getDepartments = async () => {
    const data = await getDepartmentByExam(exam);
    setDepartments(data);
  };

  const getSubjects = async () => {
    const data = await getSubjectByDepartment(department);
    setSubjects(data);
  };

  const getChapters = async () => {
    const data = await getChaptersBySubject(subject);
    setChapters(data);
  };

  useEffect(() => {
    getDepartments();
  }, [exam]);

  useEffect(() => {
    getSubjects();
  }, [department]);

  useEffect(() => {
    getChapters();
  }, [subject]);

  function submit() {
    const data = {
      code: topic_code,
      title: topic_title,
      type: "topic",
      chapter: chapter,
    };

    try {
      post(BASE + "new", data, (error, _) => {
        if (error) return console.log(error);
      });

      setTopicCode("");
      setTopicTitle("");
      showToast({ type: "success", message: "Successfully Added Topic!" });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  }
  return (
    <div className="h-100 overflow-auto ">
      <div className="container mt-3">
        <div className="d-flex flex-row justify-content-around mb-4 ">
          <div className="border p-3 w-40 ">
            <h6>Add Topic</h6>

            <Select value={exam} onChange={setExam} label="Exam">
              {exams?.map((exam, index) => (
                <option key={index} value={exam._id}>
                  {exam.title}
                </option>
              ))}
            </Select>

            <Select
              value={department}
              onChange={setDepartment}
              label="Deparment"
            >
              {departments?.map((department, index) => (
                <option key={index} value={department._id}>
                  {department.title}
                </option>
              ))}
            </Select>

            <Select value={subject} onChange={setSubject} label="Subject">
              {subjects.map((subject, index) => (
                <option key={index} value={subject._id}>
                  {subject.title}
                </option>
              ))}
            </Select>

            <Select value={chapter} onChange={setChapter} label="Chapter">
              {chapters?.map((chapter, index) => (
                <option key={index} value={chapter._id}>
                  {chapter.title}
                </option>
              ))}
            </Select>
            <div className="form-group">
              <label htmlFor="examCode">Topic Code</label>
              <Input
                id="examCode"
                type={"text"}
                value={topic_code}
                onChange={setTopicCode}
              />
            </div>
            <div className="form-group">
              <label htmlFor="examTitle">Topic Title</label>
              <Input
                id="examTitle"
                type={"text"}
                value={topic_title}
                onChange={setTopicTitle}
              />
            </div>
            <div>
              <Button
                className="outline"
                width="25"
                onClick={submit}
                disabled={!topic_title || !topic_code || chapter == ""}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddTopic;
