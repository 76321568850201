import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { Tabs } from "../../components/layouts";
import { endpoints } from "../../constants/Endpoints";
import { authUser } from "../../providers/index";
import { instance } from "../../services/https/inceptors";
import { BASE, useFetch } from "../../utilities/apis";
import { showToast } from "../../utilities/toast";
import AddChapter from "./AddChapter";
import AddDepartment from "./AddDepartment";
import AddExam from "./AddExam";
import AddSubject from "./AddSubject";
import AddTopic from "./AddTopic";

const AddSection = () => {
  const [examTitle, setExamTitle] = useState("");
  const [examCode, setExamCode] = useState("");
  const [exams, setExams] = useState([]);
  const user = useRecoilValue(authUser);
  const { post } = useFetch();

  useEffect(() => {
    const getAllowedExams = async () => {
      const res = await instance.get(
        endpoints.getAllowedExams +
          `?examPermissions=${user.examPermissions.toString()}`
      );
      setExams(res.data);
    };
    getAllowedExams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function submitExam() {
    try {
      const data = { code: examCode, title: examTitle, type: "exam" };
      post(BASE + "new", data, (error, data) => {
        if (error) return console.log(error);
        const clone = [...exams];

        clone.push(data);
        setExams(clone);
      });

      setExamCode("");
      setExamTitle("");
      showToast({ type: "success", message: "Successfully added exam!" });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  }

  return (
    <Tabs>
      {/* Tab heading */}
      <Tabs.Button id="bExam" target="pExam" selected>
        Exam
      </Tabs.Button>
      <Tabs.Button id="bDepartment" target="pDepartment">
        Department
      </Tabs.Button>
      <Tabs.Button id="bSubject" target="pSubject">
        Subject
      </Tabs.Button>
      <Tabs.Button id="bChapter" target="pChapter">
        Chapter
      </Tabs.Button>
      <Tabs.Button id="bTopic" target="pTopic">
        Topic
      </Tabs.Button>
      {/* Tabs body */}

      <Tabs.Pane id="pExam" label="bExam" selected>
        <AddExam
          examCode={examCode}
          examTitle={examTitle}
          setExamCode={setExamCode}
          setExamTitle={setExamTitle}
          submitExam={submitExam}
        />
      </Tabs.Pane>
      <Tabs.Pane id="pDepartment" label="bDepartment">
        <AddDepartment exams={exams} />
      </Tabs.Pane>
      <Tabs.Pane id="pSubject" label="bSubject">
        <AddSubject exams={exams} />
      </Tabs.Pane>
      <Tabs.Pane id="pChapter" label="bChapter">
        <AddChapter exams={exams} />
      </Tabs.Pane>
      <Tabs.Pane id="pTopic" label="bTopic">
        <AddTopic exams={exams} />
      </Tabs.Pane>
    </Tabs>
  );
};

export default AddSection;
