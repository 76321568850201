class Admin {
  static MASTER = "master";
  static EXAM = "exam";
  static DEPARTMENT = "department";
  static SENIOR = "senior";
  static SUPPORT = "support";
}

class QuestionTypes {
  static ANSWER = "answer";
  static MCQ = "mcq";
  static MSQ = "msq";
  static SUBJECTIVE = "subjective";
}

class Type {
  static SERIES = 1000;
  static QUIZ = 1010;
  static PREVIOUSYEARS = 1030;
  static DPP = 1020;
  static Assignment = 1030;
  static Workbook = 1040;
  static Other = 1050;
}

export const YEARS = [
  { name: "Test Series", value: Type.SERIES },
  { name: "Quiz", value: Type.QUIZ },
  { name: "Assignment", value: Type.Assignment },
  { name: "DPP", value: Type.DPP },
  { name: "Workbook", value: Type.Workbook },
  { name: "Other", value: Type.Other },
];

const subjects = [
  { key: "EE", text: "Electrical Engineering" },
  { key: "ME", text: "Mechanical Engineering" },
  { key: "CS", text: "Computer Science and Information Technology" },
  { key: "MT", text: "Metallurgical Engineering Section" },
  { key: "IN", text: "Instrumentation Engineering" },
  { key: "EC", text: "Electronics and Communications" },
  { key: "CE", text: "Civil Engineering" },
  { key: "CH", text: "Chemical Engineering" },
  { key: "GG", text: "Geology and Geophysics" },
  { key: "CY", text: "Chemistry" },
];

export { Admin, Type, QuestionTypes, subjects };
