import { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { BsFillEyeFill } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useRecoilValue } from "recoil";
import { Button } from "../../components/buttons";
import { endpoints } from "../../constants/Endpoints";
import { instance } from "../../services/https/inceptors";
import { showToast } from "../../utilities/toast";
import AddAssignmentModal from "./Modals/AddAssignmentModal";
import AddTopicModal from "./Modals/AddTopicModal";
import { department as recoilDepart, selectedLanguage } from "../../providers/index";
import classNames from 'classnames';

const CourseChapterData = () => {
  const [chapterData, setChapterData] = useState();
  const [showTopicModal, setShowTopicModal] = useState();
  const [showAddAssignmentModal, setShowAddAssignmentModal] = useState();
  const { chapterId, chapterName, subjectId } = useParams();
  const department = useRecoilValue(recoilDepart).split("/")[1];
  const language = useRecoilValue(selectedLanguage);


  const getChapterData = async () => {
    const res = await instance.get(
      `${endpoints.getCourseChaptersData}?chapterId=${chapterId}`
    );
    setChapterData(res.data);
  };

  useEffect(() => {
    if (chapterId) getChapterData();
  }, []);

  const deleteTopic = async (topicId) => {
    try {
      await instance.delete(
        `${endpoints.deleteCourseTopic}?chapterId=${chapterData?.chapterId}&topicId=${topicId}`
      );

      const courseClone = JSON.parse(JSON.stringify(chapterData));
      courseClone.topics = courseClone.topics.filter(
        (topic) => topic._id !== topicId
      );

      setChapterData(courseClone);
      showToast({ type: "success", message: "Successfully deleted topic" });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  const deleteAssignment = async (ass) => {
    try {
      await instance.delete(
        `${endpoints.deleteCourseAssignment}?assignmentId=${ass._id}&chapterId=${chapterId}`
      );

      const clone = JSON.parse(JSON.stringify(chapterData));
      clone.assignments = clone.assignments.filter(
        (assignment) => assignment._id !== ass._id
      );

      setChapterData(clone);
      showToast({
        type: "success",
        message: "Successfully deleted Assignment!",
      });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  return (
    <div className="mb-5">
      <h2
        style={{
          background: "gainsboro",
          width: "fit-content",
          padding: "10px 80px",
          margin: "auto",
          fontSize: 23,
        }}
        className="text-center text-uppercase"
      >
        {chapterName}
      </h2>
      <h5 className="text-center text-uppercase mt-5 section-heading">
        Topics
      </h5>
      <div className="d-flex mx-3 accent-body mt-4">
        <div className="all-chapters w-50">
          {chapterData?.topics?.length ? (
            <div className="mt-4">
              {chapterData?.topics?.map((topic) => (
                <div className="chapters-list">
                  <div className={classNames('chapter-title', {
                    '!bg-red-200': topic?.title?.[language.value]
                  })}>
                    {topic?.title?.[language.value] ?? `Not available in ${language.label}`}
                  </div>
                  <div className="actions">
                    <Link
                      to={`/course/topic/${topic?._id}/${subjectId}/${topic?.title[language.value]}`}
                    >
                      <BsFillEyeFill size={26} />
                    </Link>
                    <div>
                      <AiFillDelete
                        onClick={() => deleteTopic(topic?._id)}
                        size={26}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center mt-4"> No Topic Added!</div>
          )}
        </div>

        <div className="w-50 d-flex flex-column align-items-center justify-content-center gap-4">
          <Button
            onClick={() => setShowTopicModal(true)}
            color="success"
            width="30"
            className="search-assignment"
          >
            Add Topic
          </Button>
        </div>
      </div>

      <div>
        <h5 className="text-center text-uppercase mt-5 section-heading">
          Assignments
        </h5>
        <div className="d-flex mx-3 accent-body mt-4">
          <div className="all-chapters w-50">
            {chapterData?.assignments?.length ? (
              <div className="mt-4">
                {chapterData?.assignments?.map((assign) => (
                  <div className="chapters-list">
                    <div className={classNames('chapter-title', {
                      '!bg-red-200': !assign?.name?.[language.value],
                    })}>
                      {assign?.name?.[language.value] ?? `Not available in ${language.label}`}
                    </div>
                    <div className="actions">
                      <Link to={`/edit-assignment/${test._id}/${department}`}>
                        <BsFillEyeFill size={26} />
                      </Link>
                      <div>
                        <AiFillDelete
                          onClick={() => deleteAssignment(test)}
                          size={26}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center mt-4"> No Assignments Added!</div>
            )}
          </div>

          <div className="w-50 d-flex flex-column align-items-center justify-content-center gap-4">
            <Button
              onClick={() => setShowAddAssignmentModal(true)}
              color="success"
              width="30"
              className="search-assignment"
            >
              Add Assignments
            </Button>
          </div>
        </div>
      </div>

      {showTopicModal && (
        <AddTopicModal
          language={language.value}
          id={chapterId}
          data={chapterData}
          setData={setChapterData}
          closeModal={() => setShowTopicModal(false)}
        />
      )}

      {showAddAssignmentModal && (
        <AddAssignmentModal
          subject={subjectId}
          id={chapterId}
          data={chapterData}
          setData={setChapterData}
          closeModal={() => setShowAddAssignmentModal(false)}
        />
      )}
    </div>
  );
};

export default CourseChapterData;
