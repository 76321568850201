import { BiBookContent } from 'react-icons/bi';
import { FaQuestion } from 'react-icons/fa';
import AddViewBox from '../../components/AddViewBox/index';

const AddViewPanel = ({ exam, department }) => {
  return (
    <div className='mt-3 add-view-box-flex'>
      <AddViewBox
        label='Content'
        icon={<BiBookContent size={50} />}
        addPath={`/add-content/${department.split('/')[1]}/${
          exam.split('/')[1]
        }`}
        viewPAth={`/contents/${department.split('/')[1]}`}
        addDisable={!department || !exam}
        viewDisable={!exam || !department}
      />

      <AddViewBox
        icon={<FaQuestion size={40} />}
        label='Questions'
        addPath={`/new/question`}
        viewPAth={`/questions/${department.split('/')[1]}/${
          exam.split('/')[1]
        }`}
        addDisable={!department || !exam}
        viewDisable={!exam || !department}
      />
    </div>
  );
};

export default AddViewPanel;
