import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Button, Submit } from "../../components/buttons";
import { Input, Select, Time } from "../../components/inputs";
import { Section } from "../../components/Section";
import { endpoints } from "../../constants/Endpoints";
import useData from "../../hooks/useData";
import { authUser, selectedDepartmentObject } from "../../providers/index";
import { instance } from "../../services/https/inceptors";
import { showToast } from "../../utilities/toast";
import { checkError } from "../../utilities/error";
import CommonInput from "../../components/CommonInput";
import { AWS_DIRECTORIES, LANGUAGES } from "../../constants/Common";
import ReactSelect from "react-select";

const AddWorkBook = () => {
  const { department, exam } = useParams();
  const selectedDepart = useRecoilValue(selectedDepartmentObject);
  const [language, setSelectedLanguage] = useState({
    value: "en",
    label: "English",
  });

  const [name, setName] = useState({ [language.value]: "" });
  const [instructions, setInstructions] = useState({ [language.value]: "" });

  const [toRenderSection, setToRenderSection] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState();
  const [marks, setMarks] = useState("");
  const [time, setTime] = useState(0);
  const data = useData();
  const user = useRecoilValue(authUser);

  const getSubjects = async () => {
    const res = await data.getSubjectByDepartment(department);
    setSubjects(res);
  };

  React.useEffect(() => {
    if (department && exam) getSubjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [department, exam]);

  const handleSection = () => {
    let index = toRenderSection.length;
    let flag = true;
    while (flag === true) {
      let temp = false;
      // eslint-disable-next-line no-loop-func
      toRenderSection.forEach((sec) => {
        if (sec.sectionId === `section${index}`) temp = true;
      });
      if (temp) {
        flag = true;
        index++;
      } else flag = false;
    }
    const toAdd = {
      name: "",
      questions: [],
      sectionId: "section" + index,
      tackle: 0,
      compulsory: null,
    };
    setToRenderSection([...toRenderSection, toAdd]);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    let questionIds = [];
    let returnFunc = false;
    const sectionsClone = JSON.parse(JSON.stringify(toRenderSection));

    sectionsClone.forEach((section) => {
      returnFunc = checkError(section);
      section.questions.forEach((question) => {
        questionIds.push(question._id);
      });
      section.questions = questionIds;
      questionIds = [];
    });
    if (returnFunc) return;

    const data = {
      exam,
      department,
      name,
      postedBy: user.userId,
      time,
      instructions,
      marks,
      sections: sectionsClone,
      subject: selectedSubject,
    };

    try {
      await instance.post(endpoints.addWorkbook, data);
      showToast({
        type: "success",
        message: "Successfully Added Workbook..",
      });

      setMarks("");
      setTime(0);
      setName({ [language.value]: "" });
      setInstructions({ [language.value]: "" });
      setToRenderSection([]);
    } catch (err) {
      showToast({
        type: "error",
        message: err.response.data.message,
      });
    }
  };

  const deleteSection = (selected) =>
    setToRenderSection((prev) =>
      prev.filter((sec) => sec.sectionId !== selected.sectionId)
    );

  const onLanguageChange = (e) => {
    setSelectedLanguage(e);

    setName((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: "" };
      else return prev;
    });

    setInstructions((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: "" };
      else return prev;
    });
  };

  const allQuestions = useMemo(() => {
    const data = [];
    toRenderSection.forEach((section) =>
      section.questions.forEach((ques) => data.push(ques))
    );
    return data;
  }, [toRenderSection]);

  return (
    <div className="container mb-5 d-flex flex-column justify-content-center">
      <div className="d-flex justify-content-center ">
        <h4 className="space-heading mb-5">Create Workbook</h4>
      </div>
      <form className="horizontal" onSubmit={onSubmit}>
        <div className="flex flex-wrap gap-x-8 items-center justify-center">
          <Input
            className="!w-56"
            id="name"
            label="Workbook Name"
            value={name[language?.value]}
            onChange={(e) =>
              setName((prev) => ({ ...prev, [language.value]: e }))
            }
          />

          <Select
            value={selectedSubject}
            onChange={setSelectedSubject}
            label="Subject"
          >
            {subjects.length > 0 &&
              subjects.map((item, index) => (
                <option value={item._id} key={index}>
                  {item.title}
                </option>
              ))}
          </Select>

          <Input value={marks} onChange={setMarks} label="Marks" />

          <Time value={time} onChange={setTime} label="Select Time" />

          <div className="-mt-1">
            <h6 className="mb-1">Select Language</h6>
            <ReactSelect
              onChange={onLanguageChange}
              options={selectedDepart?.languages?.map((lang) => {
                const label = Object.keys(LANGUAGES).find(
                  (key) => LANGUAGES[key] === lang
                );
                return {
                  value: lang,
                  label: label.replace(/^./, label[0].toUpperCase()),
                };
              })}
              value={language}
              placeholder="Select Language"
              className="w-52"
            />
          </div>
        </div>
        <div className="mt-4 mb-3 mx-4">
          <CommonInput
            language={language.value}
            directory={AWS_DIRECTORIES.WORK_BOOK}
            label="General Instructions"
            value={instructions[language.value]}
            onChange={setInstructions}
          />
        </div>
        {toRenderSection.map((section, index) => {
          return (
            <Section
              allQuestions={allQuestions}
              language={language}
              key={index + section.sectionId}
              index={index}
              section={section}
              setToRenderSection={setToRenderSection}
              deleteSection={deleteSection}
            />
          );
        })}
        <div className="d-flex justify-content-start mt-3 ">
          <Button width="25" onClick={handleSection}>
            Add Section
          </Button>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <Submit
            width="25"
            disabled={
              !name[language.value] ||
              time === 0 ||
              !marks ||
              !instructions[language.value] ||
              !toRenderSection.length ||
              !selectedSubject
            }
          />
        </div>
      </form>
    </div>
  );
};

export default AddWorkBook;
