import classnames from "classnames";
import { MathfieldElement } from "mathlive";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { SelectQuestionModal } from "./SelectQuestionModal";

/**
 * Create a primary button
 */

const Button = ({
  width,
  color,
  className,
  transparent,
  children,
  custom,
  ...props
}) => (
  <button
    type="button"
    className={classnames(
      custom ? "" : `btn btn-${color}`,
      width && `w-${width}`,
      className
    )}
    style={{
      background: transparent ? "transparent" : `rgb(2, 79, 16)`,
      color: transparent ? "black" : "white",
      border: "none",
    }}
    {...props}
  >
    {children}
  </button>
);

Button.defaultProps = {
  color: "primary",
  disabled: false,
};

Button.propTypes = {
  width: PropTypes.oneOf(["100", "75", "50", "35", "25"]),
  color: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

/**
 * Creates a submit button with predefined formatting
 */

const Submit = ({ width, disabled, children, ...props }) => (
  <button
    type="submit"
    className={`btn  w-${width}`}
    style={{ background: `rgb(2, 79, 16)`, color: "white" }}
    disabled={disabled}
    {...props}
  >
    {children}
  </button>
);

Submit.defaultProps = {
  width: "100",
  disabled: false,
  children: "Submit",
};

Submit.propTypes = {
  width: PropTypes.oneOf(["100", "75", "50", "25"]),
  disabled: PropTypes.bool,
  children: PropTypes.string,
};

/**
 * Displays a modal to enter equation when clicked
 */

const Equation = ({ inputRef, onEntered }) => {
  const mathFieldRef = useRef();

  const [active, setActive] = useState(false);
  const [equation, setEquation] = useState("");

  const mathFieldElement = new MathfieldElement({
    defaultMode: "math",
    letterShapeStyle: "tex",
    virtualKeyboardMode: "onfocus",
  });

  mathFieldElement.addEventListener("input", (event) =>
    setEquation(event.target.value)
  );

  function openDialog() {
    mathFieldRef.current.appendChild(mathFieldElement);
    setActive(true);
  }

  function closeDialog() {
    mathFieldRef.current.removeChild(mathFieldRef.current.children[0]);
    setEquation("");
    setActive(false);
    inputRef.current.focus();
  }

  function getLatex() {
    onEntered(`/eqtn\\mathrm{${equation.replace(/\s+/g, "")}}`);
    closeDialog();
  }

  return (
    <>
      <SelectQuestionModal
        title="Enter Equation"
        show={active}
        onClose={closeDialog}
        onDone={getLatex}
        footerVisible
      >
        <div ref={mathFieldRef} className="my-3" />
      </SelectQuestionModal>

      <Button className="square" onClick={openDialog}>
        &Sigma;
      </Button>
    </>
  );
};

Equation.propTypes = {
  inputRef: PropTypes.object,
  onEntered: PropTypes.func,
};

export { Button, Submit, Equation };
