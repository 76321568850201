import React, { useEffect, useState } from "react";
import { endpoints } from "../../constants/Endpoints";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  department as recoilDepart,
  exam as recoilExam,
  selectedDepartmentObject,
  selectedLanguage,
} from "../../providers/index";
import { instance } from "../../services/https/inceptors";
import EmptyIcon from "../../components/EmptyIcon/index";
import { LANGUAGES, MODULES } from "../../constants/Common";
import Actions from "../../components/Action/index";
import { useHistory } from "react-router-dom";
import ReactSelect from 'react-select';

const YearCutOff = () => {
  const [yearCutOff, setYearCutOff] = useState();
  const department = useRecoilValue(recoilDepart).split("/")[1];
  const exam = useRecoilValue(recoilExam).split("/")[1];
  const { push } = useHistory();

  const selectedDepart = useRecoilValue(selectedDepartmentObject);
  const [currentLanguage, setCurrentLanguage] = useRecoilState(selectedLanguage);

  const getAllYearCutOff = async () => {
    const res = await instance.get(
      `${endpoints.getAllYearCutOff}?exam=${exam}&department=${department}`
    );
    setYearCutOff(res.data);
  };

  useEffect(() => {
    getAllYearCutOff();
  }, []);

  return (
    <div className="container">
      <div className="flex flex-col justify-center items-center my-4">
        <div
          className="d-flex justify-content-center rounded w-25 p-2 btn-hover c-pointer"
          onClick={() => push("/create-yearCutoff")}
        >
          Create Year Cut Off
        </div>


        <div className='mt-4'>
          <h6 className='mb-1 text-center'>Select Language</h6>
          <ReactSelect
            onChange={setCurrentLanguage}
            options={selectedDepart?.languages?.map((lang) => {
              const label = Object.keys(LANGUAGES).find(
                (key) => LANGUAGES[key] === lang
              );
              return {
                value: lang,
                label: label.replace(/^./, label[0].toUpperCase()),
              };
            })}
            value={currentLanguage}
            placeholder='Select Language'
            className='w-52'
          />
        </div>
      </div>

      <div className="mt-5 m-auto w-50">
        {yearCutOff?.length ? (
          <Actions
            language={currentLanguage}
            primary={true}
            type={MODULES.YEAR_CUT_OFF}
            data={yearCutOff}
            setData={setYearCutOff}
          />
        ) : (
          <EmptyIcon />
        )}
      </div>
    </div>
  );
};

export default YearCutOff;
